import { Injectable } from '@angular/core';
import { isObservable, Subscription } from 'rxjs';
import { NetworkService } from '../../../services/network.service';
import { StorageService } from '../../../services/storage.service';
import { ImageService } from '../../../services/image.service';
import { AbstractControl } from '@angular/forms';
import { Platform, AlertController, LoadingController } from '@ionic/angular';
import { AuthService } from '../../auth/auth.service';
import {
  Firestore,
  arrayRemove, arrayUnion, doc, getDocFromServer, getDocFromCache, getDocsFromServer
  , getDocsFromCache, updateDoc, query, where, collection, QuerySnapshot, Timestamp, DocumentData, Query, setDoc
} from '@angular/fire/firestore';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { DateHelpers } from '../../../utils/date-helpers';
import { Utils } from '../../../utils/utils';
import { ConfigService } from '../../../services/config.service';
import { CryptoService } from '../../../services/crypto.service';
import { FileType } from '../../../enums/file-type.enum';
import { MasterDataService } from '../../master-data/master-data.service';
import { ContactService } from '../../master-data/contact/contact.service';
import { ProductService } from '../../master-data/product/product.service';
import {
  Form, FormAttribute, FormAttributeControl, FormCondition, FormPdf, FormPdfImageOptions, FormPdfTable, FormPdfTableBody, FormPdfTableBodyAttributes
  , FormPdfTableBodyText, FormPdfTableBodyTextValue, FormPdfTableBodyTextValueReplacement
} from '../../../interfaces/config/form';
import { Status } from '../../../enums/status.enum';
import { Client } from '../../../enums/client.enum';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Share } from '@capacitor/share';
import { TranslateService } from '@ngx-translate/core';
import { AmountHelpers } from '../../../utils/amount-helpers';
import { ValueFormat, ValueHandler, ValueType } from '../../../enums/mappings/pdf-value.enum';
import { BodyStyle, BodyType } from '../../../enums/mappings/pdf-body.enum';
import { PdfType } from '../../../enums/mappings/pdf-type.enum';
import { ControlType } from '../../../enums/mappings/control-type.enum';
import * as formularParser from 'hot-formula-parser';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { AlertId } from '../../../../app/enums/alert-id.enum';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto.ttf',
    bold: 'Roboto.ttf',
    italics: 'Roboto.ttf',
    bolditalics: 'Roboto.ttf'
  },
  montserratLight: {
    normal: 'MontserratLight.ttf',
    bold: 'MontserratSemiBold.ttf',
    italics: 'MontserratLightItalic.ttf',
    bolditalics: 'MontserratSemiBoldItalic.ttf'
  },
  Fontello: {
    normal: 'Fontello.ttf',
    bold: 'Fontello.ttf',
    italics: 'Fontello.ttf',
    bolditalics: 'Fontello.ttf'
  }
}

const propertiesToSkip = new Array<string>('$key', 'type', 'workflowStatus');

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private isOnline: boolean;
  private networkSubscription: Subscription;
  private currentObject: any;
  private currentDocs: Map<string, Array<any>>;
  private forms: Array<Form>;
  public dbLogosRef: any;
  private logos: { de: string, en: string };
  public client: typeof Client = Client;
  private pdfContainsCoverImage: boolean;
  private parser: any;

  constructor(
    private networkService: NetworkService,
    private imageService: ImageService,
    private platform: Platform,
    private authService: AuthService,
    private configService: ConfigService,
    private cryptoService: CryptoService,
    private contactService: ContactService,
    private productService: ProductService,
    private fileOpener: FileOpener,
    public translate: TranslateService,
    private loadingController: LoadingController,
    private alertCtrl: AlertController,
    private masterDataService: MasterDataService,
    private storageService: StorageService,
    private firestore: Firestore,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.networkSubscription = this.networkService.isOnlineObservable().subscribe(async (isOnline: boolean) => {
      if (this.isOnline !== undefined && this.isOnline && !isOnline) {
        this.cacheCurrentObjects();
      }

      this.isOnline = isOnline;
    });

    this.parser = new formularParser.Parser();

    this.currentDocs = new Map<string, Array<any>>();
  }

  public ngOnDestroy(): void {
    this.networkSubscription.unsubscribe();
  }

  public async loadCurrent(formType: string, formNode: string, id: string, setCurrent: boolean, fromDb?: boolean): Promise<any> {
    var formMapping: Form;

    if (!fromDb && this.getCurrentDocs().has(id)) {
      var formObj = this.getCurrentDocs().get(id);
      if (formObj['subType']) {
        formMapping = this.configService.getFormMappingByTypeAndSubType(formType, formObj['subType']);
      } else {
        formMapping = this.configService.getFormMappingByType(formType);
      }
      await this.categorize(formObj, formMapping);
      if (setCurrent) {
        this.currentObject = formObj;
      }
      this.cacheImages(formObj);
      return formObj;
    } else {
      const currentObjectRef = doc(this.firestore, formNode, id);
      const obj = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? await getDocFromServer(currentObjectRef) : await getDocFromCache(currentObjectRef);
      if (!obj.exists) {
        return;
      }
      const $key = obj.id;
      const formObj = { $key, ...obj.data() as {}, type: formType };
      if (formObj['subType']) {
        formMapping = this.configService.getFormMappingByTypeAndSubType(formType, formObj['subType']);
      } else {
        formMapping = this.configService.getFormMappingByType(formType);
      }

      if (this.configService.getClientMapping().encryption) {
        await this.cryptoService.decryptObj(formObj);
      }

      await this.categorize(formObj, formMapping);

      if (setCurrent) {
        this.currentObject = formObj;
      }
      var cacheObj = this.currentDocs.get(id);
      if (cacheObj !== undefined) {
        this.currentDocs.get(formNode)[this.currentDocs.get(formNode).indexOf(cacheObj)] = formObj;
      }
      this.cacheImages(formObj);
      return formObj;
    }
  }

  public async loadDocs(initial: boolean, creators: Array<string>, clientBased: boolean): Promise<void> {
    this.currentDocs = new Map<string, Array<any>>();
    this.forms = this.configService.getFormsMapping();
    var tempForms = new Array<Form>();

    if (!initial || (initial && this.networkService.isOnline() && this.networkService.isMinBandwidth())) {
      if (this.authService.currentUser !== undefined) {

        var objectsPromises = new Array<Promise<QuerySnapshot<any>>>();
        await Utils.asyncForEach(this.forms, async (form: Form) => {
          const formRef = collection(this.firestore, form.node);

          let creatorQuery: Query<DocumentData>;
          let holderQuery: Query<DocumentData>;

          if (!creators || creators.length === 0) {
            if (form.subType) {
              creatorQuery = query(formRef
                , where('visible', '==', true)
                , where('subType', '==', form.subType.name)
                , where('creator', '==', this.authService.currentUser.$key)
              );
              holderQuery = query(formRef
                , where('visible', '==', true)
                , where('subType', '==', form.subType.name)
                , where('creator', '!=', this.authService.currentUser.$key)
                , where('holder', '==', this.authService.currentUser.$key)
              );
            } else {
              creatorQuery = query(formRef
                , where('visible', '==', true)
                , where('creator', '==', this.authService.currentUser.$key)
              );
              holderQuery = query(formRef
                , where('visible', '==', true)
                , where('creator', '!=', this.authService.currentUser.$key)
                , where('holder', '==', this.authService.currentUser.$key)
              );
            }

            const creatorObjs = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocsFromServer(creatorQuery) : getDocsFromCache(creatorQuery);

            objectsPromises.push(creatorObjs);
            tempForms.push(form);

            const holderObjs = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocsFromServer(holderQuery) : getDocsFromCache(holderQuery);

            objectsPromises.push(holderObjs);
            tempForms.push(form);
          } else {
            let creatorObjs: Promise<QuerySnapshot<any>>;

            if (clientBased) {
              if (form.subType) {
                creatorQuery = query(formRef
                  , where('visible', '==', true)
                  , where('subType', '==', form.subType.name)
                  , where('client', '==', this.authService.currentUser.client)
                );
              } else {
                creatorQuery = query(formRef
                  , where('visible', '==', true)
                  , where('client', '==', this.authService.currentUser.client)
                );
              }
              creatorObjs = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocsFromServer(creatorQuery) : getDocsFromCache(creatorQuery);
            } else {
              if (form.subType) {
                creatorQuery = query(formRef
                  , where('visible', '==', true)
                  , where('subType', '==', form.subType.name)
                  , where('creator', '==', creators[0])
                );
              } else {
                creatorQuery = query(formRef
                  , where('visible', '==', true)
                  , where('creator', '==', creators[0])
                );
              }

              creatorObjs = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocsFromServer(creatorQuery) : getDocsFromCache(creatorQuery);
            }

            objectsPromises.push(creatorObjs);
            tempForms.push(form);
          }
        });

        try {
          var objects = new Map<string, Array<any>>();
          const objectsDataSnapshots = await Promise.all(objectsPromises);

          await Utils.asyncForEach(objectsDataSnapshots, async (objectSnapshot: QuerySnapshot<any>) => {
            var filteredObjs = new Array<any>();
            let form = tempForms[objectsDataSnapshots.indexOf(objectSnapshot)];

            filteredObjs = (await Promise.all(objectSnapshot.docs
              .filter(a => a.exists)
              .map(async action => {
                const $key = action.id;
                const icon = form.icon;
                const data = { $key, ...action.data() as {}, type: form.type, icon: icon } as {};
                var formMapping: Form;
                if (data['subType']) {
                  formMapping = this.configService.getFormMappingByTypeAndSubType(form.type, data['subType']);
                } else {
                  formMapping = this.configService.getFormMappingByType(form.type);
                }
                if (formMapping) {
                  if (data['creator'] === this.authService.currentUser.$key || data['holder'] === this.authService.currentUser.$key) {
                    if (this.configService.getClientMapping().encryption) {
                      await this.cryptoService.decryptObj(data);
                      return this.categorize(data, formMapping);
                    } else {
                      return this.categorize(data, formMapping);
                    }
                  } else {
                    if (formMapping.teamLeadPermission
                      && this.authService.currentUser.client !== undefined
                      && this.authService.currentUser.client !== Client.Basic
                      && this.authService.currentUser.client !== Client.Free
                      && this.authService.currentUser.client !== Client.Advance
                      && !this.authService.currentUser.teamLead) {
                      return;
                    } else {
                      if (this.configService.getClientMapping().encryption) {
                        await this.cryptoService.decryptObj(data);
                        return this.categorize(data, formMapping);
                      } else {
                        return this.categorize(data, formMapping);
                      }
                    }
                  }
                }
              })))
              .filter(d => d !== undefined);

            if (objects.has(form.type)) {
              var existingObj = objects.get(form.type);
              existingObj.push(...filteredObjs);
            } else {
              objects.set(form.type, filteredObjs);
            }
            this.storageService.get(form.type).then(async data => {
              if (data !== null) {
                data.push(...filteredObjs);
              } else {
                await this.storageService.set(form.type, filteredObjs);
              }
            });
          });
          this.currentDocs = objects;
        } catch (ex) {
          // Error while getting documents from firebase server / cache -> Getting documents from local storage
          logEvent(this.analytics, 'error', { error: 'Error while getting documents from firebase server / cache -> Getting documents from local storage: ' + JSON.stringify(ex) });
          this.currentDocs = await this.loadDocsFromStorage();
          return;
        }
      } else {
        return;
      }
    } else {
      // Device is offline or min bandwidth isn't available
      logEvent(this.analytics, 'error', { error: 'Device is offline or min bandwidth isnt available -> Getting documents from local storage' });
      this.currentDocs = await this.loadDocsFromStorage();
      return;
    }
  }

  public clearCurrentObject(): void {
    this.currentObject = undefined;
  }

  public clearCurrentObjects(): void {
    this.clearCurrentObject();
    this.clearStorageCache();
    this.currentDocs = new Map<string, Array<any>>();
  }

  public clearStorageCache(): void {
    this.currentDocs.forEach(async (value: Object, key: string) => {
      await this.storageService.remove(key);
    });
  }

  public async cacheCurrentObjects(): Promise<void> {
    this.currentDocs.forEach(async (value: Object, key: string) => {
      await this.storageService.set(key, value);
    });
  }

  public getCurrentObject(): any {
    return this.currentObject;
  }

  public getCurrentDocs(): Map<string, Array<any>> {
    return this.currentDocs;
  }

  public getImage(image: string): string {
    if (this.networkService.isOnline()) {
      return image;
    } else {
      if (this.imageService.getImageCache()[image] !== undefined) {
        return this.imageService.getImageCache()[image];
      } else if (!image || (image && !image.includes('https://'))) {
        return image;
      } else {
        return null;
      }
    }
  }

  public async create(formType: string, formSubType: string, formNode: string, controls: Map<string, AbstractControl>, currency: string, images: Array<any>
    , setCurrent: boolean, issuerExistent: boolean, holder: string, productId?: string, formIds?: Array<{ type: string, id: string }>
    , relatedContacts?: Array<any>, relatedProducts?: Array<any>, recipientName?: string, recipientBirthdate?: Timestamp): Promise<any> {
    const t = trace(this.performance, 'createForm');
    try { t.start(); } catch (ex) { };

    var encryption = this.configService.getClientMapping().encryption;

    var formMapping: Form;
    if (formSubType) {
      formMapping = this.configService.getFormMappingByTypeAndSubType(formType, formSubType);
    } else {
      formMapping = this.configService.getFormMappingByType(formType);
    }

    const newFormRef = doc(collection(this.firestore, formNode));

    // Add fix properties
    let newObj: object = {
      client: this.authService.currentUser.client,
      creator: this.authService.currentUser.$key,
      creationDate: Timestamp.fromDate(new Date()),
      formIds,
      holder: holder ? holder : this.authService.currentUser.$key,
      productId,
      recipient_name: !recipientName ? undefined : recipientName,
      recipient_birthdate: !recipientBirthdate ? undefined : recipientBirthdate,
      relatedContacts: !relatedContacts || relatedContacts.length === 0 ? undefined : relatedContacts,
      relatedProducts: !relatedProducts || relatedProducts.length === 0 ? undefined : relatedProducts,
      subType: formSubType,
      trash: false,
      visible: true
    };
    if (currency) {
      newObj['currency'] = currency;
    }

    if (!formIds || formIds.length === 0) {
      newObj['issuer_company'] = this.authService.currentUser.company;
      newObj['issuer_salutation'] = this.authService.currentUser.salutation;
      newObj['issuer_title'] = this.authService.currentUser.title;
      newObj['issuer_name'] = ((this.authService.currentUser.firstname === undefined ? '' : this.authService.currentUser.firstname)
        + ' ' + (this.authService.currentUser.lastname === undefined ? '' : this.authService.currentUser.lastname)).trim();
      newObj['issuer_street'] = this.authService.currentUser.street;
      newObj['issuer_zip'] = this.authService.currentUser.postalcode;
      newObj['issuer_city'] = this.authService.currentUser.city;
      newObj['issuer_state'] = this.authService.currentUser.state;
      newObj['issuer_country'] = this.authService.currentUser.country;
      newObj['issuer_birthdate'] = !this.authService.currentUser.birthdate ? undefined
        : typeof this.authService.currentUser.birthdate === 'string'
          ? DateHelpers.getFirestoreTimestamp(this.authService.currentUser.birthdate)
          : this.authService.currentUser.birthdate;
      newObj['issuer_email'] = this.authService.currentUser.email;
      newObj['issuer_idCardIdNumber'] = this.authService.currentUser.idCardIdNumber;
      newObj['issuer_idCardIssueAgency'] = this.authService.currentUser.idCardIssueAgency;
      newObj['issuer_idCardIssueDate'] = !this.authService.currentUser.idCardIssueDate ? undefined
        : typeof this.authService.currentUser.idCardIssueDate === 'string'
          ? DateHelpers.getFirestoreTimestamp(this.authService.currentUser.idCardIssueDate)
          : this.authService.currentUser.idCardIssueDate;
      newObj['issuer_phoneNr'] = this.authService.currentUser.phoneNr;
      newObj['issuer_paymentBic'] = this.authService.currentUser.paymentBic;
      newObj['issuer_paymentIban'] = this.authService.currentUser.paymentIban;
      newObj['issuer_paymentInstitution'] = this.authService.currentUser.paymentInstitution;
    }

    // Add dynamic properties
    controls.forEach((control: AbstractControl, key: string) => {
      if (!propertiesToSkip?.includes(key)
        && !this.platform.is('capacitor')
        && !this.platform.is('cordova')
        && Utils.isArrayControlWithNewImage(control, this.imageService)) {
        newObj[key] = Utils.getArrayControlWithoutNewImage(control, this.imageService);
      } else if (!propertiesToSkip?.includes(key)) {
        newObj[key] = control.value;
      }
    });

    // Remove undefined fields saving
    await Utils.deleteUndefinedProperties(newObj);

    if (encryption) {
      let encryptedObj = await this.cryptoService.encryptObj(newObj, formMapping);
      setDoc(newFormRef, encryptedObj);
    } else {
      setDoc(newFormRef, newObj);
    }

    this.addElementToUserDocumentList(formNode, newFormRef.id);
    var currentObj = await this.loadCurrent(formType, formNode, newFormRef.id, setCurrent);

    var formTitle = this.configService.getFormMappingByType(formType).translations.title;

    if (formIds && formIds.length > 0) {
      if (newObj['issuer_contactId']) {
        await this.addElementToMasterDataHistory(newObj['issuer_contactId'], formMapping, this.contactService.getMasterDataNode(), formType, formSubType, formTitle, currentObj);
      }
      if (newObj['recipient_contactId']) {
        await this.addElementToMasterDataHistory(newObj['recipient_contactId'], formMapping, this.contactService.getMasterDataNode(), formType, formSubType, formTitle, currentObj);
      }
    }

    // Add element to product history
    if (productId) {
      await this.addElementToMasterDataHistory(productId, formMapping, this.productService.getMasterDataNode(), formType, formSubType, formTitle, currentObj);
    }

    // Add related contacts to contact history
    if (relatedContacts) {
      relatedContacts.forEach(async (contact: any) => {
        await this.addElementToMasterDataHistory(contact.id, formMapping, this.contactService.getMasterDataNode(), formType, formSubType, formTitle, currentObj);
      });
    }

    // Add related products to product history
    if (relatedProducts) {
      relatedProducts.forEach(async (product: any) => {
        await this.addElementToMasterDataHistory(product.id, formMapping, this.productService.getMasterDataNode(), formType, formSubType, formTitle, currentObj);
      });
    }

    // Upload general images
    await this.imageService.handleMultipleStorageUpload(images, newFormRef.id, newFormRef.id, formTitle, formNode, 'images', currentObj, this);

    // Upload modal images
    controls.forEach(async (abstractControl: AbstractControl, key: string) => {
      if (typeof abstractControl.value === 'object') {
        await this.handleObjAdding(abstractControl.value, formNode, newFormRef.id, newFormRef.id, formTitle, key, key);
      }
    });

    try { t.stop(); } catch (ex) { };

    return currentObj;
  }

  public async update(formNode: string, formType: string, formSubType: string, productId?: string, contactId?: string, deletedImages?: Array<string>, newImages?: Array<string>
    , deletedPdfs?: Array<any>, newPdfs?: Array<any>, deletedModals?: Map<string, any>, newModals?: Map<string, any>, newContactIds?: Array<string>
    , newProductIds?: Array<string>): Promise<void> {
    let t = trace(this.performance, 'updateForm');
    try { t.start(); } catch (ex) { };

    var encryption = this.configService.getClientMapping().encryption;

    var formMapping: Form;
    if (formSubType) {
      formMapping = this.configService.getFormMappingByTypeAndSubType(formType, formSubType);
    } else {
      formMapping = this.configService.getFormMappingByType(formType);
    }

    var formTitle = formMapping.translations.title;
    var currentObj = this.getCurrentObject();
    var dbObj = await this.loadCurrent(formType, formNode, currentObj.$key, false, true);

    const formRef = doc(this.firestore, formNode, currentObj.$key);

    // Add all properties of the current object
    var updatedObj = {};
    await Utils.asyncForEach(Object.keys(currentObj), (key: string) => {
      if (!propertiesToSkip?.includes(key) && !this.platform.is('capacitor')
        && !this.platform.is('cordova')
        && Utils.isArrayPropertyWithNewImage(currentObj[key], this.imageService)) {
        updatedObj[key] = Utils.getArrayPropertyWithoutNewImage(currentObj[key], this.imageService);
      } else if (!propertiesToSkip?.includes(key)) {
        updatedObj[key] = currentObj[key];
      }
    });

    // Remove undefined fields saving
    await Utils.deleteUndefinedProperties(updatedObj);

    if (encryption) {
      let encryptedObj = await this.cryptoService.encryptObj(updatedObj, formMapping);
      updateDoc(formRef, encryptedObj);
    } else {
      updateDoc(formRef, updatedObj);
    }

    // Add element to contact history
    if (contactId) {
      await this.addElementToMasterDataHistory(contactId, formMapping, this.contactService.getMasterDataNode(), formType, formSubType, formTitle, dbObj);
    }

    // Add related contacts to contact history
    if (newContactIds && newContactIds.length > 0) {
      newContactIds.forEach(async (contactId: string) => {
        await this.addElementToMasterDataHistory(contactId, formMapping, this.contactService.getMasterDataNode(), formType, formSubType, formTitle, dbObj);
      });
    }

    // Add related products to product history
    if (newProductIds && newProductIds.length > 0) {
      newProductIds.forEach(async (productId: string) => {
        await this.addElementToMasterDataHistory(productId, formMapping, this.productService.getMasterDataNode(), formType, formSubType, formTitle, dbObj);
      });
    }

    // Add element to product history
    if (productId) {
      await this.addElementToMasterDataHistory(productId, formMapping, this.productService.getMasterDataNode(), formType, formSubType, formTitle, dbObj);
    }

    // Handle general images
    // Deleted images
    if (deletedImages && deletedImages.length > 0) {
      updateDoc(formRef, {
        images: arrayRemove(...deletedImages)
      });
    }
    await this.imageService.deleteMultipleRemovedObjsFromStorage(deletedImages);
    // New images
    if (newImages && newImages.length > 0) {
      newImages = await this.imageService.checkFirebaseMaxLength(newImages);
      updateDoc(formRef, {
        images: arrayUnion(...newImages)
      });
    }
    await this.imageService.handleMultipleStorageUpload(newImages, currentObj.$key, dbObj.number, formTitle, formNode, 'images'
      , currentObj, this);

    // Handle PDFs
    // Deleted PDFs
    if (deletedPdfs && deletedPdfs.length > 0) {
      updateDoc(formRef, {
        pdfs: arrayRemove(...deletedPdfs)
      });
    }
    await this.imageService.deleteMultipleRemovedObjsFromStorage(deletedPdfs);
    // New PDFs
    if (newPdfs && newPdfs.length > 0) {
      if (!this.platform.is('capacitor') && !this.platform.is('cordova')) {
        let pdfsWithoutBase64 = Utils.getArrayPropertyWithoutNewImage(newPdfs, this.imageService);
        if (pdfsWithoutBase64 && pdfsWithoutBase64.length > 0) {
          updateDoc(formRef, {
            pdfs: arrayUnion(...pdfsWithoutBase64)
          });
        }
      } else {
        updateDoc(formRef, {
          pdfs: arrayUnion(...newPdfs)
        });
      }
    }
    await this.handleObjAdding(newPdfs, formNode, currentObj.$key, dbObj.number, formTitle, 'pdfs', 'pdfs');

    // Handle all modals
    // Deleted modals
    if (deletedModals && deletedModals.size > 0) {
      deletedModals.forEach(async (value: any, key: string) => {
        updateDoc(formRef, {
          [key]: arrayRemove(...value)
        });
        await this.imageService.deleteMultipleRemovedObjsFromStorage(value);
      });
    }
    // New modals
    if (newModals && newModals.size > 0) {
      newModals.forEach(async (value: any, key: string) => {
        updateDoc(formRef, {
          [key]: arrayUnion(...value)
        });
        await this.handleObjAdding(value, formNode, currentObj.$key, dbObj.number, formTitle, key, key);
      });
    }

    await this.loadCurrent(formType, formNode, currentObj.$key, true);

    try { t.stop(); } catch (ex) { };
  }

  public async cacheImages(form: any): Promise<void> {
    if (this.networkService.isOnline() && this.networkService.isMinBandwidth()) {
      if (form.pdf && form.pdf.includes('https://')) {
        this.imageService.getImageFromUrl(form.pdf, FileType.Pdf);
      }
      if (form.images && form.images.length > 0 && form.images.some((image: string) => image !== undefined && image !== null
        && image.includes('https://'))) {
        form.images.forEach(async (image: string) => {
          this.imageService.getImageFromUrl(image);
        });
      }
    }
  }

  public async delete(): Promise<void> {
    this.deleteWithObj(this.getCurrentObject());
  }

  public async deleteWithObj(obj: any): Promise<void> {
    var formMapping: Form;
    if (obj.subType) {
      formMapping = this.configService.getFormMappingByTypeAndSubType(obj.type, obj.subType);
    } else {
      formMapping = this.configService.getFormMappingByType(obj.type);
    }
    obj.trash = true;
    const objRef = doc(this.firestore, formMapping.node, obj.$key);
    updateDoc(objRef, {
      trash: obj.trash
    });
    await this.categorize(obj, formMapping);
  }

  public async remove(): Promise<void> {
    this.removeWithObj(this.getCurrentObject());
  }

  public async removeWithObj(obj: any): Promise<void> {
    var formMapping: Form;
    if (obj.subType) {
      formMapping = this.configService.getFormMappingByTypeAndSubType(obj.type, obj.subType);
    } else {
      formMapping = this.configService.getFormMappingByType(obj.type);
    }
    obj.visible = false;
    await this.categorize(obj, formMapping);
    const objRef = doc(this.firestore, formMapping.node, obj.$key);
    updateDoc(objRef, {
      visible: obj.visible
    });
  }

  public async restoreWithObj(obj: any): Promise<void> {
    var formMapping: Form;
    if (obj.subType) {
      formMapping = this.configService.getFormMappingByTypeAndSubType(obj.type, obj.subType);
    } else {
      formMapping = this.configService.getFormMappingByType(obj.type);
    }
    obj.trash = false;
    await this.categorize(obj, formMapping);
    const objRef = doc(this.firestore, formMapping.node, obj.$key);
    updateDoc(objRef, {
      trash: obj.trash
    });
  }

  public async categorize(obj: any, formMapping: Form): Promise<any> {
    obj.signDisabled = this.checkSignDisabled(obj, formMapping);

    if ((await this.checkProperties(obj, formMapping, Status.Do))) {
      obj.workflowStatus = Status.Do;
      return obj;
    } else if ((await this.checkProperties(obj, formMapping, Status.Wait))) {
      obj.workflowStatus = Status.Wait;
      return obj;
    } else if ((await this.checkProperties(obj, formMapping, Status.Done))) {
      obj.workflowStatus = Status.Done;
      return obj;
    } else if (obj.trash && obj.visible) {
      obj.workflowStatus = Status.Trash;
      return obj;
    } else {
      obj.workflowStatus = undefined;
      return obj;
    }
  }

  public async createPdfHandler(formId: string, formMapping: Form, fileData: any, filename: string, saveDir: Directory
    , currentObj: any, preliminary: boolean): Promise<void> {
    var loading = await this.loadingController.create({});
    await loading.present();
    await this.storageService.set('share', true);
    if (this.platform.is('capacitor') && this.platform.ready() && !this.platform.is('mobileweb')) {
      if (fileData === null) {
        if (loading) {
          await loading.dismiss();
        }
        await this.openFile(saveDir, filename, 'application/pdf');
      } else if (typeof fileData === 'string') {
        await this.writePdf(fileData, saveDir, filename);
        if (loading) {
          await loading.dismiss();
        }
        await this.openFile(saveDir, filename, 'application/pdf');
      } else {
        await this.createPdfAndSaveOnStorage(formId, formMapping, fileData, saveDir, filename, currentObj, preliminary);
        if (loading) {
          await loading.dismiss();
        }
        await this.openFile(saveDir, filename, 'application/pdf');
      }
    } else {
      if (fileData === null) {
        if (loading) {
          await loading.dismiss();
        }
        const pdfWindow = window.open('');
        try {
          pdfWindow.document.write('<iframe width=\'100%\' height=\'100%\' src=\'' + filename + '\'></iframe>');
        } catch {
          let header: string, message: string, ok: string;
          this.translate.get('AUT_LOG_ERR_POPUPBLOCKED_TITLE').subscribe((result: string) => header = result);
          this.translate.get('AUT_LOG_ERR_POPUPBLOCKED_MESSAGE').subscribe((result: string) => message = result);
          this.translate.get('OK').subscribe((result: string) => ok = result);
          var alert = await this.alertCtrl.create({
            id: AlertId.PopupBlocked,
            header,
            message,
            buttons: [
              {
                text: ok
              }
            ]
          });
          await alert.present();
        }
      } else if (typeof fileData === 'string') {
        if (loading) {
          await loading.dismiss();
        }
        try {
          var encodedUri = encodeURI(fileData.replace('data:application/pdf;base64,', ''));
          var downloadLink = document.createElement('a');
          downloadLink.target = '_blank';
          downloadLink.download = filename;
          var blob = new Blob([Uint8Array.from(atob(encodedUri), c => c.charCodeAt(0))], { type: 'application/pdf' });
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);
          downloadLink.href = downloadUrl;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(downloadUrl);
        } catch (ex) {
          let header: string, message: string, ok: string;
          this.translate.get('AUT_LOG_ERR_POPUPBLOCKED_TITLE').subscribe((result: string) => header = result);
          this.translate.get('AUT_LOG_ERR_POPUPBLOCKED_MESSAGE').subscribe((result: string) => message = result);
          this.translate.get('OK').subscribe((result: string) => ok = result);
          var alert = await this.alertCtrl.create({
            id: AlertId.PopupBlocked,
            header,
            message,
            buttons: [
              {
                text: ok
              }
            ]
          });
          await alert.present();
        }
      } else {
        var pdfDocGenerator = await this.createPdf(fileData);
        pdfDocGenerator.getDataUrl(async (dataUrl: any) => {
          await this.uploadPdf(formId, formMapping, dataUrl, currentObj, preliminary);
          if (loading) {
            await loading.dismiss();
          }
          pdfDocGenerator.download(filename);
        });
      }
    }
  }

  public _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public async createMoreHandler(formId: string, formMapping: Form, fileData: any, filename: string, title: string, text: string
    , directory: Directory, currentObj: any, preliminary: boolean): Promise<void> {
    if (this.platform.is('capacitor') && this.platform.ready()) {
      var loading = await this.loadingController.create({});
      await loading.present();
      await this.storageService.set('share', true);
      if (fileData === null) {
        if (loading) {
          await loading.dismiss();
        }
        var fileUri = await this.checkFileExists(directory, filename);
        await Share.share({ title, dialogTitle: title, text, url: fileUri });
      } else if (typeof fileData === 'string') {
        await this.writePdf(fileData, directory, filename);
        if (loading) {
          await loading.dismiss();
        }
        var fileUri = await this.checkFileExists(directory, filename);
        await Share.share({ title, dialogTitle: title, text, url: fileUri });
      } else {
        await this.createPdfAndSaveOnStorage(formId, formMapping, fileData, directory, filename, currentObj, preliminary);
        if (loading) {
          await loading.dismiss();
        }
        var fileUri = await this.checkFileExists(directory, filename);
        await Share.share({ title, dialogTitle: title, text, url: fileUri });
      }
    }
  }

  public async getLogoObject(logo: string, alignment: string): Promise<{}> {
    var client = !this.authService.currentUser.client ? this.client.Basic : this.authService.currentUser.client;
    var width = this.configService.getClientMapping().logos.width;

    var decodedLogo: {};
    if (logo.includes('image/svg+xml')) {
      var svgLogo = window.atob(logo.replace('data:image/svg+xml;base64,', ''));
      decodedLogo = {
        svg: svgLogo, width: width === undefined ? 100 : width, alignment: alignment
      }
    } else {
      decodedLogo = {
        image: logo, width: width === undefined ? 100 : width, alignment: alignment
      };
    }
    return decodedLogo;
  }

  public checkFileExists(saveDir: Directory, filename: string): Promise<string | undefined> {
    return new Promise(async promise => {
      if (this.platform.is('capacitor') && this.platform.ready() && !this.platform.is('mobileweb')) {
        Filesystem.stat({
          path: filename,
          directory: saveDir
        }).then((fileStat) => {
          promise(fileStat.uri);
        }).catch(() => {
          promise(undefined);
        });
      } else {
        promise(undefined);
      }
    });
  }

  public async saveSignature(formMapping: Form, value: string, location: string, date: Timestamp, issuer?: boolean): Promise<void> {
    if (value.includes('https://')) {
      return;
    } else {
      var currentObj = await this.loadCurrent(formMapping.type, formMapping.node, this.currentObject.$key, true, true);

      var signatureObj: { twoSignatures: boolean, location: string, date: Timestamp, issuer?: boolean };

      if (formMapping.signatures === 2) {
        signatureObj = { twoSignatures: true, location, date, issuer };
      } else {
        signatureObj = { twoSignatures: false, location, date };
      }

      var contractTitle = formMapping.translations.title;
      this.imageService.putImageToFirebaseStorage(this.getCurrentObject().$key + '_' + new Date().getTime().toString()
        , this.getCurrentObject().$key, this.getCurrentObject().number, contractTitle, formMapping.node, 'signatures', formMapping.node, value, currentObj, 'signatures', true
        , this, this.authService.currentUser, signatureObj);

      await this.update(formMapping.node, formMapping.type, formMapping.subType?.name);
    }
  }

  public async createDocumentDefinition(fileFormat: string, language: string, filename: string, logos: any, preliminary: boolean): Promise<any> {
    let signature1: any, signature2: any;

    if (this.getCurrentObject().signatures) {
      const getSignature1FromStoragePromise = this.imageService.getImageFromUrl(this.getCurrentObject().signatures[0]?.value);
      const getSignature2FromStoragePromise = this.imageService.getImageFromUrl(this.getCurrentObject().signatures[1]?.value);


      if (this.getCurrentObject().signatures[0]) {
        const [image1, image2] = await Promise.all([getSignature1FromStoragePromise, getSignature2FromStoragePromise]);

        if (image1) {
          signature1 = image1;
          if (image2) {
            signature2 = image2;
            var dd = await this.handleDocumentCreation(signature1, signature2, fileFormat, language, filename, logos, preliminary);
            return dd;
          } else {
            var dd = await this.handleDocumentCreation(signature1, signature2, fileFormat, language, filename, logos, preliminary);
            return dd;
          }
        }
      } else {
        if (this.getCurrentObject().signatures[0]) {
          signature1 = this.getCurrentObject().signatures[0].value;
        }
        if (this.getCurrentObject().signatures[1]) {
          signature2 = this.getCurrentObject().signatures[1].value;
        }

        var dd = await this.handleDocumentCreation(signature1, signature2, fileFormat, language, filename, logos, preliminary);
        return dd;
      }
    } else {
      var dd = await this.handleDocumentCreation(signature1, signature2, fileFormat, language, filename, logos, preliminary);
      return dd;
    }
  }

  public async addElementToMasterDataHistory(masterDataId: string, formMapping: Form, formNode: string, formType: string, formSubType: string, formTitle: string, currentObj: any): Promise<void> {
    var newHistoryObj: any = {
      date: Timestamp.fromDate(new Date())
      , description: formMapping.details.reason.map(r => currentObj[r]).join(' / ')
      , descriptionTitle: 'REASON'
      , formId: currentObj.$key
      , documentNumber: currentObj.number
      , formType: formType
      , result: formTitle
    };

    if (formSubType) {
      newHistoryObj.formSubType = formSubType;
    }

    // Remove undefined fields saving
    await Utils.deleteUndefinedProperties(newHistoryObj);

    this.masterDataService.addElementToMasterDataHistory(
      formNode
      , masterDataId
      , newHistoryObj
    );
  }

  public async getEmployees(employeeIds?: Array<string>): Promise<Array<{ id: string, name: string }>> {
    if (!this.authService.currentUser.client
      ||
      this.authService.currentUser.client === this.client.Free || this.authService.currentUser.client === this.client.Basic || this.authService.currentUser.client === this.client.Advance) {
      var decryptedFirstname = this.cryptoService.decryptValue(this.authService.currentUser.firstname);
      var decryptedLastname = this.cryptoService.decryptValue(this.authService.currentUser.lastname);
      var decryptedName = decryptedFirstname + ' ' + decryptedLastname;
      console.log('decryptedName: ', decryptedName);

      return [{ id: this.authService.currentUser.$key, name: decryptedName }];
    }

    const objRef = collection(this.firestore, 'users');
    const q = query(objRef, where('client', '==', this.authService.currentUser.client));
    const users = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? await getDocsFromServer(q) : await getDocsFromCache(q);

    var result = users.docs
      .filter(a => a.exists)
      .map(user => {
        const $key = user.id;
        const data: any = user.data();
        if (employeeIds === undefined || (employeeIds.length > 0 && employeeIds.includes($key))) {
          if (data.firstname || data.lastname) {
            let name: string;
            if (data.firstname && data.lastname) {
              name = this.cryptoService.decryptValue(data.firstname) + ' ' + this.cryptoService.decryptValue(data.lastname);
            } else if (data.firstname) {
              name = this.cryptoService.decryptValue(data.firstname);
            } else {
              name = this.cryptoService.decryptValue(data.lastname);
            }
            return { id: $key, name };
          }
        }
      })
      .filter(r => r);

    return result;
  }

  private async handleDocumentCreation(signature1: any, signature2: any, fileFormat: string, language: string, filename: string, logos: any, preliminary: boolean): Promise<any> {
    let currentObj = this.getCurrentObject();
    let formMapping: Form;
    if (currentObj.subType) {
      formMapping = this.configService.getFormMappingByTypeAndSubType(currentObj.type, currentObj.subType);
    } else {
      formMapping = this.configService.getFormMappingByType(currentObj.type);
    }

    let formTitle: string;
    let formNumber: string;
    let pageOf: string;
    let preliminaryText: string;
    let issuerTitle: string;
    let recipientTitle: string;
    let productNumber: string;
    let issuerContactNumber: string;
    let recipientContactNumber: string;

    let profileSalutation: string;
    let profileTitle: string;
    let profileCompany: string;
    let profileFirstname: string;
    let profileLastname: string;
    let profileBirthdate: string;
    let profileStreet: string;
    let profilePostalCode: string;
    let profileCity: string;
    let profileState: string;
    let profilePhone: string;
    let profileEmail: string;

    let profilePaymentIban: string;
    let profilePaymentBic: string;
    let profilePaymentInstitution: string;
    let profileSalesTaxId: string;
    let profileTaxNumber: string;
    var pageNumber: string;
    this.pdfContainsCoverImage = false;
    let signaturesObj: {
      signatureWidths: Array<string>,
      signatureValues: any,
      signatureLocations: any,
      signatureDates: any
    } = { signatureWidths: new Array<string>(), signatureValues: undefined, signatureLocations: undefined, signatureDates: undefined };

    this.translate.get(formMapping.translations.title).subscribe((result: string) => formTitle = result);
    this.translate.get('PDF_NUMBER').subscribe((result: string) => formNumber = result);
    this.translate.get('PDF_OF').subscribe((result: string) => pageOf = result);
    this.translate.get(formMapping.issuer?.translation ? formMapping.issuer.translation : 'ISSUER').subscribe((result: string) => issuerTitle = result);
    this.translate.get(formMapping.recipient?.translation ? formMapping.recipient.translation : 'RECIPIENT').subscribe((result: string) => recipientTitle = result);
    this.translate.get('PRELIMINARY').subscribe((result: string) => preliminaryText = result);

    if (currentObj.productId) {
      productNumber = (await this.productService.getCurrentMasterDataObjs()).filter(m => m.$key === currentObj.productId).pop()?.number;
    }
    if (currentObj.issuer_contactId) {
      issuerContactNumber = (await this.contactService.getCurrentMasterDataObjs()).filter(m => m.$key === currentObj.issuer_contactId).pop()?.number;
    }
    if (currentObj.recipient_contactId) {
      recipientContactNumber = (await this.contactService.getCurrentMasterDataObjs()).filter(m => m.$key === currentObj.recipient_contactId).pop()?.number;
    }

    profileSalutation = this.authService.currentUser.salutation;
    profileTitle = this.authService.currentUser.title;
    profileCompany = this.authService.currentUser.company;
    profileFirstname = this.authService.currentUser.firstname;
    profileLastname = this.authService.currentUser.lastname;
    profileBirthdate = this.authService.currentUser.birthdate === undefined || this.authService.currentUser.birthdate === null
      ? ''
      : typeof this.authService.currentUser.birthdate === 'string'
        ? this.authService.currentUser.birthdate
        : DateHelpers.getFormattedDateFromTimestamp(this.authService.currentUser.birthdate);
    profileStreet = this.authService.currentUser.street;
    profilePostalCode = this.authService.currentUser.postalcode;
    profileCity = this.authService.currentUser.city;
    profileState = this.authService.currentUser.state;
    profilePhone = this.authService.currentUser.phoneNr;
    profileEmail = this.authService.currentUser.email;

    profilePaymentIban = this.authService.currentUser.paymentIban;
    profilePaymentBic = this.authService.currentUser.paymentBic;
    profilePaymentInstitution = this.authService.currentUser.paymentInstitution;
    profileSalesTaxId = this.authService.currentUser.salesTaxId;
    profileTaxNumber = this.authService.currentUser.taxNumber;
    pageNumber = '<pageNumber>';

    var variables = new Map<string, string>();
    variables.set(Object.keys({ formTitle })[0], formTitle);
    variables.set(Object.keys({ formNumber })[0], formNumber);
    variables.set(Object.keys({ issuerTitle })[0], issuerTitle);
    variables.set(Object.keys({ recipientTitle })[0], recipientTitle);
    variables.set(Object.keys({ productNumber })[0], productNumber);
    variables.set(Object.keys({ issuerContactNumber })[0], issuerContactNumber);
    variables.set(Object.keys({ recipientContactNumber })[0], recipientContactNumber);
    variables.set(Object.keys({ profileSalutation })[0], profileSalutation);
    variables.set(Object.keys({ profileTitle })[0], profileTitle);
    variables.set(Object.keys({ profileCompany })[0], profileCompany);
    variables.set(Object.keys({ profileFirstname })[0], profileFirstname);
    variables.set(Object.keys({ profileLastname })[0], profileLastname);
    variables.set(Object.keys({ profileBirthdate })[0], profileBirthdate);
    variables.set(Object.keys({ profileStreet })[0], profileStreet);
    variables.set(Object.keys({ profilePostalCode })[0], profilePostalCode);
    variables.set(Object.keys({ profileCity })[0], profileCity);
    variables.set(Object.keys({ profileState })[0], profileState);
    variables.set(Object.keys({ profilePhone })[0], profilePhone);
    variables.set(Object.keys({ profileEmail })[0], profileEmail);
    variables.set(Object.keys({ profilePaymentIban })[0], profilePaymentIban);
    variables.set(Object.keys({ profilePaymentBic })[0], profilePaymentBic);
    variables.set(Object.keys({ profilePaymentInstitution })[0], profilePaymentInstitution);
    variables.set(Object.keys({ profileSalesTaxId })[0], profileSalesTaxId);
    variables.set(Object.keys({ profileTaxNumber })[0], profileTaxNumber);
    variables.set(Object.keys({ pageNumber })[0], pageNumber);

    var correspondingLogo: string = language === 'de_DE' ? logos.de : logos.en;
    var logoObj: {} = await this.getLogoObject(correspondingLogo, 'left');
    signaturesObj = this.getSignatures(signature1, signature2, signaturesObj, currentObj, issuerTitle, recipientTitle, formMapping.signatures, preliminary);

    var content: Array<any> = new Array<any>();
    var header: Array<any> = new Array<any>();
    var footer: Array<any> = new Array<any>();
    var pageMargins: Array<number>;
    var headerOnlyFirstPage: boolean = false;

    // Dynamic rows
    await Utils.asyncForEach(formMapping.pdf, async (p: FormPdf) => {
      if (p.skipConditions) {
        let skip = await Utils.checkConditions(currentObj, p.skipConditions, this.authService.currentUser.$key);
        if (skip) { return; }
      }

      if (p.showConditions) {
        let show = await Utils.checkConditions(currentObj, p.showConditions, this.authService.currentUser.$key);
        if (!show) { return; }
      }

      if (p.type === PdfType.PageMargins) {
        pageMargins = p.margin;
      } else if (p.type === PdfType.Header || (p.type === PdfType.SeparateHeader && p.name)) {
        let table: FormPdfTable;
        let layout: string;
        let skipIfEmpty: boolean;
        if (p.onlyFirstPage) {
          headerOnlyFirstPage = true;
        }

        if (p.type === PdfType.Header) {
          table = p.table;
          layout = p.layout;
          skipIfEmpty = p.skipIfEmpty;
        } else {
          var pdfHeader = this.configService.getPdfHeadersMappingByName(p.name);
          if (!pdfHeader) {
            return;
          }
          table = pdfHeader.content.table;
          layout = pdfHeader.content.layout;
          skipIfEmpty = pdfHeader.content.skipIfEmpty;
        }

        var body = await this.transformBody(table.body, currentObj, variables, logoObj, language);
        if (skipIfEmpty) {
          var isEmpty = this.isBodyEmpty(body);
          if (isEmpty) { return; }
        }

        let headerObj = {
          table: {
            widths: table.widths,
            dontBreakRows: table.dontBreakRows,
            headerRows: table.headerRows,
            keepWithHeaderRows: table.keepWithHeaderRows,
            body
          },
          layout: layout,
          margin: p.margin
        };

        if (p.onlyFirstPage) {
          content.push(headerObj);
        } else {
          header.push(headerObj);
        }
      } else if (p.type === PdfType.Content) {
        var body = await this.transformBody(p.table.body, currentObj, variables, logoObj, language);
        if (p.skipIfEmpty) {
          var isEmpty = this.isBodyEmpty(body);
          if (isEmpty) { return; }
        }
        content.push({
          table: {
            widths: p.table.widths,
            dontBreakRows: p.table.dontBreakRows,
            headerRows: p.table.headerRows,
            keepWithHeaderRows: p.table.keepWithHeaderRows,
            body
          },
          layout: p.layout
        });
      } else if (p.type === PdfType.Empty) {
        content.push({ text: '', border: [false, false, false, false], margin: p.margin ? p.margin : [0, 5, 0, 5] });
      } else if (p.type === PdfType.Participants) {
        content.push(this.getParticipantsTable(issuerTitle, recipientTitle, issuerContactNumber, recipientContactNumber));
      } else if (p.type === PdfType.Modal) {
        content.push(await this.getModalTable(formMapping, p.modalKey, p.imageOptions));
      } else if (p.type === PdfType.ModalTable) {
        if (p.modalKey !== undefined && currentObj[p.modalKey]) {
          var modalObj = currentObj[p.modalKey];
          var body = new Array<Array<any>>();
          var serialNumber = 0;
          await Utils.asyncForEach(modalObj, async (element: any) => {
            serialNumber++;
            var elementBody = await this.transformBody(p.table.body, element, variables, logoObj, language, serialNumber);
            if (p.skipIfEmpty) {
              var isEmpty = this.isBodyEmpty(elementBody);
              if (isEmpty) { return; }
            }
            body.push(...elementBody);
          });
          content.push({
            table: {
              widths: p.table.widths,
              dontBreakRows: p.table.dontBreakRows,
              headerRows: p.table.headerRows,
              keepWithHeaderRows: p.table.keepWithHeaderRows,
              body
            },
            layout: p.layout
          });
        }
      } else if (p.type === PdfType.PageBreak) {
        content.push({ text: '', pageBreak: 'before' });
      } else if (p.type === PdfType.Footer) {
        var body = await this.transformBody(p.table.body, currentObj, variables, logoObj, language);
        if (p.skipIfEmpty) {
          var isEmpty = this.isBodyEmpty(body);
          if (isEmpty) { return; }
        }
        footer.push({
          table: {
            widths: p.table.widths,
            dontBreakRows: p.table.dontBreakRows,
            headerRows: p.table.headerRows,
            keepWithHeaderRows: p.table.keepWithHeaderRows,
            body
          },
          layout: p.layout
        });
      }
    });

    // Images
    const galaryImages = await this.getGalaryImages(this.pdfContainsCoverImage);
    if (galaryImages.length > 0) {
      content.push({
        table: {
          widths: ['50%', '50%'],
          dontBreakRows: true,
          headerRows: 1,
          keepWithHeaderRows: true,
          body: galaryImages
        },
        layout: 'noBorders'
      });
    }

    // Signatures
    if ((signature1 || signature2 || preliminary) && signaturesObj) {
      content.push({
        style: 'signaturesTable',
        table: {
          widths: signaturesObj.signatureWidths,
          dontBreakRows: true,
          headerRows: 3,
          body: [
            signaturesObj.signatureValues,
            signaturesObj.signatureLocations,
            signaturesObj.signatureDates
          ]
        }
      });
    }

    const documentDefinition = {
      pageSize: fileFormat.toUpperCase(),
      pageMargins,
      info: {
        title: filename,
        author: 'Thumbify',
        subject: formTitle,
        keywords: ''
      },
      watermark: preliminary ? { text: preliminaryText, opacity: 0.05, bold: true, italics: false } : undefined,
      header: () => {
        return header;
      },
      content,
      footer: (currentPage: any, pageCount: any) => {
        let tempFooter = new Array<any>();
        tempFooter = JSON.parse(JSON.stringify(footer));

        tempFooter.forEach((value: any) => {
          value.table.body.forEach((row: Array<any>) => {
            let pageNumberColumn = row.filter((column: any) => column.text === pageNumber)[0];
            if (pageNumberColumn) {
              pageNumberColumn.text = currentPage.toString() + ' ' + pageOf + ' ' + pageCount;
            }
          });
        });

        // console.log('tempFooter', tempFooter);
        // let lastEntry = tempFooter[tempFooter.length - 1];
        // console.log('table', lastEntry.table);
        // console.log('body', lastEntry.table.body);
        // console.log('body[0]', lastEntry.table.body[0]);
        // lastEntry.table.body[0] = new Array<any>();
        // lastEntry.table.body[0].push({ image: this.writeRotatedText('I am rotated'), fit: [7, 53], alignment: 'center' });
        return tempFooter;
      },
      styles: {
        header: {
          fontSize: 14,
          bold: true
        },
        midheader: {
          fontSize: 12,
          bold: false
        },
        subheader: {
          fontSize: 11,
          bold: false
        },
        standardTextBold: {
          fontSize: 9,
          bold: true
        },
        standardText: {
          fontSize: 9,
          bold: false
        },
        standardTextSmallBold: {
          fontSize: 7,
          bold: true
        },
        standardTextSmall: {
          fontSize: 7,
          bold: false
        },
        standardTextVerySmallBold: {
          fontSize: 5.5,
          bold: true
        },
        standardTextVerySmall: {
          fontSize: 5.5,
          bold: false
        },
        tableHeader: {
          bold: true
        },
        footer: {
          fontSize: 9,
          bold: false,
          margin: [0, 5, 0, 15]
        },
        itemsTable: {
          fontSize: 9,
          margin: [0, 5, 0, 15]
        },
        icon: {
          font: 'Fontello'
        }
      },
      defaultStyle: {
        font: 'montserratLight'
      }
    };
    return documentDefinition;
  }

  private writeRotatedText(text: string): string {
    var ctx: CanvasRenderingContext2D, canvas = document.createElement('canvas');
    canvas.width = 36;
    canvas.height = 270;
    ctx = canvas.getContext('2d');
    ctx.font = '36pt Arial';
    ctx.save();
    ctx.translate(36, 270);
    ctx.rotate(-0.5 * Math.PI);
    ctx.fillStyle = '#000';
    ctx.fillText(text, 0, 0);
    ctx.restore();
    return canvas.toDataURL();
  };

  private async transformBody(body: Array<Array<FormPdfTableBody>>, currentObj: any, variables: Map<string, string>, logoObj: {}, language: string, modalSerialNumber?: number): Promise<Array<Array<any>>> {
    var transformedBody = new Array<Array<any>>();

    await Utils.asyncForEach(body, async (row: Array<FormPdfTableBody>) => {
      var newRow = new Array<any>();
      await Utils.asyncForEach(row, async (column: FormPdfTableBody) => {
        if (column.type === BodyType.Text || column.type === BodyType.NetAmount || column.type === BodyType.VatAmount || column.type === BodyType.TotalAmount
          || column.type === BodyType.Count || column.type === BodyType.ModalSerialNumber) {
          var text: string;
          if (column.type === BodyType.Text) {
            text = this.replaceValues(currentObj, column.text, variables, language);
          } else if (column.type === BodyType.NetAmount) {
            text = await this.getSpecificAmount(column.attributes, currentObj, language, column.type, column.handler);
          } else if (column.type === BodyType.VatAmount) {
            text = await this.getSpecificAmount(column.attributes, currentObj, language, column.type, column.handler);
          } else if (column.type === BodyType.TotalAmount) {
            text = await this.getSpecificAmount(column.attributes, currentObj, language, column.type, column.handler);
          } else if (column.type === BodyType.Count) {
            text = currentObj[column.property] ? currentObj[column.property].length : 0;
          } else if (column.type === BodyType.ModalSerialNumber) {
            text = modalSerialNumber.toString();
          }
          var newColumn = {
            text
            , style: column.style
            , color: column.color
            , fillColor: column.fillColor
            , margin: column.margin
            , alignment: column.alignment
            , border: column.border
            , rowSpan: column.rowSpan
          };
          newRow.push(newColumn);
        } else if (column.type === BodyType.Table) {
          var body = await this.transformBody(column.body, currentObj, variables, logoObj, language);
          newRow.push({
            table: {
              widths: column.widths,
              dontBreakRows: column.dontBreakRows,
              headerRows: column.headerRows,
              keepWithHeaderRows: column.keepWithHeaderRows,
              body
            },
            layout: column.layout
          });
        } else if (column.type === BodyType.CoverImage) {
          const coverImage = await this.getCoverImage(column.colSpan, column.rowSpan);
          newRow.push(coverImage);
          this.pdfContainsCoverImage = true;
        } else if (column.type === BodyType.Image) {
          if (currentObj[column.property]) {
            var imageData = await this.imageService.getImageFromUrl(currentObj[column.property]);
            return {
              image: imageData
              , height: !column.height ? 100 : column.height
              , width: !column.width ? 100 : column.width
              , border: [false, false, false, false]
              , alignment: 'left'
            };
          } else {
            return { text: '' };
          }
        } else if (column.type === BodyType.Checkbox) {
          newRow.push({ text: currentObj[column.property] ? '' : '', style: 'icon' });
        } else if (column.type === BodyType.Empty) {
          newRow.push({});
        } else if (column.type === BodyType.Logo) {
          newRow.push(logoObj);
        } else if (column.type === BodyType.Address) {
          newRow.push({ text: this.getAddress() });
        }
      });
      transformedBody.push(newRow);
    });

    return transformedBody;
  }

  private replaceValues(currentObj: any, text: FormPdfTableBodyText, variables: Map<string, string>, language: string): string {
    var values = new Array<string>();

    text.values.forEach((value: FormPdfTableBodyTextValue) => {
      var valid = !value.conditions
        || value.conditions.every((c: FormCondition) => Utils.checkDynamicCondition(currentObj[c.property], c.comparison, c.value, this.authService.currentUser.$key));

      if (valid) {
        if (value.type === ValueType.Translation) {
          var translation: string;
          this.translate.get(value.name).subscribe((result: string) => translation = result);
          if (value.handler || value.replacements) {
            translation = this.processTextHandler(translation, value.handler, value.replacements, variables, language);
          }
          values.push(translation);
        } else if (value.type === ValueType.Property) {
          var propertyValue = currentObj[value.name];
          if (propertyValue !== undefined || propertyValue !== null) {
            var result = this.processProperty(value.name, propertyValue, value.format, value.handler, currentObj.currency, language);
            if (value.handler || value.replacements) {
              result = this.processTextHandler(result, value.handler, value.replacements, variables, language);
            }
            values.push(result);
          } else {
            values.push('-');
          }
        } else if (value.type === ValueType.Variable) {
          var variableValue = variables.get(value.name);
          if (value.handler || value.replacements) {
            variableValue = this.processTextHandler(variableValue, value.handler, value.replacements, variables, language);
          }
          values.push(variableValue);
        } else if (value.type === ValueType.Text) {
          var textValue = value.name;
          if (value.handler || value.replacements) {
            textValue = this.processTextHandler(textValue, value.handler, value.replacements, variables, language);
          }
          values.push(textValue);
        }
      }
    });

    return text.separator ? values.join(text.separator) : values.join('');
  }

  private async getModalTable(formMapping: Form, modalKey: string, imageOptions: FormPdfImageOptions): Promise<any> {
    var modalAttribute = formMapping.attributes.filter(a => a.controls[0].type === ControlType.Modal)
      .filter(a => a.controls.find(c => c.key === modalKey)).pop();

    if (!modalAttribute) { return; }

    return {
      table: {
        widths: this.getWidths(modalAttribute.controls[0].modalControls?.length + 1),
        dontBreakRows: true,
        headerRows: 2,
        keepWithHeaderRows: true,
        body: await this.getModalBody(modalAttribute, imageOptions)
      },
      layout: 'borders'
    };
  }

  private async getModalBody(modalAttribute: FormAttribute, imageOptions: FormPdfImageOptions): Promise<Array<any>> {
    const promises = [];
    const values = new Array<any>();
    let title: string;
    let picture: string;

    var modalControls = modalAttribute.controls[0].modalControls;

    if (!modalControls) { return; }

    // Add title
    this.translate.get(modalAttribute.list.divider.text).subscribe((result: string) => title = result);
    var valueHeader = new Array<any>();
    valueHeader.push({ text: title, border: [false, false, false, true], alignment: 'left', style: BodyStyle.StandardTextBold, colSpan: modalControls.length });
    for (var i = 0; i < modalControls.length; i++) {
      valueHeader.push({ text: '', border: [false, false, false, true] });
    }
    values.push(valueHeader);

    //Add subheader values
    this.translate.get('IMAGE').subscribe((result: string) => picture = result);
    var valuesSubHeader = new Array<any>();
    modalControls.forEach((modalControl: FormAttributeControl) => {
      let translation: string;
      this.translate.get(modalControl.options.label).subscribe((result: string) => translation = result);
      valuesSubHeader.push({ text: translation, style: BodyStyle.StandardTextSmallBold, border: [true, true, true, true], alignment: 'left' });
    });
    valuesSubHeader.push({ text: picture, style: BodyStyle.StandardTextSmallBold, border: [true, true, true, true], alignment: 'left' });
    values.push(valuesSubHeader);

    if (this.getCurrentObject()[modalAttribute.controls[0].key]) {
      // Add table content
      await Utils.asyncForEach(this.getCurrentObject()[modalAttribute.controls[0].key], async (item: any) => {
        let valuesContent = new Array<any>();
        modalControls.forEach((modalControl: FormAttributeControl) => {
          var text: string;
          if (item[modalControl.key]) {
            if (modalControl.type === ControlType.Select && !modalControl.selectOptions.itemsVariable) {
              let translation: string;
              let translationKey = modalControl.selectOptions.items.filter(i => i.value === item[modalControl.key])[0]?.text;
              if (translationKey !== undefined) {
                this.translate.get(translationKey).subscribe((result: string) => translation = result);
                text = translation;
              } else {
                text = item[modalControl.key];
              }
              valuesContent.push({ text, border: [true, true, true, true], alignment: 'left', style: BodyStyle.StandardText });
            } else {
              valuesContent.push({ text: item[modalControl.key], border: [true, true, true, true], alignment: 'left', style: BodyStyle.StandardText });
            }
          } else {
            valuesContent.push({ text: '-', border: [true, true, true, true], alignment: 'left', style: BodyStyle.StandardText });
          }
        });

        if (item.image) {
          const newPromise = this.imageService.getImageFromUrl(item.image).then(imageData => {
            if (imageData !== null) {
              valuesContent.push({
                image: imageData
                , height: !imageOptions?.height ? 100 : imageOptions?.height
                , width: !imageOptions?.width ? 100 : imageOptions?.width
                , border: [true, true, true, true]
                , alignment: !imageOptions?.alignment ? 'right' : imageOptions?.alignment
              });
            }
          });
          promises.push(newPromise);
        } else {
          valuesContent.push({ text: '-', border: [true, true, true, true], alignment: 'left', style: BodyStyle.StandardText });
        }

        values.push(valuesContent);
      });
      await Promise.all(promises);
      return values;
    } else {
      let valuesContent = new Array<any>();
      modalControls.forEach(() => {
        valuesContent.push({ text: '-', border: [false, false, false, false], alignment: 'left', style: BodyStyle.StandardText });
      });
      valuesContent.push({ text: '-', border: [false, false, false, false], alignment: 'left', style: BodyStyle.StandardText });
      values.push(valuesContent);
      return values;
    }
  }

  private async getSpecificAmount(attributes: FormPdfTableBodyAttributes, currentObj: any, language: string, columnType: BodyType, handler: Array<ValueHandler>): Promise<string> {
    if (attributes && attributes.amount.includes('[')) {
      var arrayName = attributes.amount.substring(0, attributes.amount.indexOf('['));
      if (attributes.amount?.match(/\[/g)?.length > 1) {
        let arrayElementString: string;
        arrayElementString = attributes.amount.substring(attributes.amount.indexOf('[') + 1);
        let arrayElementInt = parseInt(arrayElementString.substring(0, arrayElementString.indexOf(']')));
        let amountsize = this.getSpecificAttributeValues(attributes.amountsize, arrayName, arrayElementInt, currentObj);
        let currency = this.getSpecificAttributeValues(attributes.currency, arrayName, arrayElementInt, currentObj);
        let amount = this.getSpecificAttributeValues(attributes.amount, arrayName, arrayElementInt, currentObj);
        let vatrate = this.getSpecificAttributeValues(attributes.vatrate, arrayName, arrayElementInt, currentObj);
        return this.calculateSpecificAmount(amountsize, currency, amount, vatrate, language, columnType, handler, true);
      } else {
        let sum = 0;
        if (currentObj[arrayName]) {
          let i = 0;
          var currency: string;
          await Utils.asyncForEach(currentObj[arrayName], async () => {
            let amountsize = this.getSpecificAttributeValues(attributes.amountsize, arrayName, i, currentObj);
            currency = this.getSpecificAttributeValues(attributes.currency, arrayName, i, currentObj);
            let amount = this.getSpecificAttributeValues(attributes.amount, arrayName, i, currentObj);
            let vatrate = this.getSpecificAttributeValues(attributes.vatrate, arrayName, i, currentObj);
            sum += this.calculateSpecificAmount(amountsize, currency, amount, vatrate, language, columnType, handler, false);
            i++;
          });
          return AmountHelpers.getFormattedAmountByLanguageByCurrency(sum, currency, language);
        } else {
          return;
        }
      }
    } else {
      let amountsize = currentObj.amountsize;
      let currency = currentObj.currency;
      let amount = currentObj.amount;
      let vatrate = currentObj.vatrate;
      return this.calculateSpecificAmount(amountsize, currency, amount, vatrate, language, columnType, handler, true);
    }
  }

  private getSpecificAttributeValues(attributeName: string, arrayName: string, arrayElement: number, currentObj: any): any {
    if (attributeName) {
      if (attributeName.includes('[')) {
        var propertyName: string;
        propertyName = attributeName.substring(attributeName.lastIndexOf('[') + 1, attributeName.lastIndexOf(']'));
        if (currentObj[arrayName] && currentObj[arrayName][arrayElement] && currentObj[arrayName][arrayElement][propertyName]) {
          return currentObj[arrayName][arrayElement][propertyName];
        } else {
          return currentObj[propertyName];
        }
      } else {
        return currentObj[attributeName];
      }
    } else {
      return currentObj[attributeName];
    }
  }

  private calculateSpecificAmount(amountsize: string, currency: string, amount: any, vatrate: any, language: string, columnType: BodyType, handler: Array<ValueHandler>, withFormatting: boolean): any {
    var result: string | number;
    if (columnType === BodyType.NetAmount) {
      result = AmountHelpers.calculateNetAmount(amountsize, currency, amount, vatrate, language, false);
    } else if (columnType === BodyType.VatAmount) {
      result = AmountHelpers.calculateVat(amountsize, currency, amount, vatrate, language, false);
    } else {
      result = AmountHelpers.calculateTotal(amountsize, currency, amount, vatrate, language, false);
    }

    if (handler) {
      result = this.processAmountHandler(result, handler);
    }

    if (withFormatting) {
      result = AmountHelpers.getFormattedAmountByLanguageByCurrency(result, currency, language);
    }

    return result;
  }

  private getWidths(columnCount: number): Array<string> {
    var widthsArray = new Array<string>();

    for (var i = 0; i < columnCount; i++) {
      widthsArray.push('auto');
    }

    return widthsArray;
  }

  private getSignatures(signature1: any, signature2: any
    , signaturesObj: {
      signatureWidths: Array<string>, signatureValues: any, signatureLocations: any, signatureDates: any
    }, currentObj: any, issuerTitle: string, recipientTitle: string, signatureCount: number, preliminary: boolean): any {

    if (!signature1 && !signature2 && !preliminary) { return; }

    if (preliminary && !signatureCount) { return; }

    if (!preliminary || (preliminary && (signature1 || signature2))) {
      if (!signature2) {
        signaturesObj.signatureWidths = ['46%'];

        signaturesObj.signatureValues = [
          {
            image: signature1 === undefined ? 'data:image/png;base64' : signature1,
            width: 180,
            border: [false, false, false, true]
          }
        ];

        signaturesObj.signatureLocations = [
          {
            text: !currentObj.issuer_name ? '' : currentObj.issuer_name + ' (' + issuerTitle + ')',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          }
        ];

        signaturesObj.signatureDates = [
          {
            text: !signature1 ? '' : (DateHelpers.getFormattedSignatureDate(currentObj.signatures[0].date) + ', ' + currentObj.signatures[0].location),
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          }
        ];
      } else {
        signaturesObj.signatureWidths = ['46%', '*', '46%'];

        signaturesObj.signatureValues = [
          {
            image: !signature1 ? 'data:image/png;base64' : signature1,
            width: 180,
            border: [false, false, false, true]
          },
          {
            text: '',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          },
          {
            image: !signature2 ? 'data:image/png;base64' : signature2,
            width: 180,
            border: [false, false, false, true]
          }
        ];

        signaturesObj.signatureLocations = [
          {
            text: !currentObj.issuer_name ? '' : currentObj.issuer_name + ' (' + issuerTitle + ')',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          },
          {
            text: '',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          },
          {
            text: !currentObj.recipient_name ? '' : currentObj.recipient_name + ' (' + recipientTitle + ')',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          }
        ];

        signaturesObj.signatureDates = [
          {
            text: !signature1 ? '' : (DateHelpers.getFormattedSignatureDate(currentObj.signatures[0].date) + ', ' + currentObj.signatures[0].location),
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          },
          {
            text: '',
            border: [false, false, false, false],
            tyle: BodyStyle.StandardText
          },
          {
            text: !signature2 ? '' : (DateHelpers.getFormattedSignatureDate(currentObj.signatures[1].date) + ', ' + currentObj.signatures[1].location),
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          }
        ];
      }
    } else {
      let name: string;
      let date: string;
      let location: string;

      this.translate.get('NAME').subscribe((result: string) => name = result);
      this.translate.get('SIG_DATE').subscribe((result: string) => date = result);
      this.translate.get('LOCATION').subscribe((result: string) => location = result);

      if (signatureCount === 1) {
        signaturesObj.signatureWidths = ['46%'];

        signaturesObj.signatureValues = [
          {
            text: '',
            border: [false, false, false, true],
            margin: [0, 100, 0, 0],
            style: BodyStyle.StandardText
          }
        ];

        signaturesObj.signatureLocations = [
          {
            text: (!currentObj.issuer_name ? name : currentObj.issuer_name) + ' (' + issuerTitle + ')',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          }
        ];

        signaturesObj.signatureDates = [
          {
            text: date + ', ' + location,
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          }
        ];
      } else {
        signaturesObj.signatureWidths = ['46%', '*', '46%'];

        signaturesObj.signatureValues = [
          {
            text: '',
            border: [false, false, false, true],
            margin: [0, 100, 0, 0],
            style: BodyStyle.StandardText
          },
          {
            text: '',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          },
          {
            text: '',
            border: [false, false, false, true],
            margin: [0, 100, 0, 0],
            style: BodyStyle.StandardText
          }
        ];

        signaturesObj.signatureLocations = [
          {
            text: (!currentObj.issuer_name ? name : currentObj.issuer_name) + ' (' + issuerTitle + ')',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          },
          {
            text: '',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          },
          {
            text: (!currentObj.recipient_name ? name : currentObj.recipient_name) + ' (' + recipientTitle + ')',
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          }
        ];

        signaturesObj.signatureDates = [
          {
            text: date + ', ' + location,
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          },
          {
            text: '',
            border: [false, false, false, false],
            tyle: BodyStyle.StandardText
          },
          {
            text: date + ', ' + location,
            border: [false, false, false, false],
            style: BodyStyle.StandardText
          }
        ];
      }
    }

    return signaturesObj;
  }

  private processTextHandler(text: string, handler: Array<ValueHandler>, replacements: Array<FormPdfTableBodyTextValueReplacement>, variables: Map<string, string>
    , language: string): string {
    if (text === undefined) {
      return '';
    }

    let currentObj = this.getCurrentObject();

    if (handler) {
      if (handler.includes(ValueHandler.LowerCase)) {
        text = text.toLowerCase();
      }
      if (handler.includes(ValueHandler.UpperCase)) {
        text = text.toUpperCase();
      }
    }

    if (replacements) {
      replacements.forEach((replacement: FormPdfTableBodyTextValueReplacement) => {
        var value: string;
        if (replacement.value.type === ValueType.Property) {
          var propertyValue = currentObj[replacement.value.name];
          value = this.processProperty(replacement.value.name, propertyValue, replacement.value.format, replacement.value.handler, currentObj.currency, language);
        } else if (replacement.value.type === ValueType.Text) {
          value = replacement.value.name;
        } else if (replacement.value.type === ValueType.Translation) {
          var translation: string;
          this.translate.get(replacement.value.name).subscribe((result: string) => translation = result);
          value = translation;
        } else if (replacement.value.type === ValueType.Variable) {
          value = variables.get(replacement.value.name);
        }

        text = text.replace(replacement.key, value);
      });
    }

    return text;
  }

  private processProperty(propertyName: string, propertyValue: any, format: ValueFormat, handler: Array<ValueHandler>, currency: string, language: string): string {
    if (format === ValueFormat.Number) {
      let number = propertyValue === undefined || propertyValue === null ? 0 : propertyValue;
      if (handler) {
        number = this.processAmountHandler(number, handler);
      }
      let formattedNumber = AmountHelpers.getFormattedAmountByLanguage(number, language);
      return formattedNumber;
    } else if (format === ValueFormat.Amount) {
      let amount = propertyValue === undefined || propertyValue === null ? 0 : propertyValue;
      if (handler) {
        amount = this.processAmountHandler(amount, handler);
      }
      let formattedAmount = AmountHelpers.getFormattedAmountByLanguageByCurrency(amount, currency, language);
      return formattedAmount;
    } else if (format === ValueFormat.Timestamp) {
      let timestamp = DateHelpers.getFormattedDateFromTimestamp(propertyValue);
      return timestamp;
    } else if (format === ValueFormat.Datetime) {
      let datetime = DateHelpers.getFormattedDatetimeFromTimestamp(propertyValue);
      return datetime;
    } else if (format === ValueFormat.MonthYear) {
      let monthYear = DateHelpers.getFormattedMonthYearDate(propertyValue);
      return monthYear;
    } else if (format === ValueFormat.Select) {
      let selectValue = this.getSelectValue(propertyName, propertyValue);
      return selectValue;
    } else {
      return propertyValue;
    }
  }

  private processAmountHandler(propertyValue: any, handler: Array<ValueHandler>): number {
    var amount: number;

    if (handler.includes(ValueHandler.Round)) {
      amount = Math.round(propertyValue);
    }
    if (handler.includes(ValueHandler.Horsepower)) {
      amount = Utils.getHorsepower(propertyValue);
    }
    if (handler.includes(ValueHandler.Count)) {
      amount = propertyValue.length;
    }
    if (handler.includes(ValueHandler.Negate)) {
      amount = propertyValue * -1;
    }

    return amount;
  }

  private getSelectValue(propertyName: string, propertyValue: string): string {
    let currentObj = this.getCurrentObject();
    var formMapping: Form;
    if (currentObj.subType) {
      formMapping = this.configService.getFormMappingByTypeAndSubType(currentObj.type, currentObj.subType);
    } else {
      formMapping = this.configService.getFormMappingByType(currentObj.type);
    }
    var formControl = formMapping?.attributes.filter(a => a.controls.filter(c => c.key === propertyName).pop())
      .pop()
      ?.controls
      .filter(c => c.key === propertyName)
      .pop();
    if (formControl?.selectOptions) {
      var translation: string;
      var item = formControl.selectOptions.items.filter(i => i.value === propertyValue).pop();
      this.translate.get(item.text).subscribe((result: string) => translation = result);
      return translation;
    } else {
      var modalControl = formMapping?.attributes.filter(a => a.controls.filter(c => c.modalControls?.filter(m => m.key === propertyName).pop()).pop())
        .pop()
        ?.controls
        .filter(c => c.modalControls.filter(m => m.key === propertyName))
        .pop()
        ?.modalControls
        .filter(m => m.key === propertyName)
        .pop();

      if (modalControl.selectOptions) {
        var translation: string;
        var item = modalControl.selectOptions.items.filter(i => i.value === propertyValue).pop();
        if (item) {
          this.translate.get(item.text).subscribe((result: string) => translation = result);
          return translation;
        } else {
          return propertyValue;
        }
      } else {
        return propertyValue;
      }
    }
  }

  private getParticipantsTable(issuerTitle: string, recipientTitle: string, issuerContactNumber: string, recipientContactNumber: string): any {
    return {
      table: {
        widths: ['50%', '50%'],
        body: this.getParticipantsBody(issuerTitle, recipientTitle, issuerContactNumber, recipientContactNumber)
      },
      layout: 'noBorders'
    }
  }

  private getParticipantsBody(issuerTitle: string, recipientTitle: string, issuerContactNumber: string, recipientContactNumber: string): any {
    let currentObj = this.getCurrentObject();
    let idCardIdNumberTitle: string;
    let idCardIssueAgencyTitle: string;
    let idCardIssueDateTitle: string;

    this.translate.get('IDCARDIDNUMBER').subscribe((result: string) => idCardIdNumberTitle = result);
    this.translate.get('IDCARDISSUEAGENCY').subscribe((result: string) => idCardIssueAgencyTitle = result);
    this.translate.get('IDCARDISSUEDATE').subscribe((result: string) => idCardIssueDateTitle = result);

    return [
      [
        { text: issuerTitle, style: BodyStyle.StandardTextBold },
        { text: recipientTitle, style: BodyStyle.StandardTextBold }
      ],
      [
        { text: issuerContactNumber ? issuerContactNumber : '', style: BodyStyle.StandardText },
        { text: recipientContactNumber ? recipientContactNumber : '', style: BodyStyle.StandardText },
      ],
      [
        { text: currentObj.issuer_company ? currentObj.issuer_company : '', style: BodyStyle.StandardText },
        { text: currentObj.recipient_company ? currentObj.recipient_company : '', style: BodyStyle.StandardText }
      ],
      [
        { text: Utils.getFullname(currentObj.issuer_salutation, currentObj.issuer_title, currentObj.issuer_name), style: BodyStyle.StandardText },
        { text: Utils.getFullname(currentObj.recipient_salutation, currentObj.recipient_title, currentObj.recipient_name), style: BodyStyle.StandardText }
      ],
      [
        { text: currentObj.issuer_street ? currentObj.issuer_street : '', style: BodyStyle.StandardText },
        { text: currentObj.recipient_street ? currentObj.recipient_street : '', style: BodyStyle.StandardText }
      ],
      [
        {
          text: (currentObj.issuer_zip ? currentObj.issuer_zip : '')
            + ' ' + (currentObj.issuer_city ? currentObj.issuer_city : ''), style: BodyStyle.StandardText
        },
        {
          text: (currentObj.recipient_zip ? currentObj.recipient_zip : '')
            + ' ' + (currentObj.recipient_city ? currentObj.recipient_city : ''), style: BodyStyle.StandardText
        }
      ],
      [
        { text: currentObj.issuer_state ? currentObj.issuer_state : '', style: BodyStyle.StandardText },
        { text: currentObj.recipient_state ? currentObj.recipient_state : '', style: BodyStyle.StandardText }
      ],
      [
        { text: currentObj.issuer_country ? currentObj.issuer_country : '', style: BodyStyle.StandardText },
        { text: currentObj.recipient_country ? currentObj.recipient_country : '', style: BodyStyle.StandardText }
      ],
      [
        {},
        {}
      ],
      [
        { text: currentObj.issuer_email ? currentObj.issuer_email : '', style: BodyStyle.StandardText },
        { text: currentObj.recipient_email ? currentObj.recipient_email : '', style: BodyStyle.StandardText }
      ],
      [
        { text: currentObj.issuer_phoneNr ? currentObj.issuer_phoneNr : '', style: BodyStyle.StandardText },
        { text: currentObj.recipient_phoneNr ? currentObj.recipient_phoneNr : '', style: BodyStyle.StandardText }
      ],
      [
        {},
        {}
      ],
      [
        {
          text: currentObj.issuer_paymentIban ? ('IBAN: ' + currentObj.issuer_paymentIban) : '', style: BodyStyle.StandardText
        },
        {
          text: currentObj.recipient_paymentIban ? ('IBAN: ' + currentObj.recipient_paymentIban) : '', style: BodyStyle.StandardText
        }
      ],
      [
        {
          text: currentObj.issuer_paymentBic ? ('BIC: ' + currentObj.issuer_paymentBic) : '', style: BodyStyle.StandardText
        },
        {
          text: currentObj.recipient_paymentBic ? ('BIC: ' + currentObj.recipient_paymentBic) : '', style: BodyStyle.StandardText
        }
      ],
      [
        {
          text: currentObj.issuer_paymentInstitution ? currentObj.issuer_paymentInstitution : '', style: BodyStyle.StandardText
        },
        {
          text: currentObj.recipient_paymentInstitution ? currentObj.recipient_paymentInstitution : '', style: BodyStyle.StandardText
        }
      ],
      [
        {},
        {}
      ],
      [
        {
          text: currentObj.issuer_idCardIdNumber ? (idCardIdNumberTitle + ': ' + currentObj.issuer_idCardIdNumber) : '', style: BodyStyle.StandardText
        },
        {
          text: currentObj.recipient_idCardIdNumber ? (idCardIdNumberTitle + ': ' + currentObj.recipient_idCardIdNumber) : '', style: BodyStyle.StandardText
        }
      ],
      [
        {
          text: currentObj.issuer_idCardIssueAgency ? (idCardIssueAgencyTitle + ': ' + currentObj.issuer_idCardIssueAgency) : '', style: BodyStyle.StandardText
        },
        {
          text: currentObj.recipient_idCardIssueAgency ? (idCardIssueAgencyTitle + ': ' + currentObj.recipient_idCardIssueAgency) : '', style: BodyStyle.StandardText
        }
      ],
      [
        {
          text: currentObj.issuer_idCardIssueDate ? (idCardIssueDateTitle + ': ' + DateHelpers.getFormattedDateFromTimestamp(currentObj.issuer_idCardIssueDate)) : ''
          , style: BodyStyle.StandardText
        },
        {
          text: currentObj.recipient_idCardIssueAgency ? (idCardIssueDateTitle + ': ' + DateHelpers.getFormattedDateFromTimestamp(currentObj.recipient_idCardIssueDate)) : ''
          , style: BodyStyle.StandardText
        }
      ]
    ]
  };

  private async getCoverImage(colSpan: number, rowSpan: number): Promise<{}> {
    var currentObj = this.getCurrentObject();
    if (currentObj.images !== undefined && currentObj.images !== null && currentObj.images.length > 0) {
      var imageData = await this.imageService.getImageFromUrl(currentObj.images[0]);
      return { image: imageData, fit: [200, 200], border: [false, false, false, false], alignment: 'left', colSpan, rowSpan };
    } else {
      return { text: '', colSpan, rowSpan };
    }
  }

  private async getControlImage(): Promise<{}> {
    var currentObj = this.getCurrentObject();
    if (currentObj.images !== undefined && currentObj.images !== null && currentObj.images.length > 0) {
      var imageData = await this.imageService.getImageFromUrl(currentObj.images[0]);
      return { image: imageData, fit: [200, 200], border: [false, false, false, false], alignment: 'left' };
    } else {
      return { text: '' };
    }
  }

  private async getGalaryImages(containsCoverImage: boolean): Promise<Array<any>> {
    const promises = Array<Promise<any>>();
    const tempImages = Array<any>();
    const images = Array<any>();
    let picture: string;

    this.translate.get('IMAGES').subscribe((result: string) => picture = result);

    var currentObj = this.getCurrentObject();
    if (currentObj.images && ((containsCoverImage && currentObj.images.length > 1) || (!containsCoverImage && currentObj.images.length > 0))) {
      // title
      images.push([
        { text: picture, style: BodyStyle.StandardTextBold, margin: [0, 0, 0, 10] },
        { text: '' }
      ]);

      var objImages: Array<string> = new Array<string>();
      if (containsCoverImage) {
        objImages = currentObj.images.filter(i => i !== currentObj.images[0]);
      } else {
        objImages = currentObj.images;
      }

      await Utils.asyncForEach(objImages, async (image: string) => {
        const prom = this.imageService.getImageFromUrl(image);
        promises.push(prom);
      });

      var imageDatasets = await Promise.all(promises);
      await Utils.asyncForEach(imageDatasets, (imageData: any) => {
        if (imageData !== null) {
          tempImages.push({ image: imageData, width: 200, alignment: 'center' });
        }
      });

      let counter = 0;
      let temp = Array<any>();
      await Utils.asyncForEach(tempImages, (tempImage: any) => {
        counter++;
        if (counter % 2 === 0) {
          temp.push(tempImage);
          images.push(temp);
          temp = Array<any>();
        } else {
          temp.push(tempImage);
        }
      });
      if (temp.length > 0) {
        temp.push({ text: '' });
        images.push(temp);
      }
      return images;
    } else {
      return images;
    }
  }

  private getAddress(): Array<any> {
    let currentUser = this.authService.currentUser;
    var address: Array<any> = new Array<any>();
    address.push(
      { text: currentUser.company ? (currentUser.company.toUpperCase() + '\n') : '', alignment: 'right', style: 'standardTextBold' }
      , { text: (Utils.getFullname('', currentUser.title, (currentUser.firstname + ' ' + currentUser.lastname)) + '\n'), alignment: 'right', style: 'standardTextBold' }
      , { text: currentUser.street ? currentUser.street + '\n' : '', alignment: 'right', style: 'standardText' }
      , {
        text: currentUser.postalcode || currentUser.city
          ? (currentUser.postalcode + ' ' + currentUser.city + '\n') : '', alignment: 'right', style: 'standardText'
      }
      , { text: currentUser.phoneNr ? (currentUser.phoneNr + '\n') : '', alignment: 'right', style: 'standardText' }
      , { text: currentUser.email ? (currentUser.email + '\n') : '', alignment: 'right', style: 'standardText' }
    );

    return address;
  }

  private isBodyEmpty(body: Array<Array<any>>): boolean {
    var existsBoldText = body.some((row: Array<any>) => row.find((column: any) => column.style === BodyStyle.StandardTextBold));
    if (existsBoldText) {
      var standardTexts = body.filter((row: Array<any>) => row.find((column: any) => column.style === BodyStyle.StandardText));
      return standardTexts.every((row: Array<any>) => row.find((column: any) => column.text === ''));
    } else {
      return false;
    }
  }

  private async openFile(saveDir: Directory, filename: string, fileMimeType: string): Promise<void> {
    var fileUri = await this.checkFileExists(saveDir, filename);
    if (fileUri) {
      await this.fileOpener.open(fileUri, fileMimeType);
    } else {
      // Wait until file is written
      setTimeout(async () => {
        await this.openFile(saveDir, filename, fileMimeType);
      }, 500);
    }
  }

  private async createPdfAndSaveOnStorage(formId: string, formMapping: Form, fileData: any, directory: Directory, filename: string, currentObj: any, preliminary: boolean): Promise<any> {
    var pdfDocGenerator = await this.createPdf(fileData);
    pdfDocGenerator.getBase64(async (base64Data: any) => {
      await this.writePdfAndUpload(formId, formMapping, base64Data, directory, filename, currentObj, preliminary);
    });
  }

  private async writePdfAndUpload(formId: string, formMapping: Form, fileData: string, directory: Directory, filename: string, currentObj: any, preliminary: boolean): Promise<any> {
    await this.writePdf(fileData, directory, filename);
    await this.uploadPdf(formId, formMapping, fileData, currentObj, preliminary);
  }

  private async writePdf(fileData: any, directory: Directory, filename: string): Promise<any> {
    var result = await Filesystem.writeFile({
      path: filename,
      data: fileData,
      directory
    });
  }

  private convertBaseb64ToBlob(b64Data: string, contentType: string): Promise<Blob> {
    return new Promise(promise => {
      contentType = contentType || '';
      const sliceSize = 512;
      b64Data = b64Data.replace(/^[^,]+,/, '');
      b64Data = b64Data.replace(/\s/g, '');
      const byteCharacters = window.atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      promise(new Blob(byteArrays, { type: contentType }));
    });
  }

  private async uploadPdf(formId: string, formMapping: Form, fileData: string, currentObj: any, preliminary: boolean): Promise<void> {
    if (preliminary) { return; }

    var storageLanguage = await this.storageService.get('language');
    var language: string;
    if (storageLanguage === undefined || storageLanguage === null || storageLanguage === '') {
      language = 'de';
    } else {
      language = storageLanguage.substr(0, 2);
    }

    var formTitle = formMapping.translations.title;
    this.imageService.putImageToFirebaseStorage(formId + '_' + language, formId, currentObj.number, formTitle, formMapping.node, 'pdfs', formMapping.node, fileData, currentObj
      , 'pdf', false, this, this.authService.currentUser);
  }

  private addElementToUserDocumentList(elementNode: string, elementId: string): void {
    const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);

    updateDoc(userRef, {
      [elementNode]: arrayUnion(elementId)
    });

    if (this.authService.currentUser[elementNode] === undefined) {
      this.authService.currentUser[elementNode] = new Array<string>(elementId);
    } else {
      this.authService.currentUser[elementNode].push(elementId);
    }
  }

  private createPdf(fileData: any): Promise<any> {
    return new Promise(promise => {
      promise(pdfMake.createPdf(fileData));
    });
  }

  private async loadDocsFromStorage(): Promise<Map<string, Array<any>>> {
    var storageObjects = new Map<string, Array<any>>();
    await Utils.asyncForEach(this.forms, async (form: Form) => {
      var objects = (await this.storageService.get(form.node)) as Array<any>;
      if (objects) {
        var convertedObjects = objects.map(o => {
          o = DateHelpers.convertObjectsToFirestoreTimestamp(o);
          return o;
        });
        storageObjects.set(form.node, convertedObjects);
      }
    });
    return storageObjects;
  }

  private checkSignDisabled(obj: any, formMapping: Form): boolean {
    var result = this.checkRequiredProperties(obj, formMapping);
    return !result;
  }

  private async checkProperties(obj: any, formMapping: Form, status: Status): Promise<boolean> {
    var isValid = false;

    var categorization = formMapping.categorizations.filter(c => c.status == status).pop();

    if (!categorization) {
      return isValid;
    }

    if (!obj.visible || obj.trash) {
      return isValid;
    }

    isValid = this.checkRequiredProperties(obj, formMapping);

    if (isValid && categorization.conditions?.length > 0) {
      isValid = await Utils.checkConditions(obj, categorization.conditions, this.authService.currentUser.$key);
      return isValid;
    } else {
      if (isValid) {
        return isValid;
      } else {
        if (status === Status.Do) {
          if (categorization.conditions?.length > 0) {
            return await Utils.checkConditions(obj, categorization.conditions, this.authService.currentUser.$key);
          } else {
            return true;
          }
        } else {
          return isValid;
        }
      }
    }
  }

  private checkRequiredProperties(obj: any, formMapping: Form): boolean {
    // Check required controls
    return formMapping.attributes.every((a: FormAttribute) => {
      return a.controls.filter((c: FormAttributeControl) => c.required)
        .every((c: FormAttributeControl) => this.checkRequiredControls(obj, c));
    });
  }

  private checkRequiredControls(obj: any, control: FormAttributeControl): boolean {
    if (obj[control.key] !== undefined && obj[control.key] !== null) {
      return true;
    } else {
      return false;
    }
  }

  private async handleObjAdding(newObjects: Array<any>, formNode: string, formId: string, formNumber: string, formTitle: string, arrayType: string, fieldName: string): Promise<void> {
    if (!newObjects || newObjects.length === 0) {
      return;
    } else {
      await Utils.asyncForEach(newObjects, (obj: any) => {
        const newDatetime = new Date().getTime();
        this.imageService.handleSingleStorageUpload(obj.image, formId, formNumber, formTitle, arrayType
          + '_' + newDatetime.toString(), formNode, fieldName, null, this, obj);
      });
    }
  }
}
