import { Component } from '@angular/core';
import { Platform, ModalController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../../services/storage.service';
import { AuthService } from '../../auth/auth.service';
import { AccountService } from '../../account/account.service';
import { Utils } from '../../../utils/utils';
import { ConfigService } from '../../../services/config.service';
import { DateHelpers } from '../../../../app/utils/date-helpers';
import { AmountHelpers } from '../../../../app/utils/amount-helpers';
import { Form, FormOverviewAmount, FormOverviewLabel } from '../../../interfaces/config/form';
import { FormService } from '../../documents/form/form.service';
import { FormAssign } from '../../../interfaces/config/form_assign';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';



@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent {
  private pageTrace: PerformanceTrace;

  public darkMode: boolean;
  public searchInput = '';
  public items: Array<any> = new Array<any>();
  public filteredItems: Array<any> = new Array<any>();
  public sortCriteria: Array<string>;
  public orderDesc: boolean;
  public language: any;
  public formType: string;
  public formSubType: string;
  public formAssignMapping: FormAssign;
  public forms: Array<Form>;

  constructor(
    public translate: TranslateService,
    private platform: Platform,
    public authService: AuthService,
    public accountService: AccountService,
    private modalCtrl: ModalController,
    public formBuilder: UntypedFormBuilder,
    private navParams: NavParams,
    private configService: ConfigService,
    public formService: FormService,
    private storageService: StorageService,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.pageTrace = trace(this.performance, FormModalComponent.name);
  }

  public async ionViewWillEnter(): Promise<void> {
    await this.storageService.set('component', FormModalComponent.name);
    logEvent(this.analytics, 'screen_view');
    try { this.pageTrace.start(); } catch (error) { }

    var darkMode = await this.storageService.get('darkMode');
    this.darkMode = darkMode;

    var language = await this.storageService.get('language');
    this.language = language.replace('_', '-');

    this.formType = this.navParams.data.formType;
    this.forms = this.configService.getFormsMapping();
    var formsAssignMapping = this.configService.getFormsAssignMapping();
    this.formAssignMapping = formsAssignMapping.filter(f => f.target === this.formType)[0];

    var currentDocs = this.formService.getCurrentDocs();
    currentDocs.forEach((value: Array<any>, key: string) => {
      value.forEach(async (v: any) => {
        this.formAssignMapping.sources.forEach(s => {
          if (v.type === s.name) {
            if (!v.subType || (v.subType && s.subName && v.subType === s.subName)) {
              let formMapping = v.subType ? this.configService.getFormMappingByTypeAndSubType(v.type, v.subType) : this.configService.getFormMappingByType(v.type);
              if (!Utils.checkReadPermissions(this.authService.currentUser.client, this.authService.currentUser.permissions, formMapping.permissions)) { return; }
              this.items.push(v);
            }
          }
        });
      });
    });

    this.sortCriteria = new Array<string>('creationDate');
    this.orderDesc = false;

    this.filter();
  }

  public async ionViewWillLeave(): Promise<void> {
    try { this.pageTrace.stop(); } catch (error) { }
  }

  public toUpperCase(text: string): string {
    return text.toUpperCase();
  }

  public async cancel(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  public async use(): Promise<void> {
    var itemsToUse = this.items.filter(i => i.isChecked);
    await this.modalCtrl.dismiss({
      itemsToUse
    });
  }

  public async filter(): Promise<void> {
    if (this.searchInput !== undefined && this.searchInput !== '') {
      this.filteredItems = await Utils.orderBy(this.items.filter((f: Form) => {
        return this.checkSearchInput(f);
      }), this.sortCriteria, this.orderDesc);
    } else {
      this.filteredItems = await Utils.orderBy(this.items, this.sortCriteria, this.orderDesc);
    }
  }

  public getFormMapping(formType: string, formSubType: string): Form {
    if (!formSubType) {
      return this.forms?.filter(f => f.type === formType).pop();
    } else {
      return this.forms?.filter(f => f.type === formType).filter(f => f.subType.name === formSubType).pop();
    }
  }

  public getReplacedLabel(obj: any, label: FormOverviewLabel): string {
    if (!label) { return; }

    if (label.property.toLowerCase().indexOf('<recipient_name>') > -1) {
      return Utils.getFullname(obj.recipient_salutation, obj.recipient_title, obj.recipient_name);
    } else if (label.property.toLowerCase().indexOf('<issuer_name>') > -1) {
      return Utils.getFullname(obj.issuer_salutation, obj.issuer_title, obj.issuer_name);
    } else if (obj[label.property]) {
      if (label.property.toLowerCase().indexOf('date') > -1) {
        return DateHelpers.getFormattedDateFromTimestamp(obj[label.property]);
      } else if (typeof obj[label.property] === 'number') {
        return AmountHelpers.getFormattedAmountByLanguageByCurrency(obj[label.property], !obj['currency'] ? 'EUR' : obj['currency'], this.language);
      } else {
        var formMapping = this.getFormMapping(obj.type, obj.subType?.name);
        var formControl = formMapping?.attributes.filter(a => a.controls.filter(c => c.key === label.property).pop())
          .pop()
          ?.controls
          .filter(c => c.key === label.property)
          .pop();
        if (formControl?.selectOptions) {
          var translation: string;
          var item = formControl.selectOptions.items.filter(i => i.value === obj[label.property]).pop();
          this.translate.get(item.text).subscribe((result: string) => translation = result);
          return translation;
        } else if (label.property === 'subType') {
          let translation: string;
          this.translate.get(obj[label.property].toUpperCase()).subscribe((result: string) => translation = result);
          return translation;
        } else {
          return obj[label.property];
        }
      }
    } else {
      return;
    }
  }

  public getAmounts(document: any, formType: string, formSubType: string): Array<string> {
    if (!formSubType) {
      var form = this.forms?.filter(f => f.type === formType)[0];
    } else {
      var form = this.forms?.filter(f => f.type === formType).filter(f => f.subType.name === formSubType)[0];
    }
    if (form.overview.amounts?.length > 0) {
      var amounts = new Array<string>();
      form.overview.amounts.forEach((amount: FormOverviewAmount) => {
        var value = 0;
        if (amount.array) {
          let array = document[amount.array];
          if (array) {
            value = Utils.getArrayAmount(array, amount, this.language);
            if (amount.property.includes('<')) {
              document[amount.array + '.' + amount.property] = value;
            }
          }
        } else {
          value = document[amount.property] ? document[amount.property] : 0;
        }
        amounts.push(this.getFormattedAmount(value, document['currency']));
      });
      return amounts;
    } else {
      return;
    }
  }

  public getFormattedAmount(amount: any, currency: any): string {
    return AmountHelpers.getFormattedAmountByLanguageByCurrency(amount, currency, this.language);
  }

  public getFormattedDateFromTimestamp(timestamp: any): string {
    return DateHelpers.getFormattedDateFromTimestamp(timestamp);
  }

  public getDynamicIdentifier(prefix: string, key: string): string {
    return Utils.getDynamicIdentifier(prefix, key);
  }

  private checkSearchInput(form: any): boolean {
    return Object.keys(form).some(m => {
      if (form[m] !== undefined) {
        if (typeof (form[m]) === 'number') {
          return form[m].toString().toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1;
        } else if (typeof (form[m]) === 'string') {
          return form[m].toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }
}