import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Platform, NavController, AlertController } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from './utils/utils';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Auth, User, onAuthStateChanged } from '@angular/fire/auth';
import { Market } from '@ionic-native/market/ngx';
import { NetworkService } from './services/network.service';
import { default as PackageInfo } from '../../package.json';
import { environment } from '../environments/environment';
import { ConfigService } from './services/config.service';
import { Translation } from './interfaces/config/translation';
import { AccountService } from './pages/account/account.service';
import { AuthService } from './pages/auth/auth.service';
import { StorageService } from './services/storage.service';
import { Plugins } from '@capacitor/core';
const { ExitAppIosPlugin } = Plugins;
import { App } from '@capacitor/app';
import { AlertId } from './enums/alert-id.enum';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public isOnline: boolean = true;
  public routerHidden = true;
  public darkMode: boolean;
  @ViewChild('splash', { static: false }) splash: ElementRef;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private navCtrl: NavController,
    private zone: NgZone,
    private appVersion: AppVersion,
    private alertCtrl: AlertController,
    private market: Market,
    public networkService: NetworkService,
    private titleService: Title,
    private configService: ConfigService,
    private accountService: AccountService,
    private authService: AuthService,
    private storageService: StorageService,
    private auth: Auth
  ) {
    this.platform.ready().then(async () => {
      await this.initializeNetworkService();

      await this.configService.loadGeneralConfigs();

      await this.setLanguage();

      this.setCurrency();

      await this.initializeApp();

      await this.handleMaintenance();

      this.handleAuthChange();
    });
  }

  public async initializeApp(): Promise<void> {
    if (this.platform.is('capacitor')) {
      await StatusBar.setStyle({ style: Style.Default });
    }

    await this.configureTheme();

    setTimeout(() => {
      this.routerHidden = false;
      this.splash.nativeElement.style.display = 'none';
    }, 2000);
  }

  private handleAuthChange(): void {
    onAuthStateChanged(this.auth, async (user: User) => {
      var validAppVersion: boolean;
      validAppVersion = await this.validAppVersion();

      if (user) {
        await this.accountService.getMessagingToken(user.uid);
      }

      if (!environment.production) {
        if (environment.integration) {
          this.titleService.setTitle(this.titleService.getTitle() + " - INTEGRATION MODE - v." + (await this.getCurrentVersion()));
        } else {
          this.titleService.setTitle(this.titleService.getTitle() + " - DEV MODE - v." + (await this.getCurrentVersion()));
        }
      }

      if (validAppVersion) {
        if (!user || (user && !user.emailVerified)) {
          await this.storageService.set('category', 'do');
          this.zone.run(async () => {
            this.navCtrl.navigateRoot('login');
          });
        } else {
          this.zone.run(async () => {
            this.navCtrl.navigateRoot('tabs');
          });
        }
      } else {
        await this.handleInvalidVersion();
      }
    });
  }

  private async initializeNetworkService(): Promise<void> {
    this.networkService.listenToNetworkStatus();
    this.isOnline = this.networkService.isOnline();
  }

  private async handleMaintenance(): Promise<void> {
    let header: string, message: string;
    this.translate.get('MAINTENANCE_TITLE').subscribe((result: string) => header = result);
    this.translate.get('MAINTENANCE_MESSAGE').subscribe((result: string) => message = result);

    if (header !== undefined) {
      var isMaintenance = await this.checkMaintenanceWindow();
      if (isMaintenance) {
        var alert = await this.alertCtrl.create({
          id: AlertId.CheckMaintenance,
          header: header,
          cssClass: 'maintenance',
          message: message + (this.darkMode ? '<img src="./assets/custom-ion-icons/thumbs-up-outline-white.svg">' : '<img src="./assets/custom-ion-icons/thumbs-up-outline-dark.svg">'),
          backdropDismiss: false
        });
        await alert.present();

        return;
      }
    } else {
      // Wait until translations are loaded
      setTimeout(async () => {
        await this.handleMaintenance();
      }, 100);
    }
  }

  private async handleInvalidVersion(): Promise<void> {
    let header: string, message: string, download: string;
    this.translate.get('INVALID_VERSION_TITLE').subscribe((result: string) => header = result);
    this.translate.get('INVALID_VERSION_MESSAGE').subscribe((result: string) => message = result);
    this.translate.get('DOWNLOAD').subscribe((result: string) => download = result);

    if (header !== undefined) {
      var alert = await this.alertCtrl.create({
        id: AlertId.InvalidVersion,
        header,
        backdropDismiss: false,
        message: message.replace('$store', this.platform.is('ios') ? 'App Store' : 'Play Store'),
        buttons: [
          {
            text: download,
            handler: async () => {
              if (this.platform.is('capacitor')) {
                var packageName = await this.appVersion.getPackageName();
                await this.market.open(this.platform.is('ios') ? 'id1272879737' : packageName);
                if (this.platform.is('android')) {
                  App.exitApp();
                } else {
                  ExitAppIosPlugin.killApp().then();
                }
              } else {
                window.open(this.platform.is('ios')
                  ? 'https://apps.apple.com/de/app/thumbify-formular-signatur/id1272879737'
                  : 'https://play.google.com/store/apps/details?id=com.thumbify.app');
              }
            }
          }
        ]
      });
      await alert.present();
    } else {
      // Wait until translations are loaded
      setTimeout(async () => {
        await this.handleInvalidVersion();
      }, 100);
    }
  }

  private async checkMaintenanceWindow(): Promise<boolean> {
    return !(await this.configService.getVersion()).enabled;
  }

  private async setLanguage(): Promise<void> {
    this.translate.setDefaultLang('de_DE');

    if (this.authService.currentUser && this.authService.currentUser.language !== undefined) {
      this.storageService.set('language', this.authService.currentUser.language);
      this.translate.use(this.authService.currentUser.language.replace('-', '_'));
    } else {
      this.storageService.get('language').then(async lang => {
        var usedLanguage: string;
        if (lang !== null) {
          usedLanguage = lang.replace('-', '_');
          this.translate.use(usedLanguage);
        } else {
          var keys = this.configService.getTranslations().map((t: Translation) => t.key);
          const userLang = keys?.includes(navigator.language.replace('-', '_')) ? navigator.language.replace('-', '_') : 'de_DE';
          usedLanguage = userLang;
          this.translate.use(userLang);
          this.storageService.set('language', userLang);
        }
        await this.authService.setLanguage(usedLanguage);
      });
    }
  }

  private setCurrency(): void {
    if (this.authService.currentUser && this.authService.currentUser.currency !== undefined) {
      this.storageService.set('currency', this.authService.currentUser.currency);
    } else {
      this.storageService.get('currency').then(async currency => {
        var usedCurrency: string;
        if (currency !== null) {
          usedCurrency = currency;
        } else {
          usedCurrency = 'EUR';
          this.storageService.set('currency', usedCurrency);
        }
        await this.authService.setCurrency(usedCurrency);
      });
    }
  }

  private async configureTheme(): Promise<void> {
    const systemDarkMode = window.matchMedia('(prefers-color-scheme: dark)');

    if (this.authService.currentUser && this.authService.currentUser.darkMode !== undefined) {
      this.darkMode = this.authService.currentUser.darkMode;
      this.storageService.set('darkMode', this.darkMode);
      Utils.enableDarkTheme(this.darkMode);
      await this.configureStatusBarTheme(this.darkMode);
    } else {
      this.storageService.get('darkMode').then(async appDarkMode => {
        if (appDarkMode !== null) {
          this.darkMode = appDarkMode;
          Utils.enableDarkTheme(this.darkMode);
          await this.configureStatusBarTheme(this.darkMode);
        } else {
          this.darkMode = systemDarkMode.matches;
          this.storageService.set('darkMode', systemDarkMode.matches);
          Utils.enableDarkTheme(systemDarkMode.matches);
          await this.configureStatusBarTheme(systemDarkMode.matches);
        }
        await this.authService.setDarkMode(this.darkMode);
      });
    }
  }

  private async configureStatusBarTheme(appDarkMode: boolean): Promise<void> {
    if (this.platform.is('capacitor')) {
      if (appDarkMode) {
        await StatusBar.setStyle({ style: Style.Dark });
      } else {
        await StatusBar.setStyle({ style: Style.Default });
      }
    }
  }

  private async validAppVersion(): Promise<boolean> {
    var appVersion = this.platform.is('capacitor') ? (await this.appVersion.getVersionNumber()) : PackageInfo.version;
    var splittedAppVersion = appVersion.split('.').map(s => parseInt(s));

    var minAppVersion = (await this.configService.getVersion()).min;
    var splittedMinAppVersion = minAppVersion.split('.').map(s => parseInt(s));

    if (splittedAppVersion[0] > splittedMinAppVersion[0]) {
      return true;
    } else if (splittedAppVersion[0] === splittedMinAppVersion[0] && splittedAppVersion[1] > splittedMinAppVersion[1]) {
      return true;
    } else if (splittedAppVersion[0] === splittedMinAppVersion[0] && splittedAppVersion[1] === splittedMinAppVersion[1] && splittedAppVersion[2] >= splittedMinAppVersion[2]) {
      return true;
    } else {
      return false;
    }
  }

  private async getCurrentVersion(): Promise<string> {
    if (this.platform.is('capacitor')) {
      return await this.appVersion.getVersionNumber();;
    } else {
      return PackageInfo.version;
    }
  }
}