import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NetworkService } from '../../../services/network.service';
import { Contact } from '../../../interfaces/database/masterdata';
import { StorageService } from '../../../services/storage.service';
import { Platform } from '@ionic/angular';
import { AuthService } from '../../auth/auth.service';
import { DateHelpers } from '../../../utils/date-helpers';
import { MasterDataService } from '../master-data.service';
import {
  Firestore, Timestamp, arrayRemove, arrayUnion, QuerySnapshot, collection, query, where, getDocsFromServer, getDocsFromCache
  , getDocFromServer, getDocFromCache, doc, setDoc, updateDoc, deleteField
} from '@angular/fire/firestore';
import { Performance, trace } from '@angular/fire/performance';
import { Utils } from '../../../utils/utils';
import { IMasterDataService } from '../../../interfaces/general/imasterdataservice';
import { ImageService } from '../../../services/image.service';
import { Client } from '../../../enums/client.enum';
import { AbstractControl } from '@angular/forms';
import { ConfigService } from '../../../services/config.service';
import { CryptoService } from '../../../services/crypto.service';

const propertiesToSkip = new Array<string>('$key', 'type');

@Injectable({
  providedIn: 'root'
})
export class ContactService implements IMasterDataService, OnDestroy {
  private isOnline: boolean;
  private networkSubscription: Subscription;
  private currentMasterDataObj: Contact;
  private currentMasterDataObjs: Array<Contact>;
  private contactsNode: string = 'contacts';
  private type: string = 'contact';

  constructor(
    private platform: Platform,
    private networkService: NetworkService,
    public authService: AuthService,
    public masterDataService: MasterDataService,
    private imageService: ImageService,
    private storageService: StorageService,
    private configService: ConfigService,
    private cryptoService: CryptoService,
    private firestore: Firestore,
    private performance: Performance
  ) {
    this.networkSubscription = this.networkService.isOnlineObservable().subscribe(async (isOnline: boolean) => {
      if (this.isOnline !== undefined && this.isOnline && !isOnline) {
        this.cacheCurrentObjects();
      }

      this.isOnline = isOnline;
    });
  }

  public ngOnDestroy(): void {
    this.networkSubscription.unsubscribe();
  }

  public async loadMasterData(initial: boolean): Promise<void> {
    const t = trace(this.performance, 'loadContacts');
    try { t.start(); } catch (ex) { };
    this.currentMasterDataObjs = new Array<Contact>();
    var contactsMapping = this.configService.getMasterDataMapping().contacts;

    if (!initial || (initial && this.networkService.isOnline() && this.networkService.isMinBandwidth())) {
      if (this.authService.currentUser !== undefined) {
        var objects = new Array<Contact>();
        var client = this.authService.currentUser.client;
        const contactsRef = collection(this.firestore, this.getMasterDataNode());
        var contacts: Promise<QuerySnapshot<unknown>>;
        if (!client || client === Client.Free || client === Client.Basic || client === Client.Advance) {
          const q = query(contactsRef, where('creator', '==', this.authService.currentUser.$key), where('visible', '==', true));
          contacts = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocsFromServer(q) : getDocsFromCache(q);
        } else {
          const q = query(contactsRef, where('client', '==', client), where('visible', '==', true));
          contacts = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocsFromServer(q) : getDocsFromCache(q);
        }

        return contacts.then(async contactData => {
          objects = objects.concat(contactData.docs.filter(a => a.exists)
            .map(action => {
              const $key = action.id;
              const data = { $key, ...action.data() as {}, type: this.getMasterDataType() } as Contact;

              if (this.configService.getClientMapping().encryption) {
                this.cryptoService.decryptObj(data).then(() => {
                  return data;
                })
              } else {
                return data;
              }
            }));
          this.currentMasterDataObjs = objects.filter(o => contactsMapping.map(m => m.category).includes(o.category));
          await this.storageService.set(this.getMasterDataNode(), objects);
          try { t.stop(); } catch (ex) { };
        }).catch(async () => {
          // Error while getting documents from firebase server / cache -> Getting documents from local storage
          this.currentMasterDataObjs = await this.loadContactsFromStorage();
          try { t.stop(); } catch (ex) { };
          return;
        });
      } else {
        try { t.stop(); } catch (ex) { };
        return;
      }
    } else {
      // Device is offline or min bandwidth isn't available
      this.currentMasterDataObjs = await this.loadContactsFromStorage();
      try { t.stop(); } catch (ex) { };
      return;
    }
  }

  public async loadCurrent(id: string, fromDb?: boolean): Promise<Contact> {
    if (!fromDb && (await this.getCurrentMasterDataObjs()).some(c => c.$key === id)) {
      var contactObj = (await this.getCurrentMasterDataObjs()).filter(c => c.$key === id)[0];
      this.currentMasterDataObj = contactObj;
      this.cacheImages(contactObj);
      return contactObj;
    } else {
      const currentObjectRef = doc(this.firestore, this.getMasterDataNode(), id);
      var obj = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? await getDocFromServer(currentObjectRef) : await getDocFromCache(currentObjectRef);
      if (!obj.exists) {
        return;
      }
      const $key = obj.id;
      const contactObj = { $key, ...obj.data() as {} } as Contact;

      if (this.configService.getClientMapping().encryption) {
        await this.cryptoService.decryptObj(contactObj);
      }

      this.currentMasterDataObj = contactObj;
      var cacheObj = this.currentMasterDataObjs?.filter(c => c.$key === id)[0];
      if (cacheObj !== undefined) {
        this.currentMasterDataObjs[this.currentMasterDataObjs.indexOf(cacheObj)] = contactObj;
      }
      this.cacheImages(contactObj);
      return contactObj;
    }
  }

  public async getCurrentMasterDataObjs(): Promise<Array<Contact>> {
    if (!this.currentMasterDataObjs) {
      await this.loadMasterData(true);
    }
    return this.currentMasterDataObjs;
  }

  public getCurrentMasterDataObj(): Contact {
    return this.currentMasterDataObj;
  }

  public getMasterDataNode(): string {
    return this.contactsNode;
  }

  public async loadContactsFromStorage(): Promise<Array<Contact>> {
    var storageObjects = (await this.storageService.get(this.contactsNode)) as Array<Contact>;
    if (storageObjects) {
      return storageObjects.map(o => {
        o = DateHelpers.convertObjectsToFirestoreTimestamp(o);
        return o;
      });
    }
  }

  public getMasterDataType(): string {
    return this.type;
  }

  public async create(controls: Map<string, AbstractControl>, currency: string, images: Array<string>, pdfs: Array<string>): Promise<string> {
    const t = trace(this.performance, 'createContact');
    try { t.start(); } catch (ex) { };

    var encryption = this.configService.getClientMapping().encryption;

    const newContactRef = doc(collection(this.firestore, this.getMasterDataNode()));

    // Add fix properties
    let newObj: object = {
      client: this.authService.currentUser.client,
      creationDate: Timestamp.fromDate(new Date()),
      creator: this.authService.currentUser.$key,
      images: !images || images.length === 0 ? undefined : images,
      updateUser: this.authService.currentUser.$key,
      visible: true
    };
    if (currency) {
      newObj['currency'] = currency;
    }

    // Add dynamic properties
    controls.forEach((control: AbstractControl, key: string) => {
      if (!propertiesToSkip?.includes(key)
        && !this.platform.is('capacitor')
        && !this.platform.is('cordova')
        && Utils.isArrayControlWithNewImage(control, this.imageService)) {
        newObj[key] = Utils.getArrayControlWithoutNewImage(control, this.imageService);
      } else if (!propertiesToSkip?.includes(key)) {
        newObj[key] = control.value;
      }
    });

    // Remove undefined fields saving
    await Utils.deleteUndefinedProperties(newObj);

    if (encryption) {
      let masterDataContactMapping = this.configService.getMasterDataContactMappingByCategory(newObj['category']);
      let encryptedObj = await this.cryptoService.encryptObj(newObj, masterDataContactMapping);
      setDoc(newContactRef, encryptedObj);
    } else {
      setDoc(newContactRef, newObj);
    }

    await this.loadCurrent(newContactRef.id);

    await this.imageService.handleMultipleStorageUpload(images, newContactRef.id, newContactRef.id, 'MASTERDATA_CONTACT', this.getMasterDataNode(), 'images', this.getCurrentMasterDataObj(), this);
    await this.handleObjAdding(pdfs, this.getMasterDataNode(), this.getCurrentMasterDataObj().$key, this.getCurrentMasterDataObj().number, 'MASTERDATA_CONTACT'
      , 'pdfs', 'pdfs');

    try { t.stop(); } catch (ex) { };

    return newContactRef.id;
  }

  public async update(deletedImages?: Array<string>, newImages?: Array<string>, deletedPdfs?: Array<string>, newPdfs?: Array<string>, deletedModals?: Map<string, any>, newModals?: Map<string, any>): Promise<void> {
    const t = trace(this.performance, 'updateContact');
    try { t.start(); } catch (ex) { };

    var encryption = this.configService.getClientMapping().encryption;

    var currentMasterDataObj = this.getCurrentMasterDataObj();

    const contactRef = doc(this.firestore, this.getMasterDataNode(), currentMasterDataObj.$key);

    // Add all properties of the current object
    var updatedObj = {}
    Object.keys(currentMasterDataObj).forEach((key: string) => {
      if (!propertiesToSkip?.includes(key)
        && !this.platform.is('capacitor')
        && !this.platform.is('cordova')
        && Utils.isArrayPropertyWithNewImage(currentMasterDataObj[key], this.imageService)) {
        updatedObj[key] = Utils.getArrayPropertyWithoutNewImage(currentMasterDataObj[key], this.imageService);
      } else if (!propertiesToSkip?.includes(key)) {
        updatedObj[key] = currentMasterDataObj[key];
      }
    });

    // Remove undefined fields saving
    await Utils.deleteUpdatedUndefinedProperties(updatedObj);

    // Add update user
    updatedObj['updateUser'] = this.authService.currentUser.$key;

    if (encryption) {
      let masterDataContactMapping = this.configService.getMasterDataContactMappingByCategory(updatedObj['category']);
      let encryptedObj = await this.cryptoService.encryptObj(updatedObj, masterDataContactMapping);
      updateDoc(contactRef, encryptedObj);
    } else {
      updateDoc(contactRef, updatedObj);
    }

    // Handle general images
    // Deleted images
    if (deletedImages !== undefined && deletedImages.length > 0) {
      updateDoc(contactRef, {
        images: arrayRemove(...deletedImages)
      });
    }
    await this.imageService.deleteMultipleRemovedObjsFromStorage(deletedImages);
    // New images
    if (newImages !== undefined && newImages.length > 0) {
      newImages = await this.imageService.checkFirebaseMaxLength(newImages);
      updateDoc(contactRef, {
        images: arrayUnion(...newImages)
      });
    }
    await this.imageService.handleMultipleStorageUpload(newImages, currentMasterDataObj.$key, currentMasterDataObj.number, 'MASTERDATA_CONTACT'
      , this.getMasterDataNode(), 'images', currentMasterDataObj, this);

    // Handle PDFs
    // Deleted PDFs
    if (deletedPdfs && deletedPdfs.length > 0) {
      updateDoc(contactRef, {
        pdfs: arrayRemove(...deletedPdfs)
      });
    }
    await this.imageService.deleteMultipleRemovedObjsFromStorage(deletedPdfs);
    // New PDFs
    if (newPdfs && newPdfs.length > 0) {
      if (!this.platform.is('capacitor') && !this.platform.is('cordova')) {
        let pdfsWithoutBase64 = Utils.getArrayPropertyWithoutNewImage(newPdfs, this.imageService);
        if (pdfsWithoutBase64 && pdfsWithoutBase64.length > 0) {
          updateDoc(contactRef, {
            pdfs: arrayUnion(...pdfsWithoutBase64)
          });
        }
      } else {
        updateDoc(contactRef, {
          pdfs: arrayUnion(...newPdfs)
        });
      }
    }
    await this.handleObjAdding(newPdfs, this.getMasterDataNode(), this.getCurrentMasterDataObj().$key, this.getCurrentMasterDataObj().number, 'MASTERDATA_CONTACT'
      , 'pdfs', 'pdfs');

    // Handle all modals
    // Deleted modals
    if (deletedModals && deletedModals.size > 0) {
      deletedModals.forEach(async (value: any, key: string) => {
        updateDoc(contactRef, {
          [key]: arrayRemove(...value)
        });
        await this.imageService.deleteMultipleRemovedObjsFromStorage(value);
      });
    }
    // New modals
    if (newModals && newModals.size > 0) {
      newModals.forEach(async (value: any, key: string) => {
        updateDoc(contactRef, {
          [key]: arrayUnion(...value)
        });
        await this.handleObjAdding(value, this.getMasterDataNode(), currentMasterDataObj.$key, currentMasterDataObj.number, 'MASTERDATA_CONTACT', key, key);
      });
    }


    await this.loadCurrent(this.getCurrentMasterDataObj().$key);

    try { t.stop(); } catch (ex) { };
  }

  public async remove(): Promise<void> {
    this.removeWithId(this.getCurrentMasterDataObj());
  }

  public removeWithId(contact: Contact): void {
    contact.visible = false;
    const contactRef = doc(this.firestore, this.getMasterDataNode(), contact.$key);
    updateDoc(contactRef, {
      visible: contact.visible
    });
  }

  public async cacheCurrentObjects(): Promise<void> {
    await this.storageService.set(this.getMasterDataNode(), this.getCurrentMasterDataObjs());
  }

  public clearCurrentObj(): void {
    this.currentMasterDataObj = undefined;
  }

  public clearCurrentObjects(): void {
    this.clearCurrentObj();
    this.clearStorageCache();
    this.currentMasterDataObjs = undefined;
  }

  public async cacheImages(contact: Contact): Promise<void> {
    if (this.networkService.isOnline() && this.networkService.isMinBandwidth()) {
      if (contact.idCardImages !== undefined && contact.idCardImages !== null && contact.idCardImages.length > 0 && contact.idCardImages.some((image: string) => image !== undefined && image !== null
        && image.includes('https://'))) {
        contact.idCardImages.forEach(async (image: string) => {
          this.imageService.getImageFromUrl(image);
        });
      }
    }
  }

  public getImage(image: string): string {
    if (this.networkService.isOnline()) {
      return image;
    } else {
      if (this.imageService.getImageCache()[image] !== undefined) {
        return this.imageService.getImageCache()[image];
      } else if (image === undefined || (image !== undefined && image !== null && image !== '' && !image.includes('https://'))) {
        return image;
      } else {
        return null;
      }
    }
  }

  public async clearStorageCache(): Promise<void> {
    await this.storageService.remove(this.getMasterDataNode());
  }

  public getContactNumber(): number {
    var contactNumber: number | undefined;
    if (!this.authService.currentUser.client || this.authService.currentUser.client === Client.Free || this.authService.currentUser.client === Client.Basic
      || this.authService.currentUser.client === Client.Advance) {
      contactNumber = this.authService.currentUser.contactNumber;
    } else {
      contactNumber = this.authService.currentClientMapping.contactNumber;
    }
    return contactNumber === undefined ? 0 : contactNumber;
  }

  public getContactNumberPrefix(): string {
    var contactNumberPrefix: string;
    if (!this.authService.currentUser.client || this.authService.currentUser.client === Client.Free || this.authService.currentUser.client === Client.Basic
      || this.authService.currentUser.client === Client.Advance) {
      contactNumberPrefix = this.authService.currentUser.contactNumberPrefix;
    } else {
      contactNumberPrefix = this.authService.currentClientMapping.contactNumberPrefix;
    }
    return contactNumberPrefix === undefined ? new Date().getFullYear().toString() : contactNumberPrefix;
  }

  private async handleUpdate(deletedIdCardImages: Array<string>, newIdCardImages: Array<string>, deletedIdCardPdfs: Array<string>, newIdCardPdfs: Array<string>): Promise<void> {
    var contactRef = doc(this.firestore, this.getMasterDataNode(), this.getCurrentMasterDataObj().$key);
    updateDoc(contactRef, {
      birthdate: this.getCurrentMasterDataObj().birthdate === undefined || this.getCurrentMasterDataObj().birthdate === null
        ? deleteField() : this.getCurrentMasterDataObj().birthdate,
      category: this.getCurrentMasterDataObj().category === undefined ? deleteField() : this.getCurrentMasterDataObj().category,
      city: this.getCurrentMasterDataObj().city === undefined ? deleteField() : this.getCurrentMasterDataObj().city,
      company: this.getCurrentMasterDataObj().company === undefined ? deleteField() : this.getCurrentMasterDataObj().company,
      consentPrivacyPolicy: this.getCurrentMasterDataObj().consentPrivacyPolicy === undefined ? deleteField() : this.getCurrentMasterDataObj().consentPrivacyPolicy,
      country: this.getCurrentMasterDataObj().country === undefined ? deleteField() : this.getCurrentMasterDataObj().country,
      description: this.getCurrentMasterDataObj().description === undefined ? deleteField() : this.getCurrentMasterDataObj().description,
      email: this.getCurrentMasterDataObj().email === undefined ? deleteField() : this.getCurrentMasterDataObj().email,
      firstname: this.getCurrentMasterDataObj().firstname === undefined ? deleteField() : this.getCurrentMasterDataObj().firstname,
      lastname: this.getCurrentMasterDataObj().lastname === undefined ? deleteField() : this.getCurrentMasterDataObj().lastname,
      leader: this.getCurrentMasterDataObj().leader === undefined ? deleteField() : this.getCurrentMasterDataObj().leader,
      idCardIdNumber: this.getCurrentMasterDataObj().idCardIdNumber === undefined ? deleteField() : this.getCurrentMasterDataObj().idCardIdNumber,
      idCardIssueAgency: this.getCurrentMasterDataObj().idCardIssueAgency === undefined ? deleteField()
        : this.getCurrentMasterDataObj().idCardIssueAgency,
      idCardIssueDate: this.getCurrentMasterDataObj().idCardIssueDate === undefined || this.getCurrentMasterDataObj().idCardIssueDate === null
        ? deleteField() : this.getCurrentMasterDataObj().idCardIssueDate,
      paymentIban: this.getCurrentMasterDataObj().paymentIban === undefined ? deleteField() : this.getCurrentMasterDataObj().paymentIban,
      paymentBic: this.getCurrentMasterDataObj().paymentBic === undefined ? deleteField() : this.getCurrentMasterDataObj().paymentBic,
      paymentInstitution: this.getCurrentMasterDataObj().paymentInstitution === undefined ? deleteField() : this.getCurrentMasterDataObj().paymentInstitution,
      phoneNr: this.getCurrentMasterDataObj().phoneNr === undefined ? deleteField() : this.getCurrentMasterDataObj().phoneNr,
      postalcode: this.getCurrentMasterDataObj().postalcode === undefined ? deleteField() : this.getCurrentMasterDataObj().postalcode,
      salesTaxId: this.getCurrentMasterDataObj().salesTaxId === undefined ? deleteField() : this.getCurrentMasterDataObj().salesTaxId,
      salutation: this.getCurrentMasterDataObj().salutation === undefined ? deleteField() : this.getCurrentMasterDataObj().salutation,
      socialSecurityNumber: this.getCurrentMasterDataObj().socialSecurityNumber === undefined ? deleteField() : this.getCurrentMasterDataObj().socialSecurityNumber,
      state: this.getCurrentMasterDataObj().state === undefined ? deleteField() : this.getCurrentMasterDataObj().state,
      street: this.getCurrentMasterDataObj().street === undefined ? deleteField() : this.getCurrentMasterDataObj().street,
      taxNumber: this.getCurrentMasterDataObj().taxNumber === undefined ? deleteField() : this.getCurrentMasterDataObj().taxNumber,
      threeGStatus: this.getCurrentMasterDataObj().threeGStatus === undefined ? deleteField() : this.getCurrentMasterDataObj().threeGStatus,
      threeGValidity: this.getCurrentMasterDataObj().threeGValidity === undefined || this.getCurrentMasterDataObj().threeGValidity === null
        ? deleteField() : this.getCurrentMasterDataObj().threeGValidity,
      title: this.getCurrentMasterDataObj().title === undefined ? deleteField() : this.getCurrentMasterDataObj().title,
      visible: this.getCurrentMasterDataObj().visible === undefined ? deleteField() : this.getCurrentMasterDataObj().visible
    });

    // Handle id card images
    // Deleted images
    if (deletedIdCardImages !== undefined && deletedIdCardImages.length > 0) {
      updateDoc(contactRef, {
        idCardImages: arrayRemove(...deletedIdCardImages)
      });
    }
    await this.imageService.deleteMultipleRemovedObjsFromStorage(deletedIdCardImages);
    // New images
    if (newIdCardImages !== undefined && newIdCardImages.length > 0) {
      newIdCardImages = await this.imageService.checkFirebaseMaxLength(newIdCardImages);
      updateDoc(contactRef, {
        idCardImages: arrayUnion(...newIdCardImages)
      });
    }
    await this.imageService.handleMultipleStorageUpload(newIdCardImages, this.getCurrentMasterDataObj().$key, this.getCurrentMasterDataObj().number, 'MASTERDATA_CONTACT'
      , this.getMasterDataNode(), 'idCardImages', this.getCurrentMasterDataObj(), this);

    // Handle id card PDFs
    // Deleted PDFs
    if (deletedIdCardPdfs && deletedIdCardPdfs.length > 0) {
      updateDoc(contactRef, {
        idCardPdfs: arrayRemove(...deletedIdCardPdfs)
      });
    }
    await this.imageService.deleteMultipleRemovedObjsFromStorage(deletedIdCardPdfs);
    // New PDFs
    if (newIdCardPdfs && newIdCardPdfs.length > 0) {
      updateDoc(contactRef, {
        idCardPdfs: arrayUnion(...newIdCardPdfs)
      });
    }
    await this.handleObjAdding(newIdCardPdfs, this.getMasterDataNode(), this.getCurrentMasterDataObj().$key, this.getCurrentMasterDataObj().number, 'MASTERDATA_CONTACT'
      , 'idCardPdfs', 'idCardPdfs');
  }

  private async handleObjAdding(newObjects: Array<any>, formNode: string, formId: string, formNumber: string, formTitle: string, arrayType: string, fieldName: string): Promise<void> {
    if (!newObjects || newObjects.length === 0) {
      return;
    } else {
      await Utils.asyncForEach(newObjects, (obj: any) => {
        const newDatetime = new Date().getTime();
        this.imageService.handleSingleStorageUpload(obj.image, formId, formNumber, formTitle, arrayType
          + '_' + newDatetime.toString(), formNode, fieldName, null, this, obj);
      });
    }
  }
}
