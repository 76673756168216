// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "TimeBurner";
  src: url('timeburner.ttf');
}
ion-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-grid {
  height: 100%;
}

ion-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#Circles .cls-1 {
  transform: translate(-100%);
  opacity: 0;
  animation: 1s 0.2s ease slideIn forwards;
  animation-fill-mode: forwards;
}
#Circles .cls-2 {
  transform: translate(100%);
  opacity: 0;
  animation: 1s 0.2s ease slideIn forwards;
  animation-fill-mode: forwards;
}
#Circles .cls-3 {
  opacity: 0;
  animation: 2s 1s ease fadeIn;
  animation-fill-mode: forwards;
}
#Circles .cls-4 {
  opacity: 0;
  animation: 2s 1s ease fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
@keyframes slideIn {
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,0BAAA;AACJ;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAAJ;;AAII;EACI,2BAAA;EACA,UAAA;EACA,wCAAA;EACA,6BAAA;AADR;AAII;EACI,0BAAA;EACA,UAAA;EACA,wCAAA;EACA,6BAAA;AAFR;AAKI;EACI,UAAA;EACA,4BAAA;EACA,6BAAA;AAHR;AAMI;EACI,UAAA;EACA,4BAAA;EACA,6BAAA;AAJR;;AAQA;EACI;IACI,UAAA;EALN;AACF;AAQA;EACI;IACI,UAAA;IACA,yBAAA;EANN;AACF","sourcesContent":["@font-face {\n    font-family: \"TimeBurner\";\n    src: url(\"../assets/fonts/timeburner.ttf\");\n}\n\nion-content {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\nion-grid {\n    height: 100%;\n}\n\nion-row {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n}\n\n#Circles {\n    .cls-1 {\n        transform: translate(-100%);\n        opacity: 0;\n        animation: 1s 0.2s ease slideIn forwards;\n        animation-fill-mode: forwards;\n    }\n\n    .cls-2 {\n        transform: translate(+100%);\n        opacity: 0;\n        animation: 1s 0.2s ease slideIn forwards;\n        animation-fill-mode: forwards;\n    }\n\n    .cls-3 {\n        opacity: 0;\n        animation: 2s 1s ease fadeIn;\n        animation-fill-mode: forwards;\n    }\n\n    .cls-4 {\n        opacity: 0;\n        animation: 2s 1s ease fadeIn;\n        animation-fill-mode: forwards;\n    }\n}\n\n@keyframes fadeIn {\n    100% {\n        opacity: 1;\n    }\n}\n\n@keyframes slideIn {\n    100% {\n        opacity: 1;\n        transform: translateX(0%);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
