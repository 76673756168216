import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from 'rxjs';
import { Utils } from './utils';
import { Translation } from "../interfaces/config/translation";
import { ConfigService } from '../services/config.service';

const translationConfigKeys = ['de_DE', 'en_US', 'es_ES', 'fr_FR', 'it_IT', 'nl_NL', 'pl_PL', 'pt_PT', 'ru_RU', 'tr_TR', 'zh_ZH'];

export class CustomLoader implements TranslateLoader {
    constructor(
        private configService: ConfigService
    ) { }

    public getTranslation(lang: string): Observable<any> {
        return new Observable((observer) => {
            this.configService.loadTranslations().then(async () => {
                var translations = this.configService.getTranslations();
                let fields = {};
                await Utils.asyncForEach(translations, (translation: Translation) => fields[translation.key] = translation.value);
                observer.next(fields[lang]);
            });
        });
    }
}