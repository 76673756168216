import { Injectable } from '@angular/core';
import { RemoteConfig, fetchAndActivate, getValue } from '@angular/fire/remote-config';
import { CategoryForms } from '../interfaces/config/category-forms';
import { Client } from '../interfaces/config/client';
import { Form } from '../interfaces/config/form';
import { Version } from '../interfaces/config/version';
import { Currency } from '../interfaces/config/currency';
import { Translation } from '../interfaces/config/translation';
import { Client as ClientEnum } from '../enums/client.enum';
import { MasterData, MasterDataContact, MasterDataObj } from '../interfaces/config/masterdata';
import { Utils } from '../utils/utils';
import { FormTransfer } from '../interfaces/config/form_transfer';
import { FormAssign } from '../interfaces/config/form_assign';
import { StorageService } from '../services/storage.service';
import { ImageService } from './image.service';
import { CovidCert } from '../interfaces/config/covid-cert';
import { PdfHeader } from '../interfaces/config/pdf-header';
import { Storage, ref, getDownloadURL, list as storageList, listAll } from '@angular/fire/storage';

const clientsConfigKey = 'clients';
const versionConfigKey = 'version';
const translationConfigKeys = ['de_DE', 'en_US', 'es_ES', 'fr_FR', 'it_IT', 'nl_NL', 'pl_PL', 'pt_PT', 'ru_RU', 'tr_TR', 'zh_ZH'];

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private categoryForms: Array<CategoryForms>;
  private availableCategories: Array<string>;
  private selectedCategories: Array<string>;
  private availableForms: Array<string>;
  private masterData: MasterData;
  private forms: Array<Form>;
  private formsTransfer: Array<FormTransfer>;
  private formsAssign: Array<FormAssign>;
  private clientMapping: Client;
  private version: Version;
  private currencies: Array<Currency>;
  private translations: Array<Translation>;
  private covidCerts: Array<CovidCert>;
  private allFormsLoaded: boolean = false;
  private pdfHeaders: Array<PdfHeader>;

  constructor(
    private imageService: ImageService,
    private storageService: StorageService,
    private storage: Storage,
    private remoteConfig: RemoteConfig
  ) { }

  public async loadGeneralConfigs(): Promise<void> {
    // Set fetch interval for fetching remote config to 0
    this.remoteConfig.settings.minimumFetchIntervalMillis = 0;
    await fetchAndActivate(this.remoteConfig);
    await this.loadCurrencies();
  }

  public async loadAllSpecificConfigs(client: string, userIsTeamLead: boolean, userPermissions: Array<string>, userCategories?: Array<string>): Promise<void> {
    this.allFormsLoaded = false;
    client = client === undefined ? ClientEnum.Basic : client;
    await Promise.all(new Array<Promise<void>>(this.loadClientMapping(client), this.loadFormsTransferMapping(client), this.loadFormsAssignMapping(client), this.loadPdfHeadersMapping(client)
      , this.loadAllCategoryBasedConfigs(client, userIsTeamLead, userPermissions, userCategories)));
  }

  public async loadAllCategoryBasedConfigs(client: string, userIsTeamLead: boolean, userPermissions: Array<string>, userCategories?: Array<string>): Promise<void> {
    this.allFormsLoaded = false;
    client = client === undefined ? ClientEnum.Basic : client;
    if (userCategories) {
      this.selectedCategories = userCategories;
    }
    await this.loadCategoryFormsMapping(client);
    await Promise.all(new Array<Promise<any>>(this.loadFormsMapping(client, userIsTeamLead, userPermissions), this.loadMasterDataMapping(client, userPermissions)));
  }

  public getMasterDataMapping(): MasterData {
    return this.masterData;
  }

  public getMasterDataProductMappingByCategory(category: string): MasterDataObj {
    return this.getMasterDataMapping()?.products?.filter((p: MasterDataObj) => p.category === category).pop();
  }

  public getMasterDataContactMappingByCategory(category: string): MasterDataObj {
    return this.getMasterDataMapping()?.contacts?.filter((p: MasterDataObj) => p.category === category).pop();
  }

  public getFormsMapping(): Array<Form> {
    return this.forms;
  }

  public getAllFormsLoaded(): boolean {
    return this.allFormsLoaded;
  }

  public getFormMappingByType(type: string): Form {
    return this.getFormsMapping().filter((f: Form) => f.type === type).pop();
  }

  public getFormMappingByNode(node: string): Form {
    return this.getFormsMapping().filter((f: Form) => f.node === node).pop();
  }

  public getFormMappingByNodeAndSubType(node: string, subType: string): Form {
    return this.getFormsMapping().filter((f: Form) => f.node === node).filter((f: Form) => f.subType?.name === subType).pop();
  }

  public getAllFormMappingByType(type: string): Array<Form> {
    return this.getFormsMapping().filter((f: Form) => f.type === type);
  }

  public getFormMappingByTypeAndSubType(type: string, subType: string): Form {
    return this.getFormsMapping().filter((f: Form) => f.type === type).filter((f: Form) => f.subType?.name === subType).pop();
  }

  public getClientMapping(): Client {
    return this.clientMapping;
  }

  public getAvailableCategories(): Array<string> {
    return this.availableCategories;
  }

  public getSelectedCategories(): Array<string> {
    return this.selectedCategories;
  }

  public getCategoryText(category: string): string {
    return this.categoryForms.filter((c: CategoryForms) => c.category === category)?.pop()?.text;
  }

  public getCategoryByFormType(formType: string, formSubType: string): string {
    if (formSubType) {
      return this.categoryForms.filter((c: CategoryForms) => c.forms.find((f: string) => f === (formType + '_' + formSubType)))[0].category;
    } else {
      return this.categoryForms.filter((c: CategoryForms) => c.forms.find((f: string) => f === formType))[0].category;
    }
  }

  public async getVersion(): Promise<Version> {
    var version = this.version ?? JSON.parse(getValue(this.remoteConfig, versionConfigKey).asString());
    this.version = version;
    return version;
  }

  public getCurrencies(): Array<Currency> {
    return this.currencies;
  }

  public getTranslations(): Array<Translation> {
    return this.translations;
  }

  public getCovidCerts(): Array<CovidCert> {
    return this.covidCerts;
  }

  public getFormsTransferMapping(): Array<FormTransfer> {
    return this.formsTransfer;
  }

  public getFormsAssignMapping(): Array<FormAssign> {
    return this.formsAssign;
  }

  public getPdfHeadersMapping(): Array<PdfHeader> {
    return this.pdfHeaders;
  }

  public getPdfHeadersMappingByName(name: string): PdfHeader {
    return this.pdfHeaders?.filter((p: PdfHeader) => p.name === name)[0];
  }

  public async loadTranslations(): Promise<void> {
    var fileUrlsPromises = new Array<Promise<string>>();
    var translationPromises = new Array<Promise<string>>();
    this.translations = new Array<Translation>();

    const translationsRef = ref(this.storage, 'mappings/general/translations');
    await Utils.asyncForEach(translationConfigKeys, async (translationConfigKey: string) => {
      const translationRef = ref(translationsRef, translationConfigKey + '.json');
      var fileUrlPromise = getDownloadURL(translationRef);
      fileUrlsPromises.push(fileUrlPromise);
    });

    var fileUrls = await Promise.all(fileUrlsPromises);
    await Utils.asyncForEach(fileUrls, (fileUrl: string) => {
      translationPromises.push(this.imageService.getDataFromExternalUrl(fileUrl));
    });

    var translations = await Promise.all(translationPromises);
    await Utils.asyncForEach(translations, (translation: string) => {
      var parsedTranslation = this.getJsonValue(translation) as Translation;
      parsedTranslation.key = translationConfigKeys[translations.indexOf(translation)];
      this.translations.push(parsedTranslation);
    });
  }

  public async loadCovidCerts(): Promise<void> {
    if (!this.covidCerts) {
      const certsRef = ref(this.storage, 'mappings/general/covidCerts.json');
      const certsUrl = await getDownloadURL(certsRef);
      const certsBase64 = await this.imageService.getDataFromExternalUrl(certsUrl);

      var certs = this.getJsonValue(certsBase64).certs as Array<CovidCert>;
      this.covidCerts = certs;
    }
  }

  public clearConfigs(): void {
    this.categoryForms = undefined;
    this.availableCategories = undefined;
    this.selectedCategories = undefined;
    this.availableForms = undefined;
    this.masterData = undefined;
    this.forms = undefined;
    this.formsTransfer = undefined;
    this.formsAssign = undefined;
    this.clientMapping = undefined;
    this.version = undefined;
    this.allFormsLoaded = false;
    this.pdfHeaders = undefined;
  }

  private async loadCurrencies(): Promise<void> {
    if (!this.currencies) {
      const currenciesRef = ref(this.storage, 'mappings/general/currencies.json');
      const currenciesUrl = await getDownloadURL(currenciesRef);
      const currenciesBase64 = await this.imageService.getDataFromExternalUrl(currenciesUrl);
      var currencies = this.getJsonValue(currenciesBase64) as Array<Currency>;
      this.currencies = currencies;
    }
  }

  private async loadClientMapping(client: string): Promise<void> {
    client = client === undefined ? ClientEnum.Basic : client;
    var clientMapping = (JSON.parse(getValue(this.remoteConfig, clientsConfigKey).asString()) as Array<Client>).filter(c => c.name === client).pop();
    this.clientMapping = clientMapping;
  }

  private async loadCategoryFormsMapping(client: string): Promise<void> {
    this.availableForms = new Array<string>();
    this.availableCategories = new Array<string>();

    const formsRef = ref(this.storage, `mappings/clients/${client}/forms`);
    var formList = await storageList(formsRef);
    this.availableCategories = formList.prefixes.map(p => p.name);
    this.categoryForms = new Array<CategoryForms>();

    await Utils.asyncForEach(this.availableCategories, async (c: string) => {
      const categoryRef = ref(this.storage, formsRef + '/' + c);
      var forms = await listAll(categoryRef);

      var categoryForms: CategoryForms = {
        category: c,
        text: c.toUpperCase(),
        forms: forms.items.map(i => i.name.replace('.json', ''))
      };
      this.categoryForms.push(categoryForms);
    });

    await Utils.asyncForEach(this.categoryForms
      .filter((c: CategoryForms) => {
        if (this.selectedCategories !== undefined) {
          return this.selectedCategories?.includes(c.category)
        } else {
          return true;
        }
      })
      , async (c: CategoryForms) => {
        await Utils.asyncForEach(c.forms, (form: string) => {
          if (!this.availableForms?.includes(form)) {
            this.availableForms.push(form);
          }
        });
      });
  }

  private async loadFormsMapping(client: string, userIsTeamLead: boolean, userPermissions: Array<string>): Promise<Array<Form>> {
    this.allFormsLoaded = false;
    this.forms = new Array<Form>();
    var formUrlsPromises = new Array<Promise<string>>();
    var formPromises = new Array<Promise<string>>();

    var language = await this.storageService.get('language');

    const activeFormsRef = ref(this.storage, `mappings/clients/${client}/forms/active.json`);
    const activeFormsUrl = await getDownloadURL(activeFormsRef);
    const activeFormsBase64 = await this.imageService.getDataFromExternalUrl(activeFormsUrl);

    var activeForms = this.getJsonValue(activeFormsBase64) as Array<string>;
    await Utils.asyncForEach(activeForms.filter((a: string) => this.availableForms?.includes(a)), async (activeForm: string) => {
      var categoryForm = this.categoryForms.filter(c => c.forms?.includes(activeForm))[0];
      const formRef = ref(this.storage, `mappings/clients/${client}/forms/${categoryForm.category}/${activeForm}.json`);
      formUrlsPromises.push(getDownloadURL(formRef));
    });

    var formUrls = await Promise.all(formUrlsPromises);
    await Utils.asyncForEach(formUrls, (formUrl: string) => {
      formPromises.push(this.imageService.getDataFromExternalUrl(formUrl));
    });

    var forms = await Promise.all(formPromises);
    await Utils.asyncForEach(forms, (form: string) => {
      try {
        var formValue = this.getJsonValue(form) as Form;

        if (formValue.languages?.includes(language)) {
          if (Utils.checkReadPermissions(client, userPermissions, formValue.permissions)
            || Utils.checkWritePermissions(client, userPermissions, formValue.permissions)) {
            this.forms.push(formValue);
          } else {
            return;
          }
        } else {
          return;
        }
      } catch (ex) {
        console.log(ex);
      }
    });

    this.allFormsLoaded = true;

    return this.forms;
  }

  private async loadMasterDataMapping(client: string, userPermissions: Array<string>): Promise<void> {
    this.masterData = {};
    await this.loadContactMappings(client, userPermissions);
    await this.loadProductMappings(client, userPermissions);
  }

  private async loadContactMappings(client: string, userPermissions: Array<string>): Promise<void> {
    this.masterData.contacts = new Array<MasterDataObj>();

    const contactsRef = ref(this.storage, `mappings/clients/${client}/masterData/contacts/`);
    var list = await storageList(contactsRef);
    var contacts = list.items.map(p => p.name);

    await Utils.asyncForEach(contacts
      , async (contact: string) => {
        const contactRef = ref(this.storage, contactsRef + '/' + contact);
        const contactUrl = await getDownloadURL(contactRef);
        const contactBase64 = await this.imageService.getDataFromExternalUrl(contactUrl);
        var contactValue = this.getJsonValue(contactBase64) as MasterDataObj;
        if (Utils.checkReadPermissions(client, userPermissions, contactValue.permissions)
          || Utils.checkWritePermissions(client, userPermissions, contactValue.permissions)) {
          this.masterData.contacts.push(contactValue);
        }
      });
  }

  private async loadProductMappings(client: string, userPermissions: Array<string>): Promise<void> {
    this.masterData.products = new Array<MasterDataObj>();

    const productsRef = ref(this.storage, `mappings/clients/${client}/masterData/products/`);
    var list = await storageList(productsRef);
    var products = list.items.map(p => p.name);

    await Utils.asyncForEach(products
      .filter(p => {
        if (this.selectedCategories !== undefined) {
          return this.selectedCategories?.includes(p.replace('.json', ''))
        } else {
          return true;
        }
      })
      , async (product: string) => {
        const productRef = ref(this.storage, productsRef + '/' + product);
        const productUrl = await getDownloadURL(productRef);
        const productBase64 = await this.imageService.getDataFromExternalUrl(productUrl);
        var productValue = this.getJsonValue(productBase64) as MasterDataObj;
        if (Utils.checkReadPermissions(client, userPermissions, productValue.permissions)
          || Utils.checkWritePermissions(client, userPermissions, productValue.permissions)) {
          this.masterData.products.push(productValue);
        }
      });
  }

  private async loadFormsTransferMapping(client: string): Promise<void> {
    const transferRef = ref(this.storage, `mappings/clients/${client}/forms/transfer.json`);
    const transferUrl = await getDownloadURL(transferRef);
    const transferBase64 = await this.imageService.getDataFromExternalUrl(transferUrl);
    var formsTransfer = this.getJsonValue(transferBase64) as Array<FormTransfer>;
    this.formsTransfer = formsTransfer;
  }

  private async loadFormsAssignMapping(client: string): Promise<void> {
    const assignRef = ref(this.storage, `mappings/clients/${client}/forms/assign.json`);
    const assignUrl = await getDownloadURL(assignRef);
    const assignBase64 = await this.imageService.getDataFromExternalUrl(assignUrl);
    var formsAssign = this.getJsonValue(assignBase64) as Array<FormAssign>;
    this.formsAssign = formsAssign;
  }

  private async loadPdfHeadersMapping(client: string): Promise<void> {
    try {
      const pdfHeadersRef = ref(this.storage, `mappings/clients/${client}/forms/pdfHeaders.json`);
      const pdfHeadersUrl = await getDownloadURL(pdfHeadersRef);
      const pdfHeadersBase64 = await this.imageService.getDataFromExternalUrl(pdfHeadersUrl);
      var pdfHeaders = this.getJsonValue(pdfHeadersBase64) as Array<PdfHeader>;
      this.pdfHeaders = pdfHeaders;
    } catch { }
  }

  private getJsonValue(value: string): any {
    return JSON.parse(this.b64DecodeUnicode(value.replace('data:application/json;base64,', '')));
  }

  private b64DecodeUnicode(str: string) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }
}
