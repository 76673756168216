export enum BodyType {
    Text = 'text',
    Table = 'table',
    CoverImage = 'coverImage',
    Image = 'image',
    Empty = 'empty',
    NetAmount = 'netAmount',
    VatAmount = 'vatAmount',
    TotalAmount = 'totalAmount',
    Count = 'count',
    Checkbox = 'checkbox',
    ModalSerialNumber = 'modalSerialNumber',
    Logo = 'clientLogo',
    Address = 'profileAddress'
}

export enum BodyStyle {
    StandardTextBold = 'standardTextBold',
    StandardText = 'standardText',
    StandardTextSmallBold = 'standardTextSmallBold',
    Header = 'header'
}