// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  integration: false,
  development: true,
  firebase: {
    apiKey: 'AIzaSyAIi_sZIE2M9l_GJNuN26MIK8UkX9Kq7IE',
    authDomain: 'thumbify-dev.firebaseapp.com',
    databaseURL: 'https://thumbify-dev.firebaseio.com',
    projectId: 'thumbify-dev',
    storageBucket: 'thumbify-dev.appspot.com',
    messagingSenderId: '858824665559',
    appId: "1:858824665559:web:0c370bf2440ab694cdc392",
    measurementId: "G-2D109WMJKE",
    vapidKey: 'BFr5DJ9E521PJIU6PiuG6gfP3GWi8mZ1xHn1yoKs5ocO60Chsq6HyDhBZIb0qYCYZPEqn-TjtxTVqZnSdYKJ9PI'
  },
  webClientId: '858824665559-1jp9htgc04neli6ghs8mag7e023oc3du.apps.googleusercontent.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
