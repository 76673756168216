import { Component, ViewChild } from '@angular/core';
import { Platform, PopoverController, AlertController, NavParams, IonHeader } from '@ionic/angular';
import { StorageService } from '../../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { Utils } from '../../../utils/utils';
import { Browser } from '../../../enums/browser.enum';
import { Form } from '../../../interfaces/config/form';
import { ConfigService } from '../../../services/config.service';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app-filter-dashboard-popover',
  templateUrl: './filter-dashboard-popover.page.html',
  styleUrls: ['./filter-dashboard-popover.page.scss'],
})
export class FilterDashboardPopoverPage {
  private pageTrace: PerformanceTrace;

  @ViewChild(IonHeader) header: IonHeader;
  public availableDocuments: Array<string>;
  public currencies: Array<string>;
  public costCenters: Array<string>;
  public availableCurrencies: Array<string>;
  public availableCostCenters: Array<string>;
  public forms: Array<Form>;
  public translatedFormTypes = new Array<string>();

  constructor(
    public translate: TranslateService,
    private navParams: NavParams,
    private platform: Platform,
    private popoverController: PopoverController,
    private alertController: AlertController,
    public authService: AuthService,
    private configService: ConfigService,
    private storageService: StorageService,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.pageTrace = trace(this.performance, FilterDashboardPopoverPage.name);
  }

  public async ionViewWillEnter(): Promise<void> {
    var darkMode = await this.storageService.get('darkMode');
    if (darkMode !== null) {
      if (!darkMode) {
        document.body.style.setProperty('--ion-card-var', '#ffffff');
      } else {
        this.platform.is('ios')
          ? document.body.style.setProperty('--ion-card-var', '#1c1c1c')
          : document.body.style.setProperty('--ion-card-var', '#1a1b1e');
      }
    } else {
      document.body.style.setProperty('--ion-card-var', '#ffffff');
    }

    await this.storageService.set('component', FilterDashboardPopoverPage.name);
    logEvent(this.analytics, 'screen_view');
    try { this.pageTrace.start(); } catch (error) { }

    this.forms = this.configService.getFormsMapping();
    this.currencies = this.navParams.get('currencies');
    this.availableCurrencies = this.navParams.get('availableCurrencies').sort();
    this.costCenters = this.navParams.get('costCenters');
    this.availableCostCenters = this.navParams.get('availableCostCenters').sort();
    this.availableDocuments = this.navParams.get('availableDocuments');
    if (this.availableDocuments) {
      this.availableDocuments = this.availableDocuments.sort();
    } else {
      this.availableDocuments = new Array<string>();
      this.forms.forEach((f: Form) => {
        this.availableDocuments.push(f.type);
      });
    }

    this.setTranslatedFormTypes();

    await this.adjustPosition();
  }

  public async ionViewWillLeave(): Promise<void> {
    try { this.pageTrace.stop(); } catch (error) { }
  }

  public delete(type: string): void {
    this.availableDocuments = this.availableDocuments.filter(t => t !== type);
  }

  public setTranslatedFormTypes(): void {
    this.translatedFormTypes = new Array<string>();
    this.getAvailableForms().forEach((f: Form) => {
      let translation: string;
      this.translate.get(f.translations.title).subscribe((result: string) => translation = result);
      this.translatedFormTypes.push(translation);
    });
  }

  public async selectDocuments() {
    var all: string, none: string, ok: string, cancel: string, documents: string;
    this.translate.get('BTN_ALL').subscribe((result: string) => all = result);
    this.translate.get('BTN_NONE').subscribe((result: string) => none = result);
    this.translate.get('OK').subscribe((result: string) => ok = result);
    this.translate.get('BTN_CANCEL').subscribe((result: string) => cancel = result);
    this.translate.get('DOCUMENTS').subscribe((result: string) => documents = result);

    var buttons = [
      {
        text: all,
        cssClass: 'all-none-button',
        handler: () => {
          alert.inputs = alert.inputs.map((checkbox) => {
            checkbox.checked = true;
            return checkbox;
          });
          return false;
        }
      }, {
        text: none,
        cssClass: 'all-none-button',
        handler: () => {
          alert.inputs = alert.inputs.map((checkbox) => {
            checkbox.checked = false;
            return checkbox;
          });
          return false;
        }
      }, {
        text: ok,
        handler: (data) => {
          this.availableDocuments = data;
          this.setTranslatedFormTypes();
        }
      },
      , {
        text: cancel,
        role: 'cancel',
      }
    ];

    buttons = buttons.filter(b => b);

    var inputs = new Array<any>();
    this.forms.forEach((f: Form) => {
      let translation: string;
      this.translate.get(f.translations.title).subscribe((result: string) => translation = result);
      if (!inputs.some(i => i.value === f.type)) {
        inputs.push({
          label: translation,
          type: 'checkbox',
          value: f.type,
          checked: this.getAvailableForms().some(f2 => f2.type === f.type)
        });
      }
    });

    // adjust button order in four button layout for ios
    if (this.platform.is('ios')) {
      const okButton = { ...buttons[2] };
      const cancelButton = { ...buttons[3] };
      buttons = [buttons[0], buttons[1], cancelButton, okButton];
    }

    const alert = await this.alertController.create({
      header: documents,
      inputs,
      cssClass: 'four-button-alert',
      buttons
    });

    await alert.present();
  }

  public getAvailableForms(): Array<Form> {
    var availableForms = new Array<Form>();

    this.forms?.filter((f: Form) => this.availableDocuments.includes(f.type)).forEach((f: Form) => {
      if (availableForms.some((f2: Form) => f2.type === f.type)) { return; }
      availableForms.push(f);
    });

    return availableForms;
  }

  public async show(): Promise<void> {
    await this.popoverController.dismiss({
      currencies: this.currencies,
      costCenters: this.costCenters,
      availableDocuments: this.availableDocuments
    });
  }

  public async reset(): Promise<void> {
    this.currencies = undefined;
    this.costCenters = undefined;
    if (this.availableDocuments.length !== this.forms.length) {
      this.availableDocuments = new Array<string>();
      this.forms.forEach((f: Form) => {
        this.availableDocuments.push(f.type);
      });
      this.availableDocuments.sort();
    }
    await this.popoverController.dismiss({
      currencies: this.currencies,
      costCenters: this.costCenters,
      availableDocuments: this.availableDocuments
    });
  }

  public getDynamicIdentifier(prefix: string, key: string): string {
    return Utils.getDynamicIdentifier(prefix, key);
  }

  private async adjustPosition(): Promise<void> {
    var element = document.getElementsByTagName('ion-popover');
    if (element[0]) {
      var wrapperOpen = await Utils.getChildElement(element[0].shadowRoot.childNodes, 1);
      var content = await Utils.getChildElement(wrapperOpen.childNodes, 1);
      var contentStyle: CSSStyleDeclaration = content['style'];
      contentStyle.transformOrigin = 'right top';
      contentStyle.left = '';
      contentStyle.top = this.navParams.get('headerHeight') + 'px';
      if (Utils.getBrowser() === Browser.Chrome) {
        contentStyle.right = '10px';
      } else {
        contentStyle.right = (this.navParams.get('margin') + 10) + 'px';
      }
    } else {
      // Wait until element is loaded
      setTimeout(async () => {
        await this.adjustPosition();
      }, 10);
    }
  }
}