import { Component } from '@angular/core';
import { Platform, PopoverController, AlertController, NavParams } from '@ionic/angular';
import { StorageService } from '../../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { Utils } from '../../../utils/utils';
import { ContactService } from '../../master-data/contact/contact.service';
import { ProductService } from '../../master-data/product/product.service';
import { Browser } from '../../../enums/browser.enum';
import { ProductCategory } from '../../../enums/product-category.enum';
import { ConfigService } from '../../../services/config.service';
import { MasterDataContact, MasterDataOverviewLabel, MasterDataObj, MasterDataSortCriteria } from '../../../interfaces/config/masterdata';
import { MasterDataType } from '../../../enums/masterdata-type.enum';
import { ContactCategory } from 'src/app/enums/contact-category.enum';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app-filter-masterdata-popover',
  templateUrl: './filter-masterdata-popover.page.html',
  styleUrls: ['./filter-masterdata-popover.page.scss'],
})
export class FilterMasterDataPopoverPage {
  private pageTrace: PerformanceTrace;

  public masterDataProducts: Array<MasterDataObj>;
  public masterDataContacts: Array<MasterDataObj>;
  public masterDataType: string;
  public productCategories: Array<string>;
  public contactCategories: Array<string>;
  public isAscSelected: boolean = false;
  public isDescSelected: boolean = false;
  public productCategory: typeof ProductCategory = ProductCategory;
  public masterDataTypeEnum: typeof MasterDataType = MasterDataType;
  public availableSortCriteria = new Map<string, Array<string>>();
  public activeSortCriteria = new Array<string>();
  public activeSortTitle: string;
  public productResults = new Array<string>();
  public translatedResults = new Array<string>();

  constructor(
    public translate: TranslateService,
    private navParams: NavParams,
    private platform: Platform,
    private popoverController: PopoverController,
    private alertController: AlertController,
    public authService: AuthService,
    public contactService: ContactService,
    public productService: ProductService,
    private configService: ConfigService,
    private storageService: StorageService,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.pageTrace = trace(this.performance, FilterMasterDataPopoverPage.name);
  }

  public async ionViewWillEnter(): Promise<void> {
    var darkMode = await this.storageService.get('darkMode');
    if (darkMode !== null) {
      if (!darkMode) {
        document.body.style.setProperty('--ion-card-var', '#ffffff');
      } else {
        this.platform.is('ios')
          ? document.body.style.setProperty('--ion-card-var', '#1c1c1c')
          : document.body.style.setProperty('--ion-card-var', '#1a1b1e');
      }
    } else {
      document.body.style.setProperty('--ion-card-var', '#ffffff');
    }

    await this.storageService.set('component', FilterMasterDataPopoverPage.name);
    logEvent(this.analytics, 'screen_view');
    try { this.pageTrace.start(); } catch (error) { }

    this.masterDataProducts = this.configService.getMasterDataMapping().products;
    this.masterDataContacts = this.configService.getMasterDataMapping().contacts;
    this.masterDataType = this.navParams.get('masterDataType');
    this.productCategories = this.navParams.get('productCategories');
    this.contactCategories = this.navParams.get('contactCategories');
    this.productResults = this.navParams.get('productResults');
    if (this.productCategories) {
      this.productCategories = this.productCategories.sort();
    } else {
      this.productCategories = new Array<string>();
      this.masterDataProducts.forEach((p: MasterDataObj) => {
        this.productCategories.push(p.category);
      });
    }
    if (this.contactCategories) {
      this.contactCategories = this.contactCategories.sort();
    } else {
      this.contactCategories = new Array<string>();
      this.masterDataContacts.forEach((p: MasterDataObj) => {
        this.contactCategories.push(p.category);
      });
    }
    if (this.productResults) {
      this.productResults = this.productResults.sort();
    } else {
      this.productResults = new Array<string>();
      this.getResults().forEach((r: { value: string, text: string }) => {
        this.productResults.push(r.value);
      });
    }

    this.getSortCriteria();
    this.activeSortCriteria = this.navParams.get('activeSortCriteria');
    this.getActiveSortTitle();
    this.setTranslatedResults();

    this.isDescSelected = this.navParams.get('orderDesc');
    this.isAscSelected = !this.isDescSelected;

    await this.adjustPosition();
  }

  public async ionViewWillLeave(): Promise<void> {
    try { this.pageTrace.stop(); } catch (error) { }
  }

  public deleteProductCategory(category: string): void {
    this.productCategories = this.productCategories.filter((c: string) => c !== category);
    this.getSortCriteria();
  }

  public deleteContactCategory(category: string): void {
    this.contactCategories = this.contactCategories.filter((c: string) => c != category);
    this.getSortCriteria();
  }

  public clearProductCategories(): void {
    this.productCategories = new Array<string>();
    this.getSortCriteria();
  }

  public clearContactCategories(): void {
    this.contactCategories = new Array<string>();
    this.getSortCriteria();
  }

  public async addProductCategory(): Promise<void> {
    var addProductCategory: string, noMoreProductCategory: string, ok: string;
    var inputs = new Array<any>();
    this.translate.get('MASTERDATA_FILTER_ADDPRODUCTCATEGORY').subscribe((result: string) => addProductCategory = result);
    this.translate.get('MASTERDATA_FILTER_NOMOREPRODUCTCATEGORY').subscribe((result: string) => noMoreProductCategory = result);
    this.translate.get('OK').subscribe((result: string) => ok = result);

    this.masterDataProducts.forEach((p: MasterDataObj) => {
      if (!this.productCategories.includes(p.category)) {
        var title: string;
        this.translate.get(p.text).subscribe((result: string) => title = result);

        inputs.push({
          type: 'checkbox',
          name: title,
          label: title,
          value: p.category
        });
      }
    });

    var alert: HTMLIonAlertElement;
    if (inputs.length > 0) {
      alert = await this.alertController.create({
        header: addProductCategory,
        inputs: inputs.sort(),
        buttons: [
          {
            text: ok,
            handler: async (data: Array<string> | undefined) => {
              if (data !== undefined && data.length > 0) {
                await Utils.asyncForEach(data, (d: string) => {
                  this.productCategories.push(d);
                });
                this.productCategories.sort();
              }
              this.getSortCriteria();
            }
          }]
      });
    } else {
      alert = await this.alertController.create({
        subHeader: noMoreProductCategory,
        buttons: [
          {
            text: ok
          }
        ]
      })
    }
    await alert.present();
  }

  public async addContactCategory(): Promise<void> {
    var addContactCategory: string, noMoreContactCategory: string, ok: string;
    var inputs = new Array<any>();
    this.translate.get('MASTERDATA_FILTER_ADDCONTACTCATEGORY').subscribe((result: string) => addContactCategory = result);
    this.translate.get('MASTERDATA_FILTER_NOMORECONTACTCATEGORY').subscribe((result: string) => noMoreContactCategory = result);
    this.translate.get('OK').subscribe((result: string) => ok = result);

    this.masterDataContacts.forEach((p: MasterDataObj) => {
      if (!this.contactCategories.includes(p.category)) {
        var title: string;
        this.translate.get(p.text).subscribe((result: string) => title = result);

        inputs.push({
          type: 'checkbox',
          name: title,
          label: title,
          value: p.category
        });
      }
    });

    var alert: HTMLIonAlertElement;
    if (inputs.length > 0) {
      alert = await this.alertController.create({
        header: addContactCategory,
        inputs: inputs.sort(),
        buttons: [
          {
            text: ok,
            handler: async (data: Array<string> | undefined) => {
              if (data !== undefined && data.length > 0) {
                await Utils.asyncForEach(data, (d: string) => {
                  this.contactCategories.push(d);
                });
                this.contactCategories.sort();
              }
              this.getSortCriteria();
            }
          }]
      });
    } else {
      alert = await this.alertController.create({
        subHeader: noMoreContactCategory,
        buttons: [
          {
            text: ok
          }
        ]
      })
    }
    await alert.present();
  }

  public async show(): Promise<void> {
    await this.popoverController.dismiss({
      activeSortCriteria: this.availableSortCriteria.get(this.activeSortTitle),
      orderDesc: this.isDescSelected,
      productCategories: this.productCategories,
      contactCategories: this.contactCategories,
      productResults: this.productResults
    });
  }

  public selectAsc(): void {
    this.isDescSelected = false;
    this.isAscSelected = true;
  }

  public selectDesc(): void {
    this.isAscSelected = false;
    this.isDescSelected = true;
  }

  public async reset(): Promise<void> {
    if (this.productCategories.length !== this.masterDataProducts.length) {
      this.productCategories = new Array<string>();
      this.masterDataProducts.forEach((p: MasterDataObj) => {
        this.productCategories.push(p.category);
      });
      this.productCategories.sort();
    }
    if (this.contactCategories.length !== this.masterDataContacts.length) {
      this.contactCategories = new Array<string>();
      this.masterDataContacts.forEach((c: MasterDataObj) => {
        this.contactCategories.push(c.category);
      });
      this.contactCategories.sort();
    }
    this.productResults = new Array<string>();
    this.getResults().forEach((c: { value: string, text: string }) => {
      this.productResults.push(c.value);
    });
    this.getSortCriteria();
    this.getActiveSortTitle();
    this.activeSortCriteria = this.masterDataType === MasterDataType.Contact ? this.availableSortCriteria.get('LASTNAME') : this.availableSortCriteria.get('TITLE');
    this.isAscSelected = false;
    this.isDescSelected = true;
    await this.popoverController.dismiss({
      activeSortCriteria: this.activeSortCriteria,
      orderDesc: this.isDescSelected,
      productCategories: this.productCategories,
      contactCategories: this.contactCategories
    });
  }

  public getAvailableProducts(): Array<MasterDataObj> {
    return this.masterDataProducts?.filter((m: MasterDataObj) => this.productCategories.includes(m.category));
  }

  public getAvailableContacts(): Array<MasterDataObj> {
    return this.masterDataContacts?.filter((c: MasterDataObj) => this.contactCategories.includes(c.category));
  }

  public getResults(): Array<{ value: string, text: string }> {
    var items = new Array<{ value: string, text: string }>();
    this.masterDataProducts.forEach(p => {
      p.attributes.forEach(a => {
        var resultControl = a.controls.filter(c => c.key === 'result')[0];
        if (resultControl) {
          resultControl.selectOptions.items.forEach(i => {
            items.push({
              value: i.value,
              text: i.text
            });
          });
        }
      })
    });
    return items;
  }

  public async selectResults(): Promise<void> {
    var all: string, none: string, ok: string, cancel: string, results: string;
    this.translate.get('BTN_ALL').subscribe((result: string) => all = result);
    this.translate.get('BTN_NONE').subscribe((result: string) => none = result);
    this.translate.get('OK').subscribe((result: string) => ok = result);
    this.translate.get('BTN_CANCEL').subscribe((result: string) => cancel = result);
    this.translate.get('MASTERDATA_PRODUCT_RESULT').subscribe((result: string) => results = result);

    var buttons = [
      {
        text: all,
        cssClass: 'all-none-button',
        handler: () => {
          alert.inputs = alert.inputs.map((checkbox) => {
            checkbox.checked = true;
            return checkbox;
          });
          return false;
        }
      }, {
        text: none,
        cssClass: 'all-none-button',
        handler: () => {
          alert.inputs = alert.inputs.map((checkbox) => {
            checkbox.checked = false;
            return checkbox;
          });
          return false;
        }
      }, {
        text: ok,
        handler: (data) => {
          this.productResults = data;
          this.setTranslatedResults();
        }
      },
      , {
        text: cancel,
        role: 'cancel',
      }
    ];

    buttons = buttons.filter(b => b);

    var inputs = new Array<any>();
    this.getResults().forEach((r: { value: string, text: string }) => {
      let translation: string;
      this.translate.get(r.text).subscribe((result: string) => translation = result);
      if (!inputs.some(i => i.value === r.value)) {
        inputs.push({
          label: translation,
          type: 'checkbox',
          value: r.value,
          checked: this.productResults.some(r2 => r2 === r.value)
        });
      }
    });

    // adjust button order in four button layout for ios
    if (this.platform.is('ios')) {
      const okButton = { ...buttons[2] };
      const cancelButton = { ...buttons[3] };
      buttons = [buttons[0], buttons[1], cancelButton, okButton];
    }

    const alert = await this.alertController.create({
      header: results,
      inputs,
      cssClass: 'four-button-alert',
      buttons
    });

    await alert.present();
  }

  public setTranslatedResults(): void {
    this.translatedResults = new Array<string>();
    this.getResults().filter(r => this.productResults.includes(r.value)).forEach((r: { value: string, text: string }) => {
      let translation: string;
      this.translate.get(r.text).subscribe((result: string) => translation = result);
      this.translatedResults.push(translation);
    });
  }

  public getSortCriteria(): void {
    this.availableSortCriteria = new Map<string, Array<string>>();

    this.availableSortCriteria.set('CREATIONDATE', new Array<string>('creationDate'));
    this.availableSortCriteria.set('NUMBER', new Array<string>('number'));

    var objs = this.masterDataType === MasterDataType.Contact ? this.getAvailableContacts() : this.getAvailableProducts();
    objs.forEach(async (o: MasterDataObj) => {
      o.overview.boldLabels.concat(o.overview.labels).forEach((l: MasterDataOverviewLabel) => {
        if (!l.sortTranslation) { return; }

        if (this.availableSortCriteria.has(l.sortTranslation)) {
          var values = this.availableSortCriteria.get(l.sortTranslation);
          if (!values.includes(l.property)) {
            values.push(l.property);
          }
        } else {
          this.availableSortCriteria.set(l.sortTranslation, new Array<string>(l.property));
        }
      });
    });
  }

  public getDynamicIdentifier(prefix: string, key: string): string {
    return Utils.getDynamicIdentifier(prefix, key);
  }

  private getActiveSortTitle(): void {
    this.availableSortCriteria.forEach((value: Array<string>, key: string) => {
      if (value.some(v => this.activeSortCriteria.includes(v))) {
        this.activeSortTitle = key;
      }
    });
  }

  private async adjustPosition(): Promise<void> {
    var element = document.getElementsByTagName('ion-popover');
    if (element[0]) {
      var wrapperOpen = await Utils.getChildElement(element[0].shadowRoot.childNodes, 1);
      var content = await Utils.getChildElement(wrapperOpen.childNodes, 1);
      var contentStyle: CSSStyleDeclaration = content['style'];
      contentStyle.transformOrigin = 'right top';
      contentStyle.left = '';
      contentStyle.top = this.navParams.get('headerHeight') + 'px';
      if (Utils.getBrowser() === Browser.Chrome) {
        contentStyle.right = '10px';
      } else {
        contentStyle.right = (this.navParams.get('margin') + 10) + 'px';
      }
    } else {
      // Wait until element is loaded
      setTimeout(async () => {
        await this.adjustPosition();
      }, 10);
    }
  }
}