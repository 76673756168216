// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itemInput {
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
}

ion-list[inset=true][lines=none] ion-item {
  --border-width: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/signature/location-recipient/location-recipient.component.scss"],"names":[],"mappings":"AAAA;EACI,kHAAA;AACJ;;AACA;EACI,4BAAA;AAEJ","sourcesContent":[".itemInput {\n    border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));\n}\nion-list[inset=\"true\"][lines=\"none\"] ion-item {\n    --border-width: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
