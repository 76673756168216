// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smaller-image {
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto;
}

.itemInput {
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
}

.invalid {
  border-bottom: 1px solid var(--ion-color-danger) !important;
}

h3 {
  text-align: left !important;
  padding-left: 16px;
}

.amountsize {
  --ion-item-background: transparent !important;
  padding: 0% !important;
  display: grid !important;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.checkbox {
  --padding-start: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/general/modal/modal.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;AACJ;;AACA;EACI,kHAAA;AAEJ;;AAAA;EACI,2DAAA;AAGJ;;AADA;EACI,2BAAA;EACA,kBAAA;AAIJ;;AAFA;EACI,6CAAA;EACA,sBAAA;EACA,wBAAA;EACA,uBAAA;EACA,qBAAA;EACA,mBAAA;AAKJ;;AAHA;EACI,+BAAA;AAMJ","sourcesContent":[".smaller-image {\n    width: 80px;\n    height: 80px;\n    display: block;\n    margin: 0 auto;\n}\n.itemInput {\n    border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));\n}\n.invalid {\n    border-bottom: 1px solid var(--ion-color-danger) !important;\n}\nh3 {\n    text-align: left !important;\n    padding-left: 16px;\n}\n.amountsize {\n    --ion-item-background: transparent !important;\n    padding: 0% !important;\n    display: grid !important;\n    justify-content: center;\n    align-content: center;\n    align-items: center;\n}\n.checkbox {\n    --padding-start: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
