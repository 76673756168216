import { Component } from '@angular/core';
import { Platform, ModalController, NavParams } from '@ionic/angular';
import { StorageService } from '../../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Utils } from '../../../../app/utils/utils';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';



@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent {
  private pageTrace: PerformanceTrace;

  public image: string;
  public imageBefore: string;
  public cropMode: boolean;
  public cropImgPreview: any = '';

  constructor(
    public translate: TranslateService,
    private platform: Platform,
    public modalCtrl: ModalController,
    private navParams: NavParams,
    private storageService: StorageService,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.pageTrace = trace(this.performance, ImageModalComponent.name);
  }

  public async ionViewWillEnter(): Promise<void> {
    await this.storageService.set('component', ImageModalComponent.name);
    logEvent(this.analytics, 'screen_view');
    try { this.pageTrace.start(); } catch (error) { }

    this.image = this.navParams.data.image;
    this.imageBefore = this.navParams.data.image;
    this.cropMode = this.navParams.data.cropMode;
  }

  public async ionViewWillLeave(): Promise<void> {
    try { this.pageTrace.stop(); } catch (error) { }
  }

  public cancel(): void {
    this.modalCtrl.dismiss();
  }

  public done(): void {
    this.modalCtrl.dismiss({
      image: this.image
    });
  }

  public crop(): void {
    this.imageBefore = this.image;
    this.image = this.cropImgPreview;
  }

  public reset(): void {
    this.image = this.imageBefore;
  }

  public cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
  }

  public getDynamicIdentifier(prefix: string, key: string): string {
    return Utils.getDynamicIdentifier(prefix, key);
  }
}
