import { Injectable, inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  Auth, User, UserCredential, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, AuthCredential, linkWithCredential
  , signInWithPopup, signInWithCredential, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification
  , sendPasswordResetEmail, fetchSignInMethodsForEmail, authState
} from '@angular/fire/auth';
import {
  Firestore, deleteField, updateDoc, Timestamp, setDoc, doc, getDocFromServer, getDocFromCache, DocumentSnapshot, DocumentData, waitForPendingWrites
} from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { environment } from '../../../environments/environment';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { User as DbUser } from '../../interfaces/database/user';
import { Client } from '../../interfaces/database/client';
import { NetworkService } from '../../services/network.service';
import { default as PackageInfo } from '../../../../package.json';
import { StorageService } from '../../services/storage.service';
import { Client as ClientEnum } from '../../enums/client.enum';
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { CryptoService } from '../../services/crypto.service';

interface LinkedProfiles {
  google: boolean;
  apple: boolean;
  password: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public linkedProfiles: LinkedProfiles;
  public currentUserId: string;
  public currentUser: DbUser;
  public currentClientMapping: Client;
  public authStateSubscription: Subscription;
  private networkSubscription: Subscription;
  public client: typeof ClientEnum = ClientEnum;
  private authState$: Observable<User>;

  constructor(
    private platform: Platform,
    private appVersion: AppVersion,
    private networkService: NetworkService,
    private storageService: StorageService,
    private firestore: Firestore,
    private auth: Auth,
    private cryptoService: CryptoService
  ) {
    this.linkedProfiles = {} as LinkedProfiles;
    this.authState$ = authState(this.auth);
    this.authStateSubscription = this.authState$.subscribe((user: User) => this.onUserStateChange(user));
  }

  ngOnDestroy(): void {
    this.authStateSubscription.unsubscribe();
    this.networkSubscription.unsubscribe();
  }

  public onUserStateChange(user: User): void {
    if (user) {
      this.currentUserId = user.uid;
      for (const provider of user.providerData) {
        if (provider.providerId === GoogleAuthProvider.PROVIDER_ID) {
          this.linkedProfiles.google = true;
        } else if (provider.providerId === 'apple.com') {
          this.linkedProfiles.apple = true;
        } else {
          this.linkedProfiles.password = true;
        }
      }
    } else {
      delete this.currentUserId;
      this.linkedProfiles = {} as LinkedProfiles;
    }
  }

  public async loadCurrentUser(): Promise<any> {
    if (this.currentUserId !== undefined) {
      if (this.networkService.isOnline() && this.networkService.isMinBandwidth()) {
        await waitForPendingWrites(this.firestore);
      }

      const currentUserRef = doc(this.firestore, 'users', this.currentUserId);
      let userObj: Promise<DocumentSnapshot<DocumentData>> = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocFromServer(currentUserRef) : getDocFromCache(currentUserRef);

      if (this.networkService.isOnline() && this.networkService.isMinBandwidth()) {
        await waitForPendingWrites(this.firestore);
      }

      return userObj.then(async user => {
        const $key = user.id;
        const userData = user.data();
        const data = { $key, ...userData as {} } as DbUser;

        this.cryptoService.decryptObj(data).then(async () => {
          this.currentUser = data;
          await this.storageService.set('currentUser', data);
        });
      }).catch(async error => {
        // Error while getting current user from firebase server / cache -> Getting current user from local storage
        this.currentUser = await this.storageService.get('currentUser');
      });
    }
  }

  public async loadClientMapping(client: string): Promise<any> {
    if (this.currentUserId !== undefined) {
      this.currentClientMapping = undefined;

      const clientRef = doc(this.firestore, 'mappings', 'clients');

      const clientObj = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocFromServer(clientRef) : getDocFromCache(clientRef);

      return clientObj.then(async clientData => {
        const $key = client;
        const data = { $key, ...clientData.data()[client] };
        this.currentClientMapping = data;
        await this.storageService.set('currentClientMapping', data);
      }).catch(async () => {
        // Error while getting client mapping from firebase server / cache -> Getting client mapping from local storage
        this.currentClientMapping = await this.storageService.get('currentClientMapping');
      });
    }
  }

  public loginWithGoogleGeneral(): Promise<UserCredential> {
    return signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  public loginWithGoogleCapacitor(): Promise<any> {
    GoogleAuth.initialize({
      clientId: environment.webClientId,
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
    return GoogleAuth.signIn();
  }

  public loginWithFacebookGeneral(): Promise<UserCredential> {
    return signInWithPopup(this.auth, new FacebookAuthProvider());
  }

  public loginWithAppleGeneral(): Promise<UserCredential> {
    return signInWithPopup(this.auth, new OAuthProvider('apple.com'));
  }

  public async loginWithAppleCapacitor(): Promise<SignInWithAppleResponse> {
    let options: SignInWithAppleOptions = {
      clientId: 'com.thumbify.app',
      redirectURI: '',
      scopes: 'email name'
    };
    return await SignInWithApple.authorize(options);
  }

  public loginWithCredential(credential: AuthCredential): Promise<UserCredential> {
    return signInWithCredential(this.auth, credential);
  }

  public loginWithEmail(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  public signup(email: string, password: string): Promise<void> {
    return new Promise((promise, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password).then(async () => {
        sendEmailVerification(this.auth.currentUser).then(() => {
          promise();
        }).catch((error: any) => {
          reject(error);
        });
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  public resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  public getProviderForEmail(email: string): Promise<string[]> {
    return fetchSignInMethodsForEmail(this.auth, email);
  }

  public async linkWithEmailAndLogin(email: string, password: string, credentials: AuthCredential):
    Promise<void> {
    const loginData = await this.loginWithEmail(email, password);
    await linkWithCredential(loginData.user, credentials);
  }

  public linkWithGoogleAndLogin(credentials: AuthCredential): Promise<void> {
    if (this.platform.is('capacitor') && this.platform.ready()) {
      return this.linkWithGoogleAndLoginCapacitor(credentials);
    } else {
      return this.linkWithGoogleAndLoginGeneral(credentials);
    }
  }

  public async linkWithGoogleAndLoginCapacitor(credentials: AuthCredential): Promise<void> {
    const success = await this.loginWithGoogleCapacitor();
    const token = success.authentication.idToken;
    const googleCredential = GoogleAuthProvider.credential(token, null);
    var success2 = await this.loginWithCredential(googleCredential);
    var photoUrl = await this.getPhotoUrl(success2, FacebookAuthProvider.PROVIDER_ID);
    var linkData = await linkWithCredential(success2.user, credentials);
    await this.saveUserWithSocialLogin(linkData.user.email, photoUrl);
  }

  public async linkWithGoogleAndLoginGeneral(credentials: AuthCredential): Promise<void> {
    const success = await this.loginWithGoogleGeneral();
    await linkWithCredential(success.user, credentials);
    await this.saveUserWithSocialLogin(success.user.email, success.user.photoURL);
  }

  public linkWithAppleAndLogin(credentials: AuthCredential): Promise<void> {
    if (this.platform.is('capacitor') && this.platform.ready()) {
      return this.linkWithAppleAndLoginCapacitor(credentials);
    } else {
      return this.linkWithAppleAndLoginGeneral(credentials);
    }
  }

  public async linkWithAppleAndLoginCapacitor(credentials: AuthCredential): Promise<void> {
    const success = await this.loginWithAppleCapacitor();
    const token = success.response.identityToken;
    const appleCredential = new OAuthProvider('apple.com').credential({ idToken: token });
    var success2 = await this.loginWithCredential(appleCredential);
    var linkData = await linkWithCredential(success2.user, credentials);
    await this.saveUserWithSocialLogin(linkData.user.email);
  }

  public async linkWithAppleAndLoginGeneral(credentials: AuthCredential): Promise<void> {
    const success = await this.loginWithAppleGeneral();
    await linkWithCredential(success.user, credentials);
    await this.saveUserWithSocialLogin(success.user.email, success.user.photoURL);
  }

  public async saveUserWithSocialLogin(email: string, photoUrl?: string): Promise<void> {
    var currentUser = this.auth.currentUser;
    //await currentUser.reload();
    const userRef = doc(this.firestore, 'users', currentUser.uid);

    await setDoc(userRef, {
      email,
      profilePicture: photoUrl === undefined || photoUrl === null || photoUrl === '' ? deleteField() : photoUrl,
      loginDate: Timestamp.fromDate(new Date()),
    }, { merge: true });
  }

  public async saveUserWithEmailLogin(email: string): Promise<void> {
    var currentUser = this.auth.currentUser;
    //await currentUser.reload();
    const userRef = doc(this.firestore, 'users', currentUser.uid);
    return await setDoc(userRef, {
      email,
      loginDate: Timestamp.fromDate(new Date()),
    }, { merge: true });
  }

  public getPhotoUrl(userData: UserCredential, providerId: string): Promise<string> {
    return new Promise(promise => {
      try {
        if (userData.user.providerData.length > 1) {
          userData.user.providerData.forEach((element: any) => {
            if (element.providerId === providerId) {
              promise(element.photoURL);
            }
          });
        } else {
          promise(userData.user.photoURL);
        }
      } catch (error) {
        promise('');
      }
    });
  }

  public async saveAppVersion(): Promise<void> {
    var currentUser = this.auth.currentUser;
    const userRef = doc(this.firestore, 'users', currentUser.uid);
    var currentVersion = this.platform.is('capacitor') ? parseInt((await this.appVersion.getVersionNumber()).replace(/\./g, '')) : PackageInfo.version.replace(/\./g, '');
    await updateDoc(userRef, {
      appVersion: currentVersion
    });
  }

  public getCostCenters(): Array<{ id: string, description: string }> {
    let costCenters: Array<{ id: string, description: string }>;

    if (!this.currentUser.client || this.currentUser.client === this.client.Free || this.currentUser.client === this.client.Basic || this.currentUser.client === this.client.Advance) {
      if (this.currentUser.costCenters) {
        costCenters = Object.assign([], this.currentUser.costCenters);
      } else {
        costCenters = new Array<{ id: undefined, description: undefined }>();
      }
    } else {
      if (this.currentClientMapping.costCenters) {
        costCenters = Object.assign([], this.currentClientMapping.costCenters);
      } else {
        costCenters = new Array<{ id: undefined, description: undefined }>();
      }
    }

    return costCenters;
  }

  public getCostCenterNumber(): number {
    var documentNumber: number | undefined;
    if (!this.currentUser.client || this.currentUser.client === this.client.Free || this.currentUser.client === this.client.Basic || this.currentUser.client === this.client.Advance) {
      documentNumber = this.currentUser.costCenterNumber;
    } else {
      documentNumber = this.currentClientMapping.costCenterNumber;
    }
    return !documentNumber ? 0 : documentNumber;
  }

  public getCostCenterNumberPrefix(): string {
    var documentNumberPrefix: string;
    if (!this.currentUser.client || this.currentUser.client === this.client.Free || this.currentUser.client === this.client.Basic || this.currentUser.client === this.client.Advance) {
      documentNumberPrefix = this.currentUser.costCenterNumberPrefix;
    } else {
      documentNumberPrefix = this.currentClientMapping.costCenterNumberPrefix;
    }
    return !documentNumberPrefix ? new Date().getFullYear().toString() : documentNumberPrefix;
  }

  public async cacheCurrentObjects(): Promise<void> {
    await this.storageService.set('currentUser', this.currentUser);
    await this.storageService.set('currentClientMapping', this.currentClientMapping);
  }

  public async clearStorageCache(): Promise<void> {
    await this.storageService.remove('currentUser');
    await this.storageService.remove('currentClientMapping');
    await this.storageService.remove('currentDocumentMapping');
  }

  public async setCurrency(currency: string): Promise<void> {
    if (!this.currentUser) { return; }

    var currentUser = this.auth.currentUser;
    const userRef = doc(this.firestore, 'users', currentUser.uid);
    await updateDoc(userRef, {
      currency
    });
  }

  public async setLanguage(language: string): Promise<void> {
    if (!this.currentUser) { return; }

    var currentUser = this.auth.currentUser;
    const userRef = doc(this.firestore, 'users', currentUser.uid);
    await updateDoc(userRef, {
      language
    });
  }

  public async setDarkMode(darkMode: boolean): Promise<void> {
    if (!this.currentUser) { return; }

    var currentUser = this.auth.currentUser;
    const userRef = doc(this.firestore, 'users', currentUser.uid);
    await updateDoc(userRef, {
      darkMode
    });
  }
}
