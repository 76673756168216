export class StringHelpers {
    static padString(num: number, size: number): string {
        let s = num + '';
        while (s.length < size) { s = '0' + s; }
        return s;
    }

    static capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
}
