import { Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FileType } from '../enums/file-type.enum';
import { NetworkService } from '../services/network.service';
import { Utils } from '../utils/utils';
import {
  Firestore, Timestamp, arrayRemove, arrayUnion, doc, setDoc, deleteField
} from '@angular/fire/firestore';
import { Storage, ref, getDownloadURL, deleteObject, uploadBytesResumable, UploadTask, StorageReference, UploadMetadata } from '@angular/fire/storage';
import { Client } from '../enums/client.enum';
import { ClientLogos } from '../interfaces/config/client';
import { NgxImageCompressService } from "ngx-image-compress";
import { Directory, FileInfo, Filesystem } from '@capacitor/filesystem';
import { User } from '../interfaces/database/user';

const IMAGE_QUALITY = 15;
const IMAGE_QUALITY_DOCUMENTSCANNER = 4.0;
const FIREBASE_MAX_LENGTH = 1048487;

@Injectable({
  providedIn: 'root'
})
export class ImageService implements OnDestroy {
  private imageCache: Map<string, string> = new Map<string, string>();
  private logos: { de: string, en: string };
  public client: typeof Client = Client;
  private uploadTasks: Array<
    {
      id: string
      , objNumber: string
      , objRef: StorageReference
      , objTitle: string
      , objType: string
      , percentage: number
      , bytesTransferred: number
      , totalBytes: number
      , task: UploadTask
      , isUploading: boolean
      , isUploaded: boolean
      , data: {
        objId: string
        , objType: string
        , image: string
        , url: string
        , additionalObj: any
        , signatureObj?: { twoSignatures: boolean, location: string, date: Timestamp, issuer?: boolean }
        , fieldName: string
        , currentObj: any
        , isArray: boolean
        , service?: any
      }
    }>;

  constructor(
    private networkService: NetworkService,
    private platform: Platform,
    private imageCompress: NgxImageCompressService,
    private firestore: Firestore,
    private storage: Storage
  ) {
    this.uploadTasks = new Array
      <{
        id: string
        , objNumber: string
        , objRef: StorageReference
        , objTitle: string
        , objType: string
        , percentage: number
        , bytesTransferred: number
        , totalBytes: number
        , task: UploadTask
        , isUploading: boolean
        , isUploaded: boolean
        , data: {
          objId: string
          , objType: string
          , image: string
          , url: string
          , additionalObj: any
          , signatureObj?: { twoSignatures: boolean, location: string, date: Timestamp, issuer?: boolean }
          , fieldName: string
          , currentObj: any
          , isArray: boolean
          , service?: any
        }
      }>();
  }

  ngOnDestroy(): void {
    this.clearImageCache();
  }

  public async downscaleImage(dataUrl: string, isDocumentScanner: boolean, quality?: number) {
    let image = await this.getImageAsElement(dataUrl);
    image.src = dataUrl;
    let width = image.width;
    let height = image.height;

    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    let ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);
    let newDataUrl = canvas.toDataURL('image/jpeg', quality === undefined ? (isDocumentScanner ? (this.getDocumentScannerQuality() / 10) : (this.getImageQuality() / 100)) : quality / 100);

    image = undefined;
    canvas = undefined;

    return newDataUrl;
  }

  public async getImageFromUrl(url: string, fileType?: FileType): Promise<string> {
    if (!url) {
      return url;
    }

    var image = '';
    if (url.includes('https://')) {
      if (this.isFirebaseStorageUrl(url)) {
        image = await this.getImageFromFirebaseStorage(url, fileType);
      } else {
        image = await this.getDataFromExternalUrl(url);
      }
      this.cacheImage(url, image);
    } else {
      image = url;
    }
    return image;
  }

  public getImageQuality(): number {
    return IMAGE_QUALITY;
  }

  public getDocumentScannerQuality(): number {
    return IMAGE_QUALITY_DOCUMENTSCANNER;
  }

  public deleteImageFromFirebaseStorage(imageUrl: string): void {
    if (imageUrl.includes('https://') && this.networkService.isOnline() && this.isFirebaseStorageUrl(imageUrl)) {
      const imageRef = ref(this.storage, imageUrl);
      deleteObject(imageRef);
    } else {
      return;
    }
  }

  public getImageCache(): Map<string, string> {
    return this.imageCache;
  }

  public clearImageCache(): void {
    this.imageCache = new Map<string, string>();
  }

  public async cacheImage(url: string, image: string): Promise<void> {
    if (this.networkService.isOnline() && this.networkService.isMinBandwidth()) {
      if (url && url.includes('https://') && this.imageCache[url] === undefined) {
        this.imageCache[url] = image;
      }
    }
  }

  public deleteSingleRemovedImageFromStorage(oldImage: any): void {
    if (!oldImage) {
      return;
    } else {
      this.deleteImageFromFirebaseStorage(oldImage);
    }
  }

  public async loadLogos(clientLogos: ClientLogos, currentUser: User): Promise<any> {
    let logoDe: any, logoEn: any;
    var client = !currentUser.client ? this.client.Basic : currentUser.client;

    if (this.imageCache[clientLogos.de] !== undefined && this.imageCache[clientLogos.en] !== undefined) {
      this.logos = { de: this.imageCache[clientLogos.de], en: this.imageCache[clientLogos.en] };
    } else {
      const logoDeRef = ref(this.storage, 'logos/' + client + '/' + clientLogos.de);
      const logoEnRef = ref(this.storage, 'logos/' + client + '/' + clientLogos.en);

      var url = await getDownloadURL(logoDeRef);
      var url2 = await getDownloadURL(logoEnRef);

      logoDe = await this.getDataFromExternalUrl(url);
      this.cacheImage(url, logoDe);
      logoEn = await this.getDataFromExternalUrl(url2);
      this.cacheImage(url2, logoEn);
      this.logos = { de: logoDe, en: logoEn };
    }
  }

  public async getLogos(clientLogos: ClientLogos, currentUser: User): Promise<any> {
    if (this.logos) {
      return this.logos;
    } else {
      this.logos = await this.loadLogos(clientLogos, currentUser);
      return this.logos;
    }
  }

  public async deleteFilesFromLocalStorage(): Promise<void> {
    if (this.platform.is('capacitor') && this.platform.ready() && !this.platform.is('mobileweb')) {
      let directory = this.platform.is('ios') ? Directory.Documents : Directory.Data;
      var readDirResult = await Filesystem.readdir({
        directory,
        path: ''
      });
      if (readDirResult.files?.length === 0) { return; }

      readDirResult.files.forEach((f: FileInfo) => {
        Filesystem.deleteFile({
          path: f.uri,
          directory
        });
      });
    }
  }

  public getUploadTasks(): Array<
    {
      id: string
      , objNumber: string
      , objRef: StorageReference
      , objTitle: string
      , objType: string
      , percentage: number
      , bytesTransferred: number
      , totalBytes: number
      , task: UploadTask
      , isUploading: boolean
      , isUploaded: boolean
      , data: {
        objId: string
        , objType: string
        , image: string
        , url: string
        , additionalObj: any
        , signatureObj?: { twoSignatures: boolean, location: string, date: Timestamp, issuer?: boolean }
        , fieldName: string
        , currentObj: any
        , isArray: boolean
        , service?: any
      }
    }> {
    return this.uploadTasks;
  }

  public removeUploadTask(uploadTask: {
    id: string
    , objNumber: string
    , objRef: StorageReference
    , objType: string
    , percentage: number
    , bytesTransferred: number
    , totalBytes: number
    , task: UploadTask
    , isUploading: boolean
    , isUploaded: boolean
    , url: string
  }): void {
    this.uploadTasks.splice(this.uploadTasks.indexOf(this.uploadTasks.filter(u => u.id === uploadTask.id)[0]), 1);
  }

  public async handleMultipleStorageUpload(newImages: Array<string>, objKey: string, objNumber: string, objTitle: string, objNode: string, fieldName: string
    , currentObj: any, service: any): Promise<void> {
    if (newImages === undefined || newImages === null || newImages.length === 0) {
      return;
    } else {
      await Utils.asyncForEach(newImages, (image: any) => {
        const newDatetime = new Date().getTime();
        this.handleSingleStorageUpload(image, objKey, objNumber, objTitle, newDatetime.toString(), objNode, fieldName, currentObj, service);
      });
    }
  }

  public handleSingleStorageUpload(image: any, objKey: string, objNumber: string, objTitle: string, suffix: string, objNode: string, fieldName: string
    , currentObj: any, service: any, additionalObj?: any): void {
    if (image === undefined || image === '' || image === null) {
      return null;
    } else {
      this.putImageToFirebaseStorage(objKey + '_' + suffix, objKey, objNumber, objTitle, objNode, objNode, null, image, currentObj, fieldName, true, service
        , undefined, additionalObj);
    }
  }

  public async deleteMultipleRemovedObjsFromStorage(deletedObjs: Array<any>): Promise<void> {
    if (deletedObjs === undefined || deletedObjs.length === 0) {
      return;
    } else {
      await Utils.asyncForEach(deletedObjs, async (deletedObj: any) => {
        if (typeof deletedObj === 'string') {
          this.deleteSingleRemovedImageFromStorage(deletedObj);
        } else {
          if (deletedObj.image !== undefined && deletedObj.image !== null && deletedObj.image !== '') {
            this.deleteSingleRemovedImageFromStorage(deletedObj.image);
          }
        }
      });
    }
  }

  public async putImageToFirebaseStorage(storageId: string, objId: string, objNumber: string, objTitle: string, objType: string, subfolder: string, furtherSubfolder: string, image: string
    , currentObj: any, fieldName: string, isArray: boolean
    , service: any, currentUser: User, signatureObj?: { twoSignatures: boolean, location: string, date: Timestamp, issuer?: boolean }
    , additionalObj?: any): Promise<void> {
    image = image.toString();

    if (image === undefined || image === '' || image.includes('https://')) {
      return;
    } else {
      if (signatureObj !== undefined) {
        if (signatureObj.twoSignatures) {
          await this.handleSavingTwoSignatures(image, currentObj, signatureObj.location, signatureObj.date, signatureObj.issuer, currentUser);
        } else {
          await this.handleSavingOneSignature(image, currentObj, signatureObj.location, signatureObj.date, currentUser);
        }
      }

      let objRef: StorageReference;

      if (furtherSubfolder !== undefined && furtherSubfolder !== null) {
        objRef = ref(this.storage, subfolder + '/' + furtherSubfolder + '/' + storageId);
      } else {
        objRef = ref(this.storage, subfolder + '/' + storageId);
      }

      var replacedImage = image.replace('data:image/jpeg;base64,', '').
        replace('data:image/png;base64,', '')
        .replace('data:application/pdf;base64,', '');

      var metadata: UploadMetadata = {};
      metadata.customMetadata = {
        creationDate: new Date().toLocaleString('de-DE')
      }

      const fileToUpload = await Utils.base64ToArrayBuffer(replacedImage);
      var uploadTask = uploadBytesResumable(objRef, fileToUpload, metadata);

      this.addUploadTask(uploadTask, objId, objNumber, objType, objRef, objTitle, fieldName, isArray, image, currentObj, additionalObj, signatureObj, service);
    }
  }

  public async handleSavingOneSignature(signatureValue: string, obj: any, location: string, date: Timestamp, currentUser: User): Promise<void> {
    let issuerSignatureIndex = null;
    if (obj.signatures !== undefined && obj.signatures !== '') {
      issuerSignatureIndex = this.getSignatureIndex(obj, 'issuer');
    }
    if (issuerSignatureIndex !== null) {
      var effectedSignatureIndex: any;
      effectedSignatureIndex = issuerSignatureIndex;

      obj.signatures[effectedSignatureIndex].value = signatureValue;
      obj.signatures[effectedSignatureIndex].location = location === undefined ? deleteField() : location;
      obj.signatures[effectedSignatureIndex].date = date === undefined
        ? deleteField() : date;
      obj.signatures[effectedSignatureIndex].creationDate = Timestamp.fromDate(new Date());

      if (obj.signatures[effectedSignatureIndex].value.includes('https://')) {
        if (obj.signatures[effectedSignatureIndex].value !== signatureValue) {
          this.deleteImageFromFirebaseStorage(obj.signatures[effectedSignatureIndex].value);
        }
      }
    } else {
      obj.completionDate = Timestamp.fromDate(new Date());
      obj.signatures = new Array(
        {
          userId: currentUser.$key,
          partner: 'issuer',
          value: signatureValue,
          location: location === undefined ? deleteField() : location,
          date: date === undefined ? deleteField() : date,
          creationDate: Timestamp.fromDate(new Date())
        }
      );
    }
  }

  public async handleSavingTwoSignatures(signatureValue: string, obj: any, location: string, date: Timestamp
    , issuer: boolean, currentUser: User): Promise<void> {
    let issuerSignatureIndex = null;
    let recipientSignatureIndex = null;
    if (obj.signatures !== undefined && obj.signatures !== '') {
      issuerSignatureIndex = this.getSignatureIndex(obj, 'issuer');
      recipientSignatureIndex = this.getSignatureIndex(obj, 'recipient');
    }
    if (issuer && issuerSignatureIndex !== null
      ||
      !issuer && recipientSignatureIndex !== null) {
      var effectedSignatureIndex: any;
      if (issuer) {
        effectedSignatureIndex = issuerSignatureIndex;
      } else {
        effectedSignatureIndex = recipientSignatureIndex;
      }

      obj.signatures[effectedSignatureIndex].value = signatureValue;
      obj.signatures[effectedSignatureIndex].location = location === undefined ? deleteField() : location;
      obj.signatures[effectedSignatureIndex].date = date === undefined
        ? deleteField() : date;
      obj.signatures[effectedSignatureIndex].creationDate = Timestamp.fromDate(new Date());

      if (obj.signatures[effectedSignatureIndex].value.includes('https://')) {
        if (obj.signatures[effectedSignatureIndex].value !== signatureValue) {
          this.deleteImageFromFirebaseStorage(obj.signatures[effectedSignatureIndex].value);
        }
      }
    } else {
      if (obj.signatures !== undefined && obj.signatures !== null &&
        Object.keys(obj.signatures).length > 0) {
        obj.completionDate = Timestamp.fromDate(new Date());
        obj.signatures.push(
          {
            userId: currentUser.$key,
            partner: issuer ? 'issuer' : 'recipient',
            value: signatureValue,
            location: location === undefined ? deleteField() : location,
            date: date === undefined ? deleteField() : date,
            creationDate: Timestamp.fromDate(new Date())
          }
        );
      } else {
        obj.signatures = new Array(
          {
            userId: currentUser.$key,
            partner: issuer ? 'issuer' : 'recipient',
            value: signatureValue,
            location: location === undefined ? deleteField() : location,
            date: date === undefined ? deleteField() : date,
            creationDate: Timestamp.fromDate(new Date())
          }
        );
      }
    }
  }

  public getDataFromExternalUrl(url: string): Promise<string> {
    return new Promise(promise => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          promise(reader.result.toString());
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.send();
    });
  }

  public async checkFirebaseMaxLength(images: Array<string>): Promise<Array<string>> {
    if (images?.length === 0) {
      return images;
    }

    const reducer = (previousValue: any, currentValue: any) => previousValue + currentValue;
    let totalLength = images.map(i => i.length).reduce(reducer);

    if (totalLength >= FIREBASE_MAX_LENGTH) {
      let newImages = new Array<string>();
      await Utils.asyncForEach(images, async (image: string) => {
        if (image.includes('https://')) {
          newImages.push(image);
        } else {
          let downscaledImage = await this.imageCompress.compressFile(image, 1);
          newImages.push(downscaledImage);
        }
      });

      let newTotalLength = newImages.map(i => i.length).reduce(reducer);
      if (newTotalLength >= FIREBASE_MAX_LENGTH) {
        return await this.checkFirebaseMaxLength(newImages);
      } else {
        return newImages;
      }
    } else {
      return images;
    }
  }

  public isFirebaseStorageUrl(url: string): boolean {
    if (url !== undefined && url !== null && url !== '' && url.includes('https://firebasestorage')) {
      return true;
    } else {
      return false;
    }
  }

  private async urltoFile(url: string): Promise<globalThis.File> {
    var response = await fetch(url);
    var arrayBuffer = await response.arrayBuffer();
    var file = new globalThis.File([arrayBuffer], 'Filename', { type: 'image/jpeg' });
    return file;
  }

  private getImageAsElement(dataUrl: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = dataUrl;
      image.onload = () => {
        resolve(image);
      };
    });
  }

  private getImageFromFirebaseStorage(imageUrl: string, fileType?: FileType): Promise<any> {
    return new Promise(async promise => {
      if (!imageUrl || imageUrl.includes('base64')) {
        promise(imageUrl);
      } else {
        var validUrl = await this.checkUrl(imageUrl);
        if (validUrl) {
          const httpsReference = ref(this.storage, imageUrl);
          var url = await getDownloadURL(httpsReference);
          const xhr = new XMLHttpRequest();
          let base64 = '';
          xhr.responseType = 'arraybuffer';
          xhr.onloadend = () => {
            if (fileType && fileType === FileType.Pdf) {
              promise('data:application/pdf;base64,' + base64);
            } else {
              promise('data:image/png;base64,' + base64);
            }
          };
          xhr.onload = () => {
            const uInt8Array = new Uint8Array(xhr.response);
            let i = uInt8Array.length;
            const binaryString = new Array(i);
            while (i--) {
              binaryString[i] = String.fromCharCode(uInt8Array[i]);
            }
            const data = binaryString.join('');
            base64 = window.btoa(data);
          };
          xhr.open('GET', url);
          xhr.send();
        } else {
          promise(null);
        }
      }
    });
  }

  private checkUrl(url: string): Promise<boolean> {
    return new Promise(promise => {
      const request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.onreadystatechange = () => {
        if (request.readyState === 4) {
          if (request.status === 404 || request.status === 403) { }
          promise(false);
        } else {
          promise(true);
        }
      };
      request.send();
    });
  }

  private addUploadTask(uploadTask: UploadTask, objId: string, objNumber: string, objType: string, objRef: StorageReference, objTitle: string
    , fieldName: string, isArray: boolean, image: string, currentObj: any
    , additionalObj: any, signatureObj?: { twoSignatures: boolean, location: string, date: Timestamp, issuer?: boolean }, service?: any): void {

    var id = Date.now().toString();

    this.uploadTasks.push({
      id: id
      , objNumber: objNumber
      , objRef: objRef
      , objTitle: objTitle
      , objType: objType.substring(0, objType.length - 1)
      , percentage: 0
      , bytesTransferred: 0
      , totalBytes: 0
      , task: uploadTask
      , isUploading: true
      , isUploaded: false
      , data: {
        objId: objId
        , objType: objType
        , image
        , url: null
        , additionalObj
        , signatureObj
        , fieldName
        , currentObj
        , isArray
        , service
      }
    });

    uploadTask.on('state_changed',
      (snapshot) => {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.uploadTasks.filter(u => u.id === id)[0].percentage = percentage;

        this.uploadTasks.filter(u => u.id === id)[0].bytesTransferred = snapshot.bytesTransferred;
        this.uploadTasks.filter(u => u.id === id)[0].totalBytes = snapshot.totalBytes;
      },
      () => { },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          if (url) {
            this.uploadTasks[this.uploadTasks.indexOf(this.uploadTasks.filter(u => u.id === id)[0])].isUploading = false;
            this.uploadTasks[this.uploadTasks.indexOf(this.uploadTasks.filter(u => u.id === id)[0])].isUploaded = true;

            this.uploadTasks[this.uploadTasks.indexOf(this.uploadTasks.filter(u => u.id === id)[0])].data.url = url;

            if (this.uploadTasks.length === 1
              ||
              this.uploadTasks.filter(u => u.data.objId === objId)
                .every(u => u.isUploaded)) {
              this.updateDb(objId);
            }
          }
        });
      });
  }

  private async updateDb(objId: string): Promise<void> {
    var replacedImages = new Array<{ oldImage: string, newImage: string }>();
    var correspondingUploadTasks = this.uploadTasks.filter(u => u.data.objId === objId);
    var firstUploadTask = correspondingUploadTasks[0];
    await Utils.asyncForEach(correspondingUploadTasks
      , async (uploadTask: any) => {
        if (uploadTask.data.additionalObj !== undefined) {
          await this.addObjToDb(uploadTask.data.url, uploadTask.data.objId, uploadTask.data.objType, uploadTask.data.fieldName, uploadTask.data.additionalObj, 'image');
        } else if (uploadTask.data.signatureObj !== undefined) {
          await this.addObjToDb(uploadTask.data.url, uploadTask.data.objId, uploadTask.data.objType, uploadTask.data.fieldName
            , uploadTask.data.currentObj['signatures'].filter(s => s.value === uploadTask.data.image)[0], 'value');
        } else {
          if (uploadTask.data.isArray) {
            replacedImages.push({ oldImage: uploadTask.data.image, newImage: uploadTask.data.url });
          } else {
            await this.addImageToDb(uploadTask.data.image, uploadTask.data.url, uploadTask.data.objId, uploadTask.data.objType, uploadTask.data.fieldName, uploadTask.data.isArray);
          }
        }
      });
    if (replacedImages.length > 0) {
      await this.addImagesToDb(replacedImages, firstUploadTask.data.currentObj, objId, firstUploadTask.data.objType, firstUploadTask.data.fieldName);
    }
    await Utils.asyncForEach(correspondingUploadTasks, (task: any) => {
      this.uploadTasks.splice(this.uploadTasks.indexOf(task), 1);
    });
    firstUploadTask.data.service.loadCurrent(firstUploadTask.objType, firstUploadTask.data.objType, objId, true);
  }

  private async addImageToDb(replacedImage: string, image: string, objId: string, objType: string, fieldName: string, isArray: boolean): Promise<void> {
    const dbObjRef = doc(this.firestore, objType, objId);
    if (isArray) {
      if (replacedImage !== null) {
        setDoc(dbObjRef, {
          [fieldName]: arrayRemove(replacedImage)
        }, { merge: true });
      }
      setDoc(dbObjRef, {
        [fieldName]: arrayUnion(image)
      }, { merge: true });
    } else {
      setDoc(dbObjRef, {
        [fieldName]: image
      }, { merge: true });
    }
  }

  private async addImagesToDb(replacedImages: Array<{ oldImage: string, newImage: string }>, currentObj: any, objId: string, objType: string, fieldName: string): Promise<void> {
    const dbObjRef = doc(this.firestore, objType, objId);
    var dbImages = currentObj[fieldName];
    if (dbImages !== undefined && dbImages !== null) {
      await Utils.asyncForEach(replacedImages, (replacedImage: { oldImage: string, newImage: string }) => {
        var index = dbImages.indexOf(replacedImage.oldImage);
        if (index > -1) {
          dbImages[index] = replacedImage.newImage;
        }
      });

      setDoc(dbObjRef, {
        [fieldName]: dbImages
      }, { merge: true });
    } else {
      setDoc(dbObjRef, {
        [fieldName]: replacedImages.map(r => r.newImage)
      }, { merge: true });
    }
  }

  private async addObjToDb(downloadUrl: string, objId: string, objType: string, fieldName: string, obj: any, propertyName: string): Promise<void> {
    const dbObjRef = doc(this.firestore, objType, objId);

    // Remove old obj from array / base64 image string
    setDoc(dbObjRef, {
      [fieldName]: arrayRemove(obj)
    }, { merge: true });
    // Replace old base64 string with URL
    if (downloadUrl !== null) {
      obj[propertyName] = downloadUrl;
    }
    setDoc(dbObjRef, {
      [fieldName]: arrayUnion(obj)
    }, { merge: true });
  }

  private getSignatureIndex(obj: any, partner: string): any {
    if (obj.signatures[0] !== undefined
      && obj.signatures[0].partner === partner) {
      return 0;
    } else if (obj.signatures[1] !== undefined
      && obj.signatures[1].partner === partner) {
      return 1;
    } else {
      return null;
    }
  }
}