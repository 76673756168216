import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NetworkService } from '../../../services/network.service';
import { Product } from '../../../interfaces/database/masterdata';
import { StorageService } from '../../../services/storage.service';
import { Platform } from '@ionic/angular';
import { AuthService } from '../../auth/auth.service';
import { DateHelpers } from '../../../utils/date-helpers';
import { MasterDataService } from '../master-data.service';
import {
  Firestore, Timestamp, arrayRemove, arrayUnion, QuerySnapshot, collection, query, where, getDocsFromServer, getDocsFromCache
  , getDocFromServer, getDocFromCache, doc, setDoc, updateDoc
} from '@angular/fire/firestore';
import { Performance, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { Utils } from '../../../utils/utils';
import { IMasterDataService } from '../../../interfaces/general/imasterdataservice';
import { ImageService } from '../../../services/image.service';
import { Client } from '../../../enums/client.enum';
import { AbstractControl } from '@angular/forms';
import { ConfigService } from '../../../services/config.service';
import { CryptoService } from '../../../services/crypto.service';

const propertiesToSkip = new Array<string>('$key', 'type');

@Injectable({
  providedIn: 'root'
})
export class ProductService implements IMasterDataService, OnDestroy {
  private isOnline: boolean;
  private networkSubscription: Subscription;
  private currentMasterDataObj: Product;
  private currentMasterDataObjs: Array<Product>;
  private productsNode: string = 'products';
  private type: string = 'product';

  constructor(
    private platform: Platform,
    private networkService: NetworkService,
    public authService: AuthService,
    public masterDataService: MasterDataService,
    private imageService: ImageService,
    private storageService: StorageService,
    private configService: ConfigService,
    private cryptoService: CryptoService,
    private firestore: Firestore,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.networkSubscription = this.networkService.isOnlineObservable().subscribe(async (isOnline: boolean) => {
      if (this.isOnline !== undefined && this.isOnline && !isOnline) {
        this.cacheCurrentObjects();
      }

      this.isOnline = isOnline;
    });
  }

  public ngOnDestroy(): void {
    this.networkSubscription.unsubscribe();
  }

  public async loadMasterData(initial: boolean): Promise<void> {
    const t = trace(this.performance, 'loadProducts');
    try { t.start(); } catch (ex) { };
    this.currentMasterDataObjs = new Array<Product>();
    var productsMapping = this.configService.getMasterDataMapping().products;

    if (!initial || (initial && this.networkService.isOnline() && this.networkService.isMinBandwidth())) {
      if (this.authService.currentUser !== undefined) {
        var objects = new Array<Product>();
        var client = this.authService.currentUser.client;
        const productsRef = collection(this.firestore, this.getMasterDataNode());
        var products: Promise<QuerySnapshot<unknown>>;
        if (!client || client === Client.Free || client === Client.Basic || client === Client.Advance) {
          const q = query(productsRef, where('creator', '==', this.authService.currentUser.$key), where('visible', '==', true));
          products = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocsFromServer(q) : getDocsFromCache(q);
        } else {
          const q = query(productsRef, where('client', '==', client), where('visible', '==', true));
          products = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocsFromServer(q) : getDocsFromCache(q);
        }

        return products.then(async productData => {
          objects = objects.concat(productData.docs.filter(a => a.exists)
            .map(action => {
              const $key = action.id;
              const data = { $key, ...action.data() as {}, type: this.getMasterDataType() } as Product;

              if (this.configService.getClientMapping().encryption) {
                this.cryptoService.decryptObj(data).then(() => {
                  return data;
                })
              } else {
                return data;
              }
            }));
          this.currentMasterDataObjs = objects.filter(o => productsMapping.map(m => m.category).includes(o.category));
          await this.storageService.set(this.getMasterDataNode(), objects);
          try { t.stop(); } catch (ex) { };
        }).catch(async () => {
          // Error while getting documents from firebase server / cache -> Getting documents from local storage
          this.currentMasterDataObjs = await this.loadContactsFromStorage();
          try { t.stop(); } catch (ex) { };
          return;
        });
      } else {
        try { t.stop(); } catch (ex) { };
        return;
      }
    } else {
      // Device is offline or min bandwidth isn't available
      this.currentMasterDataObjs = await this.loadContactsFromStorage();
      try { t.stop(); } catch (ex) { };
      return;
    }
  }

  public async loadCurrent(id: string, fromDb?: boolean): Promise<Product> {
    if (!fromDb && (await this.getCurrentMasterDataObjs()).some(p => p.$key === id)) {
      var productObj = (await this.getCurrentMasterDataObjs()).filter(p => p.$key === id)[0];
      this.currentMasterDataObj = productObj;
      this.cacheImages(productObj);
      return productObj;
    } else {
      const currentObjectRef = doc(this.firestore, this.getMasterDataNode(), id);
      var obj = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? await getDocFromServer(currentObjectRef) : await getDocFromCache(currentObjectRef);
      if (!obj.exists) {
        return;
      }
      const $key = obj.id;
      const productObj = { $key, ...obj.data() as {} } as Product;

      if (this.configService.getClientMapping().encryption) {
        await this.cryptoService.decryptObj(productObj);
      }

      this.currentMasterDataObj = productObj;
      var cacheObj = this.currentMasterDataObjs?.filter(p => p.$key === id)[0];
      if (cacheObj !== undefined) {
        this.currentMasterDataObjs[this.currentMasterDataObjs.indexOf(cacheObj)] = productObj;
      }
      this.cacheImages(productObj);
      return productObj;
    }
  }

  public async getCurrentMasterDataObjs(): Promise<Array<Product>> {
    if (!this.currentMasterDataObjs || this.currentMasterDataObjs.length === 0) {
      await this.loadMasterData(true);
      return this.currentMasterDataObjs;
    } else {
      return this.currentMasterDataObjs;
    }
  }

  public getCurrentMasterDataObj(): Product {
    return this.currentMasterDataObj;
  }

  public getMasterDataNode(): string {
    return this.productsNode;
  }

  public async loadContactsFromStorage(): Promise<Array<Product>> {
    var storageObjects = (await this.storageService.get(this.productsNode)) as Array<Product>;
    if (storageObjects) {
      return storageObjects.map(o => {
        o = DateHelpers.convertObjectsToFirestoreTimestamp(o);
        return o;
      });
    }
  }

  public getMasterDataType(): string {
    return this.type;
  }

  public async create(controls: Map<string, AbstractControl>, currency: string, images: Array<string>): Promise<void> {
    const t = trace(this.performance, 'createProduct');
    try { t.start(); } catch (ex) { };

    var encryption = this.configService.getClientMapping().encryption;

    const newProductRef = doc(collection(this.firestore, this.getMasterDataNode()));

    // Add fix properties
    let newObj = {
      client: this.authService.currentUser.client,
      creationDate: Timestamp.fromDate(new Date()),
      creator: this.authService.currentUser.$key,
      images: !images || images.length === 0 ? undefined : images,
      updateUser: this.authService.currentUser.$key,
      visible: true
    };
    if (currency) {
      newObj['currency'] = currency;
    }

    // Add dynamic properties
    controls.forEach((control: AbstractControl, key: string) => {
      if (!propertiesToSkip?.includes(key)
        && !this.platform.is('capacitor')
        && !this.platform.is('cordova')
        && Utils.isArrayControlWithNewImage(control, this.imageService)) {
        newObj[key] = Utils.getArrayControlWithoutNewImage(control, this.imageService);
      } else if (!propertiesToSkip?.includes(key)) {
        newObj[key] = control.value;
      }
    });

    // Remove undefined fields saving
    await Utils.deleteUndefinedProperties(newObj);

    if (encryption) {
      let masterDataProductMapping = this.configService.getMasterDataProductMappingByCategory(newObj['category']);
      let encryptedObj = await this.cryptoService.encryptObj(newObj, masterDataProductMapping);
      setDoc(newProductRef, encryptedObj);
    } else {
      setDoc(newProductRef, newObj);
    }

    await this.loadCurrent(newProductRef.id);

    await this.imageService.handleMultipleStorageUpload(images, newProductRef.id, newProductRef.id, 'MASTERDATA_PRODUCT', this.getMasterDataNode(), 'images'
      , this.getCurrentMasterDataObj(), this);

    try { t.stop(); } catch (ex) { };
  }

  public async update(deletedImages?: Array<string>, newImages?: Array<string>, deletedModals?: Map<string, any>, newModals?: Map<string, any>): Promise<void> {
    const t = trace(this.performance, 'updateProduct');
    try { t.start(); } catch (ex) { };

    var encryption = this.configService.getClientMapping().encryption;

    var currentMasterDataObj = this.getCurrentMasterDataObj();

    const productRef = doc(this.firestore, this.getMasterDataNode(), currentMasterDataObj.$key);

    // Add all properties of the current object
    var updatedObj = {}
    Object.keys(currentMasterDataObj).forEach((key: string) => {
      if (!propertiesToSkip?.includes(key)
        && !this.platform.is('capacitor')
        && !this.platform.is('cordova')
        && Utils.isArrayPropertyWithNewImage(currentMasterDataObj[key], this.imageService)) {
        updatedObj[key] = Utils.getArrayPropertyWithoutNewImage(currentMasterDataObj[key], this.imageService);
      } else if (!propertiesToSkip?.includes(key)) {
        updatedObj[key] = currentMasterDataObj[key];
      }
    });

    // Remove undefined fields saving
    await Utils.deleteUpdatedUndefinedProperties(updatedObj);

    // Add update user
    updatedObj['updateUser'] = this.authService.currentUser.$key;

    if (encryption) {
      let masterDataProductMapping = this.configService.getMasterDataProductMappingByCategory(updatedObj['category']);
      let encryptedObj = await this.cryptoService.encryptObj(updatedObj, masterDataProductMapping);
      updateDoc(productRef, encryptedObj);
    } else {
      updateDoc(productRef, updatedObj);
    }

    // Handle general images
    // Deleted images
    if (deletedImages !== undefined && deletedImages.length > 0) {
      updateDoc(productRef, {
        images: arrayRemove(...deletedImages)
      });
    }
    await this.imageService.deleteMultipleRemovedObjsFromStorage(deletedImages);
    // New images
    if (newImages !== undefined && newImages.length > 0) {
      newImages = await this.imageService.checkFirebaseMaxLength(newImages);
      updateDoc(productRef, {
        images: arrayUnion(...newImages)
      });
    }
    await this.imageService.handleMultipleStorageUpload(newImages, currentMasterDataObj.$key, currentMasterDataObj.number, 'MASTERDATA_PRODUCT'
      , this.getMasterDataNode(), 'images', currentMasterDataObj, this);

    // Handle all modals
    // Deleted modals
    if (deletedModals && deletedModals.size > 0) {
      deletedModals.forEach(async (value: any, key: string) => {
        updateDoc(productRef, {
          [key]: arrayRemove(...value)
        });
        await this.imageService.deleteMultipleRemovedObjsFromStorage(value);
      });
    }
    // New modals
    if (newModals && newModals.size > 0) {
      newModals.forEach(async (value: any, key: string) => {
        updateDoc(productRef, {
          [key]: arrayUnion(...value)
        });
        await this.handleObjAdding(value, this.getMasterDataNode(), currentMasterDataObj.$key, currentMasterDataObj.number, 'MASTERDATA_PRODUCT', key, key);
      });
    }

    await this.loadCurrent(currentMasterDataObj.$key);

    try { t.stop(); } catch (ex) { };
  }

  public async remove(): Promise<void> {
    this.removeWithId(this.getCurrentMasterDataObj());
  }

  public removeWithId(product: Product): void {
    product.visible = false;
    const productRef = doc(this.firestore, this.getMasterDataNode(), product.$key);
    updateDoc(productRef, {
      visible: product.visible
    });
  }

  public async cacheCurrentObjects(): Promise<void> {
    await this.storageService.set(this.getMasterDataNode(), (await this.getCurrentMasterDataObjs()));
  }

  public clearCurrentObj(): void {
    this.currentMasterDataObj = undefined;
  }

  public clearCurrentObjects(): void {
    this.clearCurrentObj();
    this.clearStorageCache();
    this.currentMasterDataObjs = undefined;
  }

  public async cacheImages(product: Product): Promise<void> {
    if (this.networkService.isOnline() && this.networkService.isMinBandwidth()) {
      if (product.images !== undefined && product.images !== null && product.images.length > 0 && product.images.some((image: string) => image !== undefined && image !== null
        && image.includes('https://'))) {
        product.images.forEach(async (image: string) => {
          this.imageService.getImageFromUrl(image);
        });
      }
    }
  }

  public getImage(image: string): string {
    if (this.networkService.isOnline()) {
      return image;
    } else {
      if (this.imageService.getImageCache()[image] !== undefined) {
        return this.imageService.getImageCache()[image];
      } else if (image === undefined || (image !== undefined && image !== null && image !== '' && !image.includes('https://'))) {
        return image;
      } else {
        return null;
      }
    }
  }

  public async clearStorageCache(): Promise<void> {
    await this.storageService.remove(this.getMasterDataNode());
  }

  public addElementToContactHistory(productId: string
    , newHistoryObj: {
      date: Timestamp, description: string, descriptionTitle: string, documentId: string, documentNumber: string
      , documentType: string, result: string
    }): void {
    const masterDataRef = doc(this.firestore, this.productsNode, productId);
    updateDoc(masterDataRef, {
      history: arrayUnion(newHistoryObj)
    });
  }

  public getProductNumber(): number {
    var productNumber: number | undefined;
    if (!this.authService.currentUser.client || this.authService.currentUser.client === Client.Free || this.authService.currentUser.client === Client.Basic
      || this.authService.currentUser.client === Client.Advance) {
      productNumber = this.authService.currentUser.productNumber;
    } else {
      productNumber = this.authService.currentClientMapping.productNumber;
    }
    return productNumber === undefined ? 0 : productNumber;
  }

  public getProductNumberPrefix(): string {
    var productNumberPrefix: string;
    if (!this.authService.currentUser.client || this.authService.currentUser.client === Client.Free || this.authService.currentUser.client === Client.Basic
      || this.authService.currentUser.client === Client.Advance) {
      productNumberPrefix = this.authService.currentUser.productNumberPrefix;
    } else {
      productNumberPrefix = this.authService.currentClientMapping.productNumberPrefix;
    }
    return productNumberPrefix === undefined ? new Date().getFullYear().toString() : productNumberPrefix;
  }

  private async handleObjAdding(newObjects: Array<any>, formNode: string, formId: string, formNumber: string, formTitle: string, arrayType: string, fieldName: string): Promise<void> {
    if (!newObjects || newObjects.length === 0) {
      return;
    } else {
      await Utils.asyncForEach(newObjects, (obj: any) => {
        const newDatetime = new Date().getTime();
        this.imageService.handleSingleStorageUpload(obj.image, formId, formNumber, formTitle, arrayType
          + '_' + newDatetime.toString(), formNode, fieldName, null, this, obj);
      });
    }
  }
}
