import { Component } from '@angular/core';
import { Platform, PopoverController, AlertController, NavParams } from '@ionic/angular';
import { StorageService } from '../../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { FormService } from '../../documents/form/form.service';
import { Utils } from '../../../utils/utils';
import { Browser } from '../../../enums/browser.enum';
import { Form, FormOverviewAmount, FormOverviewLabel } from '../../../interfaces/config/form';
import { ConfigService } from '../../../services/config.service';
import { DateHelpers } from '../../../utils/date-helpers';
import { format } from 'date-fns';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Timestamp } from '@angular/fire/firestore';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app-filter-popover',
  templateUrl: './filter-popover.page.html',
  styleUrls: ['./filter-popover.page.scss'],
})
export class FilterPopoverPage {
  private pageTrace: PerformanceTrace;

  public types: Array<string>;
  public forms: Array<Form>;
  public orderDocuments: Array<string>;
  public isAscSelected: boolean = false;
  public isDescSelected: boolean = false;
  public availableSortCriteria = new Map<string, Array<string>>();
  public activeSortCriteria = new Array<string>();
  public activeSortTitle: string;
  public creationDateFrom: string;
  public creationDateUntil: string;
  public translatedFormTypes = new Array<string>();
  public availableEmployees: Array<{ id: string, name: string }>;
  public visibleEmployeeNames = new Array<string>();
  public creators: Array<string>;
  public language: any;
  public filterPopover: UntypedFormGroup;

  constructor(
    public translate: TranslateService,
    private navParams: NavParams,
    public platform: Platform,
    private popoverController: PopoverController,
    private alertController: AlertController,
    public formService: FormService,
    private configService: ConfigService,
    private authService: AuthService,
    private storageService: StorageService,
    public formBuilder: UntypedFormBuilder,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.pageTrace = trace(this.performance, FilterPopoverPage.name);

    this.filterPopover = formBuilder.group({});
  }

  public async ionViewWillEnter(): Promise<void> {
    var language = await this.storageService.get('language');
    this.language = language.replace('_', '-');

    var darkMode = await this.storageService.get('darkMode');
    if (darkMode !== null) {
      if (!darkMode) {
        document.body.style.setProperty('--ion-card-var', '#ffffff');
      } else {
        this.platform.is('ios')
          ? document.body.style.setProperty('--ion-card-var', '#1c1c1c')
          : document.body.style.setProperty('--ion-card-var', '#1a1b1e');
      }
    } else {
      document.body.style.setProperty('--ion-card-var', '#ffffff');
    }

    await this.storageService.set('component', FilterPopoverPage.name);
    logEvent(this.analytics, 'screen_view');
    try { this.pageTrace.start(); } catch (error) { }

    this.forms = this.configService.getFormsMapping();
    this.orderDocuments = this.navParams.get('orderDocuments');
    if (this.orderDocuments !== undefined) {
      this.types = this.orderDocuments.sort();
    } else {
      this.types = new Array<string>();

      this.forms.forEach((f: Form) => {
        this.types.push(f.type);
      });
    }

    this.getSortCriteria();
    this.setTranslatedFormTypes();
    this.activeSortCriteria = this.navParams.get('activeSortCriteria');
    this.getActiveSortTitle();

    this.isDescSelected = this.navParams.get('orderDesc');
    this.isAscSelected = !this.isDescSelected;

    this.creationDateFrom = DateHelpers.getCalenderPickerMonthYear(this.navParams.get('creationDateFrom'));
    this.creationDateUntil = DateHelpers.getCalenderPickerMonthYear(this.navParams.get('creationDateUntil'));

    await this.adjustPosition();

    this.availableEmployees = this.navParams.get('availableEmployees');
    this.creators = this.navParams.get('creators');
    this.setVisibleEmployeeNames();
  }

  public async ionViewWillLeave(): Promise<void> {
    try { this.pageTrace.stop(); } catch (error) { }
  }

  public getAvailableForms(): Array<Form> {
    var availableForms = new Array<Form>();

    this.forms?.filter((f: Form) => this.types.includes(f.type)).forEach((f: Form) => {
      if (availableForms.some((f2: Form) => f2.type === f.type)) { return; }
      availableForms.push(f);
    });

    return availableForms;
  }

  public getAvailableEmployeeNames(): Array<string> {
    return this.availableEmployees?.map(e => e.name);
  }

  public setVisibleEmployeeNames(): void {
    this.visibleEmployeeNames = new Array<string>();
    if (this.creators?.length > 0) {
      this.visibleEmployeeNames = this.availableEmployees.filter(e => this.creators.includes(e.id)).map(e => e.name);
    } else {
      this.visibleEmployeeNames = new Array<string>();
    }
  }

  public setTranslatedFormTypes(): void {
    this.translatedFormTypes = new Array<string>();
    this.getAvailableForms().forEach((f: Form) => {
      let translation: string;
      this.translate.get(f.translations.title).subscribe((result: string) => translation = result);
      this.translatedFormTypes.push(translation);
    });
  }

  public dateChanged(event: CustomEvent, id: string): void {
    if (event.detail.value === undefined) {
      var element = document.getElementById('dateModal_' + id);
      if (element) {
        (element as HTMLIonModalElement).dismiss();
      }
    }
  }

  public getFormattedString(value: any): string {
    if (!value) { return; }

    var tempValue = value;

    if (typeof value === 'object') {
      tempValue = (<Timestamp>value).toDate();
    }

    var date = new Date(tempValue);

    return format(date, 'MMMM yyyy', { locale: DateHelpers.getLocale(this.language) });
  }

  public async selectEmployees(): Promise<void> {
    var all: string, none: string, ok: string, cancel: string, users: string;
    this.translate.get('BTN_ALL').subscribe((result: string) => all = result);
    this.translate.get('BTN_NONE').subscribe((result: string) => none = result);
    this.translate.get('OK').subscribe((result: string) => ok = result);
    this.translate.get('BTN_CANCEL').subscribe((result: string) => cancel = result);
    this.translate.get('USER').subscribe((result: string) => users = result);

    var buttons = [
      {
        text: all,
        cssClass: 'all-none-button',
        handler: () => {
          alert.inputs = alert.inputs.map((checkbox) => {
            checkbox.checked = true;
            return checkbox;
          });
          return false;
        }
      }, {
        text: none,
        cssClass: 'all-none-button',
        handler: () => {
          alert.inputs = alert.inputs.map((checkbox) => {
            checkbox.checked = false;
            return checkbox;
          });
          return false;
        }
      }, {
        text: ok,
        handler: (data) => {
          this.creators = data;
          this.setVisibleEmployeeNames();
        }
      },
      , {
        text: cancel,
        role: 'cancel',
      }
    ];

    buttons = buttons.filter(b => b);

    var inputs = new Array<any>();
    this.availableEmployees?.forEach((e: { id: string, name: string }) => {
      inputs.push({
        label: e.name,
        type: 'checkbox',
        value: e.id,
        checked: this.creators?.includes(e.id),
        handler: () => {
          alert.inputs = alert.inputs.map((checkbox) => {
            if (!checkbox.value || checkbox.value !== e.id) {
              checkbox.checked = false;
            } else {
              checkbox.checked = true;
            }
            return checkbox;
          });
        }
      });
    });

    // adjust button order in four button layout for ios
    if (this.platform.is('ios')) {
      const okButton = { ...buttons[2] };
      const cancelButton = { ...buttons[3] };
      buttons = [buttons[0], buttons[1], cancelButton, okButton];
    }

    const alert = await this.alertController.create({
      header: users,
      inputs,
      cssClass: 'four-button-alert',
      buttons
    });

    await alert.present();
  }

  public async selectDocuments(): Promise<void> {
    var all: string, none: string, ok: string, cancel: string, documents: string;
    this.translate.get('BTN_ALL').subscribe((result: string) => all = result);
    this.translate.get('BTN_NONE').subscribe((result: string) => none = result);
    this.translate.get('OK').subscribe((result: string) => ok = result);
    this.translate.get('BTN_CANCEL').subscribe((result: string) => cancel = result);
    this.translate.get('DOCUMENTS').subscribe((result: string) => documents = result);

    var buttons = [
      {
        text: all,
        cssClass: 'all-none-button',
        handler: () => {
          alert.inputs = alert.inputs.map((checkbox) => {
            checkbox.checked = true;
            return checkbox;
          });
          return false;
        }
      }, {
        text: none,
        cssClass: 'all-none-button',
        handler: () => {
          alert.inputs = alert.inputs.map((checkbox) => {
            checkbox.checked = false;
            return checkbox;
          });
          return false;
        }
      }, {
        text: ok,
        handler: (data) => {
          this.types = data;
          this.setTranslatedFormTypes();
        }
      },
      , {
        text: cancel,
        role: 'cancel',
      }
    ];

    buttons = buttons.filter(b => b);

    var inputs = new Array<any>();
    this.forms.forEach((f: Form) => {
      let translation: string;
      this.translate.get(f.translations.title).subscribe((result: string) => translation = result);
      if (!inputs.some(i => i.value === f.type)) {
        inputs.push({
          label: translation,
          type: 'checkbox',
          value: f.type,
          checked: this.getAvailableForms().some(f2 => f2.type === f.type)
        });
      }
    });

    // adjust button order in four button layout for ios
    if (this.platform.is('ios')) {
      const okButton = { ...buttons[2] };
      const cancelButton = { ...buttons[3] };
      buttons = [buttons[0], buttons[1], cancelButton, okButton];
    }

    const alert = await this.alertController.create({
      header: documents,
      inputs,
      cssClass: 'four-button-alert',
      buttons
    });

    await alert.present();
  }

  public async show(): Promise<void> {
    await this.popoverController.dismiss({
      activeSortCriteria: this.availableSortCriteria.get(this.activeSortTitle),
      orderDesc: this.isDescSelected,
      types: this.types,
      creationDateFrom: DateHelpers.getFirestoreTimestamp(this.creationDateFrom),
      creationDateUntil: DateHelpers.getFirestoreTimestamp(this.creationDateUntil),
      creators: this.creators
    });
  }

  public selectAsc(): void {
    this.isDescSelected = false;
    this.isAscSelected = true;
  }

  public selectDesc(): void {
    this.isAscSelected = false;
    this.isDescSelected = true;
  }

  public async reset(): Promise<void> {
    this.activeSortCriteria = new Array<string>('creationDate');
    this.getSortCriteria();
    this.getActiveSortTitle();
    this.isAscSelected = false;
    this.isDescSelected = true;
    this.creationDateFrom = undefined;
    this.creationDateUntil = undefined;
    this.creators = new Array<string>();;
    if (this.types.length !== this.forms.length) {
      this.types = new Array<string>();
      this.forms.forEach((f: Form) => {
        this.types.push(f.type);
      });
      this.types.sort();
    }
    await this.popoverController.dismiss({
      activeSortCriteria: this.availableSortCriteria.get(this.activeSortTitle),
      orderDesc: this.isDescSelected,
      types: this.types,
      creationDateFrom: this.creationDateFrom,
      creationDateUntil: this.creationDateUntil,
      creators: this.creators
    });
  }

  public getSortCriteria(): void {
    this.availableSortCriteria = new Map<string, Array<string>>();

    this.availableSortCriteria.set('CREATIONDATE', new Array<string>('creationDate'));
    this.availableSortCriteria.set('NUMBER', new Array<string>('number'));

    var forms = this.getAvailableForms();
    forms.forEach(async (f: Form) => {
      f.overview.boldLabels.concat(f.overview.labels).forEach((l: FormOverviewLabel) => {
        if (!l.sortTranslation) { return; }

        if (this.availableSortCriteria.has(l.sortTranslation)) {
          var values = this.availableSortCriteria.get(l.sortTranslation);
          if (!values.includes(l.property)) {
            values.push(l.property);
          }
        } else {
          this.availableSortCriteria.set(l.sortTranslation, new Array<string>(l.property));
        }
      });

      if (f.overview.amounts) {
        f.overview.amounts.forEach((a: FormOverviewAmount) => {
          if (!a.sortTranslation) { return; }
          if (this.availableSortCriteria.has(a.sortTranslation)) {
            var values = this.availableSortCriteria.get(a.sortTranslation);
            if (!values.includes(a.property)) {
              if (!a.array) {
                values.push(a.property);
              } else {
                values.push(a.array + '.' + a.property);
              }
            }
          } else {
            if (!a.array) {
              this.availableSortCriteria.set(a.sortTranslation, new Array<string>(a.property));
            } else {
              this.availableSortCriteria.set(a.sortTranslation, new Array<string>(a.array + '.' + a.property));
            }
          }
        });
      }
    });
  }

  public getDynamicIdentifier(prefix: string, key: string): string {
    return Utils.getDynamicIdentifier(prefix, key);
  }

  private getActiveSortTitle(): void {
    this.availableSortCriteria.forEach((value: Array<string>, key: string) => {
      if (JSON.stringify(value) === JSON.stringify(this.activeSortCriteria)) {
        this.activeSortTitle = key;
      }
    });
  }

  private async adjustPosition(): Promise<void> {
    var element = document.getElementsByTagName('ion-popover');
    if (element[0]) {
      var wrapperOpen = await Utils.getChildElement(element[0].shadowRoot.childNodes, 1);
      var content = await Utils.getChildElement(wrapperOpen.childNodes, 1);
      var contentStyle: CSSStyleDeclaration = content['style'];
      contentStyle.transformOrigin = 'right top';
      contentStyle.left = '';
      contentStyle.top = this.navParams.get('headerHeight') + 'px';
      if (Utils.getBrowser() === Browser.Chrome) {
        contentStyle.right = '10px';
      } else {
        contentStyle.right = (this.navParams.get('margin') + 10) + 'px';
      }
    } else {
      // Wait until element is loaded
      setTimeout(async () => {
        await this.adjustPosition();
      }, 10);
    }
  }
}