import { Timestamp } from '@angular/fire/firestore';
import { enGB, de, es, fr, it, nl, pl, pt, zhCN, tr } from 'date-fns/locale';
import { Locale } from 'date-fns';

export class DateHelpers {
    private static locales = { enGB, de, es, fr, it, nl, pl, pt, zhCN, tr };

    public static getFormattedDatetime(date: Date): string {
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let hours = date.getHours().toString();
        hours = hours.length > 1 ? hours : '0' + hours;

        let minutes = date.getMinutes().toString();
        minutes = minutes.length > 1 ? minutes : '0' + minutes;

        let seconds = date.getSeconds().toString();
        seconds = seconds.length > 1 ? seconds : '0' + seconds;

        return year + month + day + '-' + hours + minutes + seconds;
    }

    public static getFormattedDateFromTimestamp(timestamp: number | string | Timestamp | undefined): string {
        let newDate = '';

        if (timestamp !== undefined && timestamp !== null) {
            var date: Date;

            if (typeof timestamp === 'number' || typeof timestamp === 'string') {
                date = new Date(timestamp);
            } else {
                date = (<Timestamp>timestamp).toDate();
            }

            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;

            newDate = day + '.' + month + '.' + year;
        }

        return newDate;
    }

    public static getFormattedDatetimeFromTimestamp(timestamp: number | string | Timestamp | undefined): string {
        let newDate = '';

        if (timestamp !== undefined && timestamp !== null) {
            var date: Date;
            if (typeof timestamp === 'number' || typeof timestamp === 'string') {
                date = new Date(timestamp);
            } else {
                date = (<Timestamp>timestamp).toDate();
            }

            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;

            let hours = date.getHours().toString();
            hours = hours.length > 1 ? hours : '0' + hours;

            let minutes = date.getMinutes().toString();
            minutes = minutes.length > 1 ? minutes : '0' + minutes;

            let seconds = date.getSeconds().toString();
            seconds = seconds.length > 1 ? seconds : '0' + seconds;

            return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes + ':' + seconds;
        }

        return newDate;
    }

    public static getFormattedMonthYearFromTimestamp(timestamp: number | string | Timestamp | undefined): string {
        let newDate = '';

        if (timestamp !== undefined && timestamp !== null) {
            var date: Date;

            if (typeof timestamp === 'number' || typeof timestamp === 'string') {
                date = new Date(timestamp);
            } else {
                date = (<Timestamp>timestamp).toDate();
            }

            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            newDate = month + '.' + year;
        }

        return newDate;
    }

    public static getFormattedYearFromTimestamp(timestamp: number | string | Timestamp | undefined): string {
        let newDate = '';

        if (timestamp !== undefined && timestamp !== null) {
            var date: Date;

            if (typeof timestamp === 'number' || typeof timestamp === 'string') {
                date = new Date(timestamp);
            } else {
                date = (<Timestamp>timestamp).toDate();
            }

            newDate = date.getFullYear().toString();
        }

        return newDate;
    }

    public static getFormattedTimeFromTimestamp(timestamp: number | string | Timestamp | undefined): string {
        let newDate = '';

        if (timestamp !== undefined && timestamp !== null) {
            var date: Date;
            if (typeof timestamp === 'string') {
                var today = new Date();
                today.setHours(Number.parseInt(timestamp.substring(0, timestamp.indexOf(':'))));
                today.setMinutes(Number.parseInt(timestamp.substring(timestamp.indexOf(':') + 1)));
                date = new Date(today);
            } else {
                date = (<Timestamp>timestamp).toDate();
            }

            let hours = date.getHours().toString();
            hours = hours.length > 1 ? hours : '0' + hours;

            let minutes = date.getMinutes().toString();
            minutes = minutes.length > 1 ? minutes : '0' + minutes;

            return hours + ':' + minutes;
        }

        return newDate;
    }

    public static getFormattedAlertControllerDate(date: Date): string {
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return year + '-' + month + '-' + day;
    }

    public static getUTCDateFromTimestamp(timestamp: number | Timestamp | undefined): string {
        let newDate = '';

        if (timestamp !== undefined && timestamp !== null) {
            var date: Date;
            if (typeof timestamp === 'number') {
                date = new Date(timestamp);
            } else {
                date = (<Timestamp>timestamp).toDate();
            }

            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;

            let hours = date.getHours().toString();
            hours = hours.length > 1 ? hours : '0' + hours;

            let minutes = date.getMinutes().toString();
            minutes = minutes.length > 1 ? minutes : '0' + minutes;

            let seconds = date.getSeconds().toString();
            seconds = seconds.length > 1 ? seconds : '0' + seconds;

            newDate = year + month + day + hours + minutes + seconds;
        }

        return newDate;
    }

    public static getFormattedSignatureDate(date: string | Timestamp): string {
        var tempDate: string;
        if (typeof date === 'object') {
            tempDate = this.getIsoDate(date);
        } else {
            tempDate = date;
        }
        return tempDate.substr(8, 2) + '.' + tempDate.substr(5, 2) + '.' + tempDate.substr(0, 4);
    }

    public static getIsoDate(date: string | Date | Timestamp): string {
        if (date === undefined || date === null) {
            return undefined;
        }

        let dateSearchString: string;
        var dateString: string;
        if (date instanceof Date) {
            dateString = date.toLocaleString('de-DE');
        } else if (typeof date === 'string') {
            dateString = date;
        } else {
            dateString = (<Timestamp>date).toDate().toLocaleString('de-DE');
        }

        if (dateString.indexOf('.') > -1) {
            dateSearchString = '.';
        } else {
            dateSearchString = '/';
        }

        let day = dateString.substr(0, dateString.indexOf(dateSearchString));
        day = day.length > 1 ? day : '0' + day;

        let month = dateString.substr(dateString.indexOf(dateSearchString) + 1);
        month = month.substr(0, month.indexOf(dateSearchString));
        month = month.length > 1 ? month : '0' + month;

        const year = dateString.substr(dateString.lastIndexOf(dateSearchString) + 1, 4);

        let hours = dateString.substr(dateString.indexOf(',') + 2);
        hours = hours.substr(0, hours.indexOf(':'));
        hours = hours.length > 1 ? hours : '0' + hours;

        let minutes = dateString.substr(dateString.indexOf(':') + 1);
        minutes = minutes.substr(0, minutes.indexOf(':'));
        minutes = minutes.length > 1 ? minutes : '0' + minutes;

        let seconds = dateString.substr(dateString.lastIndexOf(':') + 1);
        seconds = seconds.substr(0, 2);
        seconds = seconds.length > 1 ? seconds : '0' + seconds;

        return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + '.000Z';
    }

    public static getCalenderPickerDatetime(date: string | Date | Timestamp): string {
        if (date === undefined || date === null) {
            return undefined;
        }

        let dateSearchString: string;
        var dateString: string;
        if (date instanceof Date) {
            dateString = date.toLocaleString('de-DE');
        } else if (typeof date === 'string') {
            dateString = date;
        } else {
            dateString = (<Timestamp>date).toDate().toLocaleString('de-DE');
        }

        if (dateString.indexOf('.') > -1) {
            dateSearchString = '.';
        } else {
            dateSearchString = '/';
        }

        let day = dateString.substr(0, dateString.indexOf(dateSearchString));
        day = day.length > 1 ? day : '0' + day;

        let month = dateString.substr(dateString.indexOf(dateSearchString) + 1);
        month = month.substr(0, month.indexOf(dateSearchString));
        month = month.length > 1 ? month : '0' + month;

        const year = dateString.substr(dateString.lastIndexOf(dateSearchString) + 1, 4);

        let hours = dateString.substr(dateString.indexOf(',') + 2);
        hours = hours.substr(0, hours.indexOf(':'));
        hours = hours.length > 1 ? hours : '0' + hours;

        let minutes = dateString.substr(dateString.indexOf(':') + 1);
        minutes = minutes.substr(0, minutes.indexOf(':'));
        minutes = minutes.length > 1 ? minutes : '0' + minutes;

        let seconds = dateString.substr(dateString.lastIndexOf(':') + 1);
        seconds = seconds.substr(0, 2);
        seconds = seconds.length > 1 ? seconds : '0' + seconds;

        return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds;
    }

    public static getCalenderPickerDate(date: string | Date | Timestamp): string {
        if (date === undefined || date === null) {
            return undefined;
        }

        let dateSearchString: string;
        var dateString: string;
        if (date instanceof Date) {
            dateString = date.toLocaleString('de-DE');
        } else if (typeof date === 'string') {
            dateString = date;
        } else {
            dateString = (<Timestamp>date).toDate().toLocaleString('de-DE');
        }

        if (dateString.indexOf('.') > -1) {
            dateSearchString = '.';
        } else {
            dateSearchString = '/';
        }

        let day = dateString.substr(0, dateString.indexOf(dateSearchString));
        day = day.length > 1 ? day : '0' + day;

        let month = dateString.substr(dateString.indexOf(dateSearchString) + 1);
        month = month.substr(0, month.indexOf(dateSearchString));
        month = month.length > 1 ? month : '0' + month;

        const year = dateString.substr(dateString.lastIndexOf(dateSearchString) + 1, 4);

        return year + '-' + month + '-' + day;
    }

    public static getCalenderPickerMonthYear(date: string | Date | Timestamp): string {
        if (date === undefined || date === null) {
            return undefined;
        }

        let dateSearchString: string;
        var dateString: string;
        if (date instanceof Date) {
            dateString = date.toLocaleString('de-DE');
        } else if (typeof date === 'string') {
            dateString = date;
        } else {
            dateString = (<Timestamp>date).toDate().toLocaleString('de-DE');
        }

        if (dateString.indexOf('.') > -1) {
            dateSearchString = '.';
        } else {
            dateSearchString = '/';
        }

        let month = dateString.substr(dateString.indexOf(dateSearchString) + 1);
        month = month.substr(0, month.indexOf(dateSearchString));
        month = month.length > 1 ? month : '0' + month;

        const year = dateString.substr(dateString.lastIndexOf(dateSearchString) + 1, 4);

        return year + '-' + month;
    }

    public static getCalenderPickerYear(date: string | Date | Timestamp): string {
        if (date === undefined || date === null) {
            return undefined;
        }

        let dateSearchString: string;
        var dateString: string;
        if (date instanceof Date) {
            dateString = date.toLocaleString('de-DE');
        } else if (typeof date === 'string') {
            dateString = date;
        } else {
            dateString = (<Timestamp>date).toDate().toLocaleString('de-DE');
        }

        if (dateString.indexOf('.') > -1) {
            dateSearchString = '.';
        } else {
            dateSearchString = '/';
        }

        const year = dateString.substr(dateString.lastIndexOf(dateSearchString) + 1, 4);

        return year;
    }

    public static getCalenderPickerTime(date: string | Date | Timestamp): string {
        if (date === undefined || date === null) {
            return undefined;
        }

        let dateSearchString: string;
        var dateString: string;
        if (date instanceof Date) {
            dateString = date.toLocaleString('de-DE');
        } else if (typeof date === 'string') {
            dateString = date;
        } else {
            dateString = (<Timestamp>date).toDate().toLocaleString('de-DE');
        }

        if (dateString.indexOf('.') > -1) {
            dateSearchString = '.';
        } else {
            dateSearchString = '/';
        }

        let hours = dateString.substr(dateString.indexOf(',') + 2);
        hours = hours.substr(0, hours.indexOf(':'));
        hours = hours.length > 1 ? hours : '0' + hours;

        let minutes = dateString.substr(dateString.indexOf(':') + 1);
        minutes = minutes.substr(0, minutes.indexOf(':'));
        minutes = minutes.length > 1 ? minutes : '0' + minutes;

        return hours + ':' + minutes;
    }

    public static getUnixTimestamp(value: any): number {
        if (value === undefined || value === null) {
            return null;
        } else if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
            return new Date(value).getTime();
        } else {
            return (<Timestamp>value).toMillis();
        }
    }

    public static getFirestoreTimestamp(value: any): Timestamp | null {
        if (value === undefined || value === null) {
            return null;
        } else {
            return Timestamp.fromDate(new Date(value));
        }
    }

    public static getFormattedMonthYearDate(timestamp: number | string | Timestamp | undefined): string {
        let newDate = '';

        if (timestamp !== undefined && timestamp !== null) {
            var date: Date;

            if (typeof timestamp === 'number' || typeof timestamp === 'string') {
                date = new Date(timestamp);
            } else {
                date = (<Timestamp>timestamp).toDate();
            }

            var year = date.getFullYear();

            var month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            newDate = month + '/' + year;
        }

        return newDate;
    }

    public static convertObjectsToFirestoreTimestamp(obj: Object): any {
        var objectsKeys = Object.keys(obj);
        objectsKeys.forEach((k: string) => {
            if (obj[k] instanceof Object && !Array.isArray(obj[k])) {
                var propertyKeys = Object.keys(obj[k]);
                if (propertyKeys.includes('seconds') && propertyKeys.includes('nanoseconds')) {
                    obj[k] = new Timestamp(obj[k]['seconds'], obj[k]['nanoseconds']);
                }
            } else if (Array.isArray(obj[k])) {
                (<Array<any>>obj[k]).forEach(a => {
                    this.convertObjectsToFirestoreTimestamp(a);
                });
            }
        });
        return obj;
    }

    public static getLocale(language: string): Locale {
        if (!language) {
            return this.locales.de;
        }

        var replacedLanguage = language.replace('_', '-');

        switch (replacedLanguage) {
            case 'de-DE':
                return this.locales.de;
            case 'en-US':
                return this.locales.enGB;
            case 'es-ES':
                return this.locales.es;
            case 'fr-FR':
                return this.locales.fr;
            case 'it-IT':
                return this.locales.it;
            case 'nl-NL':
                return this.locales.nl;
            case 'pl-PL':
                return this.locales.pl;
            case 'pt-PT':
                return this.locales.pt;
            case 'tr-TR':
                return this.locales.tr;
            case 'zh-ZH':
                return this.locales.zhCN;
        }
    }
}
