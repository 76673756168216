export enum ControlType {
    Select = 'select',
    Segment = 'segment',
    AmountSize = 'amountsize',
    Input = 'input',
    Textarea = 'textarea',
    Image = 'image',
    Pdf = 'pdf',
    Modal = 'modal',
    Checkbox = 'checkbox',
    Sum = 'sum',
    Count = 'count',
    Calc = 'calc',
    ThreeGScanner = '3gScanner'
}
