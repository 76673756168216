export enum ValueFormat {
    Amount = 'amount',
    MonthYear = 'monthYear',
    Number = 'number',
    Timestamp = 'timestamp',
    Datetime = 'datetime',
    Select = 'select'
}

export enum ValueHandler {
    Round = 'round',
    Horsepower = 'horsepower',
    LowerCase = 'lowerCase',
    UpperCase = 'upperCase',
    Count = 'count',
    Negate = 'negate'
}

export enum ValueType {
    Variable = 'variable',
    Property = 'property',
    Translation = 'translation',
    Text = 'text'
}