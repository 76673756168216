import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Auth, updateEmail, sendEmailVerification, signOut } from '@angular/fire/auth';
import { Messaging, getToken } from '@angular/fire/messaging';
import {
  Firestore,
  deleteField, increment, arrayRemove, arrayUnion, doc, getDocFromServer, getDocFromCache, getDocsFromServer
  , getDocsFromCache, updateDoc, query, where, collection, deleteDoc
} from '@angular/fire/firestore';
import { Storage, UploadResult, ref, getDownloadURL, deleteObject, uploadString } from '@angular/fire/storage';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { StorageService } from '../../services/storage.service';
import { AuthService } from '../auth/auth.service';
import { CryptoService } from '../../services/crypto.service';
import { environment } from '../../../environments/environment';
import { User } from '../../interfaces/database/user';
import { NetworkService } from '../../services/network.service';
import { Client } from '../../enums/client.enum';
import { Message } from '../../interfaces/database/message';
import { ConfigService } from '../../services/config.service';
import { Badge } from '@capawesome/capacitor-badge';
import { FCM } from "@capacitor-community/fcm";
import { ActionPerformed, PushNotificationSchema, PushNotifications, RegistrationError, Token } from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private currentMessages: Array<Message>;

  constructor(
    private platform: Platform,
    public authService: AuthService,
    private networkService: NetworkService,
    private configService: ConfigService,
    private storageService: StorageService,
    private firestore: Firestore,
    private storage: Storage,
    private auth: Auth,
    private messaging: Messaging,
    private analytics: Analytics,
    private cryptoService: CryptoService
  ) {
    this.currentMessages = new Array<Message>();
  }

  public async updateUser(): Promise<boolean> {
    const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);

    const obj = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? await getDocFromServer(userRef) : await getDocFromCache(userRef);

    if (!obj.exists) {
      return false;
    }
    const $key = obj.id;
    const data = { $key, ...obj.data() } as User;
    var userObj = data;

    // Change email address in authentication when different        
    var emailUpdated = await this.updateEmailAddress(userObj.email);

    // Image is URL
    if (this.authService.currentUser.profilePicture === undefined || this.authService.currentUser.profilePicture === null
      || this.authService.currentUser.profilePicture === '' || (this.authService.currentUser.profilePicture !== undefined &&
        this.authService.currentUser.profilePicture !== null && this.authService.currentUser.profilePicture !== ''
        && this.authService.currentUser.profilePicture?.includes('https://'))) {
      await this.handleUpdate();
      return emailUpdated;
    } else {
      // Else image is base64
      var imageUrl = await this.putImageToFirebaseStorage(this.authService.currentUser.$key, 'users', this.authService.currentUser.profilePicture);
      this.authService.currentUser.profilePicture = imageUrl;
      await this.handleUpdate();
      var currentProfilePictureWithoutToken = this.authService.currentUser.profilePicture.substring(0, this.authService.currentUser.profilePicture.indexOf('&token'));
      var oldProfilePictureWithoutToken = userObj.profilePicture === undefined ? '' : userObj.profilePicture.substring(0, userObj.profilePicture.indexOf('&token'));
      if (userObj.profilePicture !== undefined && userObj.profilePicture !== null && userObj.profilePicture !== ''
        && oldProfilePictureWithoutToken !== currentProfilePictureWithoutToken && this.networkService.isOnline()) {
        await this.deleteImageFromFirebaseStorage(userObj.profilePicture);
        return emailUpdated;
      } else {
        return emailUpdated;
      }
    }
  }

  public async updateUserSettings(): Promise<void> {
    const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);
    var encryption = this.configService.getClientMapping().encryption;

    var vatRate: number;

    if (this.authService.currentUser.vatRate) {
      if (typeof this.authService.currentUser.vatRate === 'string') {
        let tempVatRate = parseFloat(this.authService.currentUser.vatRate);
        vatRate = tempVatRate < 0 ? tempVatRate * -1 : tempVatRate;
      } else {
        vatRate = this.authService.currentUser.vatRate < 0 ? this.authService.currentUser.vatRate * -1 : this.authService.currentUser.vatRate;
      }
    }

    var obj: object = {
      categories: !this.authService.currentUser.categories ? deleteField() : this.authService.currentUser.categories,
      currency: !this.authService.currentUser.currency ? deleteField() : this.authService.currentUser.currency,
      darkMode: this.authService.currentUser.darkMode === undefined ? false : this.authService.currentUser.darkMode,
      fileFormat: !this.authService.currentUser.fileFormat ? deleteField() : this.authService.currentUser.fileFormat,
      language: !this.authService.currentUser.language ? deleteField() : this.authService.currentUser.language,
      vatRate: !this.authService.currentUser.vatRate ? deleteField() : vatRate,
      messagingEnabled: this.authService.currentUser.messagingEnabled === undefined ? true : this.authService.currentUser.messagingEnabled
    };

    if (encryption) {
      let encryptedObj = await this.cryptoService.encryptObj(obj);
      await updateDoc(userRef, encryptedObj);
    } else {
      await updateDoc(userRef, obj);
    }
  }

  public async updateFormPrefixes(formPrefixes: Object): Promise<void> {
    if (formPrefixes === undefined) {
      return;
    }

    if (this.authService.currentUser.client !== undefined && this.authService.currentUser.client !== Client.Free && this.authService.currentUser.client !== Client.Basic
      && this.authService.currentUser.client !== Client.Advance) {
      this.authService.currentClientMapping.formPrefixes = formPrefixes;

      const clientRef = doc(this.firestore, 'mappings', 'clients');

      var newClientMapping = { ...this.authService.currentClientMapping };
      delete newClientMapping.$key;
      await updateDoc(clientRef, {
        [this.authService.currentUser.client]: newClientMapping
      });
    } else {
      this.authService.currentUser.prefixes = formPrefixes;

      const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);

      await updateDoc(userRef, {
        prefixes: formPrefixes
      });
    }
  }

  public async updateCostCenters(costCenters: Array<{ id: string, description: string }>, costCenterNumber: number, costCenterNumberPrefix: string): Promise<void> {
    if (!this.authService.currentUser.client || this.authService.currentUser.client === Client.Free || this.authService.currentUser.client === Client.Basic
      || this.authService.currentUser.client === Client.Advance) {
      this.authService.currentUser.costCenters = costCenters;
      this.authService.currentUser.costCenterNumber = costCenterNumber;
      this.authService.currentUser.costCenterNumberPrefix = costCenterNumberPrefix;

      const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);

      if (costCenters === undefined || costCenters === null || costCenters.length === 0) {
        await updateDoc(userRef, {
          costCenters: deleteField(),
          costCenterNumber: costCenterNumber,
          costCenterNumberPrefix: costCenterNumberPrefix
        });
      } else {
        await updateDoc(userRef, {
          costCenters: costCenters,
          costCenterNumber: costCenterNumber,
          costCenterNumberPrefix: costCenterNumberPrefix
        });
      }
    } else {
      if (costCenters === undefined || costCenters === null || costCenters.length === 0) {
        delete this.authService.currentClientMapping.costCenters;
      } else {
        this.authService.currentClientMapping.costCenters = costCenters;
      }
      this.authService.currentClientMapping.costCenterNumber = costCenterNumber;
      this.authService.currentClientMapping.costCenterNumberPrefix = costCenterNumberPrefix;

      const clientRef = doc(this.firestore, 'mappings', 'clients');

      var newClientMapping = { ...this.authService.currentClientMapping };
      delete newClientMapping.$key;
      await updateDoc(clientRef, {
        [this.authService.currentUser.client]: newClientMapping
      });
    }
  }

  public async updateUserDocNumber(formType: string, number: number): Promise<void> {
    if (!this.authService.currentUser.numbers) {
      this.authService.currentUser.numbers = {};
    }
    this.authService.currentUser.numbers[formType] = number;

    const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);
    await updateDoc(userRef, {
      numbers: this.authService.currentUser.numbers
    });
  }

  public async updateMasterDataNumber(objNumberPrefixName: string, objNumberPrefix: string): Promise<void> {
    if (objNumberPrefixName === undefined || objNumberPrefix === undefined) {
      return;
    }

    if (this.authService.currentUser.client !== undefined && this.authService.currentUser.client !== Client.Free && this.authService.currentUser.client !== Client.Basic
      && this.authService.currentUser.client !== Client.Advance) {
      this.authService.currentClientMapping[objNumberPrefixName] = objNumberPrefix;

      const clientRef = doc(this.firestore, 'mappings', 'clients');

      var newClientMapping = { ...this.authService.currentClientMapping };
      delete newClientMapping.$key;
      await updateDoc(clientRef, {
        [this.authService.currentUser.client]: newClientMapping
      });
    } else {
      this.authService.currentUser[objNumberPrefixName] = objNumberPrefix;

      const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);
      await updateDoc(userRef, {
        [objNumberPrefixName]: objNumberPrefix
      });
    }
  }

  public async incrementUserExportCount(): Promise<void> {
    const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);
    await updateDoc(userRef, {
      exportExecutions: increment(1)
    });
  }

  public async updateEmailAddress(oldEmailAddress: string): Promise<boolean> {
    if (oldEmailAddress === this.authService.currentUser.email) {
      return false;
    } else {
      await updateEmail(this.auth.currentUser, this.authService.currentUser.email);
      await sendEmailVerification(this.auth.currentUser);
      return true;
    }
  }

  public async saveImage(image: string): Promise<void> {
    this.authService.currentUser.profilePicture = image;
    await this.updateUser();
  }

  public async logout(): Promise<void> {
    await this.storageService.set('category', 'do');
    await signOut(this.auth);
  }

  public async deleteImageFromFirebaseStorage(imageUrl: string): Promise<any> {
    const imageRef = ref(this.storage, imageUrl);
    await deleteObject(imageRef);
  }

  public async loadMessages(): Promise<Array<Message>> {
    this.currentMessages = new Array<Message>();

    if (this.networkService.isOnline() && this.networkService.isMinBandwidth()) {
      if (this.authService.currentUser !== undefined) {
        const messagesRef = collection(this.firestore, 'messages');
        const q = query(messagesRef, where('recipientIds', 'array-contains', this.authService.currentUser.$key), where('visible', '==', true));
        const messages = this.networkService.isOnline() && this.networkService.isMinBandwidth() ? getDocsFromServer(q) : getDocsFromCache(q);

        messages.then(async messageData => {
          var messageObjs = messageData.docs.filter(a => a.exists)
            .map(result => {
              const $key = result.id;
              const data = { $key, ...result.data() as {} } as Message;
              return data;
            });

          this.currentMessages = messageObjs;
          await this.storageService.set('messages', messageObjs);
          await this.setAppBadgeCounter();
          return !this.currentMessages ? new Array<Message>() : this.currentMessages;
        }).catch(async (ex) => {
          if (this.platform.is('capacitor') && this.platform.ready() && !this.platform.is('mobileweb')) {
            logEvent(this.analytics, 'error', { message: 'Error while getting messages from firebase server / cache -> Getting messages from local storage: ' + JSON.stringify(ex) });
          }
          // Error while getting messages from firebase server / cache -> Getting messages from local storage
          this.currentMessages = (await this.storageService.get('messages'));
          await this.setAppBadgeCounter();
          return !this.currentMessages ? new Array<Message>() : this.currentMessages;
        });
      } else {
        this.currentMessages = new Array<Message>();
        await this.setAppBadgeCounter();
        return this.currentMessages;
      }
    } else {
      // Device is offline or min bandwidth isn't available
      if (this.platform.is('capacitor') && this.platform.ready() && !this.platform.is('mobileweb')) {
        logEvent(this.analytics, 'error', { message: 'Device is offline or min bandwidth isnt available -> Getting messages from local storage' });
      }
      this.currentMessages = await this.storageService.get('messages');
      await this.setAppBadgeCounter();
      return !this.currentMessages ? new Array<Message>() : this.currentMessages;
    }
  }

  public getCurrentMessages(): Array<Message> {
    let messages = this.currentMessages
      ?.filter(m => {
        if (m.senderNode !== 'products' && m.senderNode !== 'contacts') {
          if (m.senderSubType) {
            return this.configService.getFormMappingByNodeAndSubType(m.senderNode, m.senderSubType) !== undefined;
          } else {
            return this.configService.getFormMappingByNode(m.senderNode) !== undefined;
          }
        } else if (m.senderNode === 'products') {
          if (m.senderCategory) {
            return this.configService.getMasterDataProductMappingByCategory(m.senderCategory) !== undefined;
          } else {
            return false;
          }
        } else if (m.senderNode === 'contacts') {
          if (m.senderCategory) {
            return this.configService.getMasterDataContactMappingByCategory(m.senderCategory) !== undefined;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });

    if (messages.length > 0) {
      var unseenMessages = messages.filter((m: Message) => !m.seen?.includes(this.authService.currentUser.$key))
        ?.sort((a, b) => b.creationDate.toMillis() - a.creationDate.toMillis());
      var seenMessages = messages.filter((m: Message) => m.seen?.includes(this.authService.currentUser.$key))
        ?.sort((a, b) => b.creationDate.toMillis() - a.creationDate.toMillis());

      return unseenMessages.concat(seenMessages);
    } else {
      return messages;
    }
  }

  public async getMessagingToken(userId: string): Promise<void> {
    if (this.platform.is('capacitor')) {
      await this.registerNotifications();

      await this.getFcmToken(userId);
    } else {
      getToken(this.messaging, { vapidKey: environment.firebase.vapidKey })
        .then(
          async (token: string) => {
            if (token) {
              await this.storeToken(token, userId);
            }
          },
          (error: any) => {
            console.error(error);
          },
        );
    }
  }

  public async setAppBadgeCounter(): Promise<void> {
    if (this.platform.is('capacitor') && this.platform.ready() && !this.platform.is('mobileweb')) {
      var unseenMessages = this.getCurrentMessages()
        .filter((m: Message) => m.visible)
        .filter((m: Message) => !m.seen?.includes(this.authService.currentUser.$key));

      if (unseenMessages?.length > 0) {
        await Badge.set({ count: unseenMessages.length });
      } else {
        await Badge.clear();
      }
    } else {
      return;
    }
  }

  public async deleteToken(): Promise<void> {
    var token = await this.storageService.get('token');
    if (token !== null) {
      await this.storageService.remove('token');
      const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);
      await updateDoc(userRef, {
        messagingTokens: arrayRemove(token)
      });
    }
  }

  public async deleteUser(): Promise<void> {
    const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);
    await deleteDoc(userRef);

    try {
      var currentUser = this.auth.currentUser;
      await currentUser.delete();
    } catch (error) {
      logEvent(this.analytics, 'error', { message: 'DeleteUser (' + currentUser.email + ') : ' + JSON.stringify(error) });
    }
  }

  public async setMessageAsSeen(message: Message): Promise<void> {
    if (!message.seen) {
      message.seen = new Array<string>(this.authService.currentUser.$key);
    } else {
      message.seen.push(this.authService.currentUser.$key);
    }
    const objRef = doc(this.firestore, 'messages', message.$key);
    await updateDoc(objRef, {
      seen: arrayUnion(this.authService.currentUser.$key)
    });
    await this.setAppBadgeCounter();
  }

  public async setMessageAsUnseen(message: Message): Promise<void> {
    if (message.seen?.length > 0 && message.seen?.indexOf(this.authService.currentUser.$key) > -1) {
      message.seen = message.seen.filter(s => s !== this.authService.currentUser.$key);
    }
    const objRef = doc(this.firestore, 'messages', message.$key);
    await updateDoc(objRef, {
      seen: arrayRemove(this.authService.currentUser.$key)
    });
    await this.setAppBadgeCounter();
  }

  public async deleteMessage(message: Message): Promise<void> {
    message.visible = false;
    const objRef = doc(this.firestore, 'messages', message.$key);
    await updateDoc(objRef, {
      visible: false
    });
    await this.setAppBadgeCounter();
  }

  private async storeToken(token: string, userId: string): Promise<void> {
    await this.storageService.set('token', token);
    const userRef = doc(this.firestore, 'users', userId);
    await updateDoc(userRef, {
      messagingTokens: arrayUnion(token)
    });
  }

  private async handleUpdate(): Promise<void> {
    var encryption = this.configService.getClientMapping().encryption;
    const userRef = doc(this.firestore, 'users', this.authService.currentUser.$key);

    var obj: object = {
      salutation: this.authService.currentUser.salutation === undefined ? deleteField() : this.authService.currentUser.salutation,
      title: this.authService.currentUser.title === undefined ? deleteField() : this.authService.currentUser.title,
      company: this.authService.currentUser.company === undefined ? deleteField() : this.authService.currentUser.company,
      firstname: this.authService.currentUser.firstname === undefined ? deleteField() : this.authService.currentUser.firstname,
      lastname: this.authService.currentUser.lastname === undefined ? deleteField() : this.authService.currentUser.lastname,
      birthdate: this.authService.currentUser.birthdate === undefined ? deleteField() : this.authService.currentUser.birthdate,
      street: this.authService.currentUser.street === undefined ? deleteField() : this.authService.currentUser.street,
      postalcode: this.authService.currentUser.postalcode === undefined ? deleteField() : this.authService.currentUser.postalcode,
      city: this.authService.currentUser.city === undefined ? deleteField() : this.authService.currentUser.city,
      state: this.authService.currentUser.state === undefined ? deleteField() : this.authService.currentUser.state,
      country: this.authService.currentUser.country === undefined ? deleteField() : this.authService.currentUser.country,
      email: this.authService.currentUser.email === undefined ? deleteField() : this.authService.currentUser.email,
      phoneNr: this.authService.currentUser.phoneNr === undefined ? deleteField() : this.authService.currentUser.phoneNr,
      idCardIdNumber: this.authService.currentUser.idCardIdNumber === undefined ? deleteField() : this.authService.currentUser.idCardIdNumber,
      idCardIssueAgency: this.authService.currentUser.idCardIssueAgency === undefined ? deleteField()
        : this.authService.currentUser.idCardIssueAgency,
      idCardIssueDate: this.authService.currentUser.idCardIssueDate === undefined ? deleteField() : this.authService.currentUser.idCardIssueDate,
      paymentIban: this.authService.currentUser.paymentIban === undefined ? deleteField() : this.authService.currentUser.paymentIban,
      paymentBic: this.authService.currentUser.paymentBic === undefined ? deleteField() : this.authService.currentUser.paymentBic,
      paymentInstitution: this.authService.currentUser.paymentInstitution === undefined ? deleteField() : this.authService.currentUser.paymentInstitution,
      profilePicture: this.authService.currentUser.profilePicture === undefined ? deleteField() : this.authService.currentUser.profilePicture,
      salesTaxId: this.authService.currentUser.salesTaxId === undefined ? deleteField() : this.authService.currentUser.salesTaxId,
      taxNumber: this.authService.currentUser.taxNumber === undefined ? deleteField() : this.authService.currentUser.taxNumber
    };

    if (encryption) {
      let encryptedObj = await this.cryptoService.encryptObj(obj);
      await updateDoc(userRef, encryptedObj);
    } else {
      await updateDoc(userRef, obj);
    }
  }

  private async putImageToFirebaseStorage(id: string, subfolder: string, image: string): Promise<any> {
    if (image === undefined || image === null || image === '') {
      return '';
    } else if (!this.networkService.isOnline() || !this.networkService.isMinBandwidth()) {
      return image;
    } else {
      const documentRef = ref(this.storage, subfolder + '/' + id);
      var snap: UploadResult = await uploadString(documentRef, image.replace('data:image/jpeg;base64,', '')
        .replace('data:image/png;base64,', '')
        .replace('data:application/pdf;base64,', ''), 'base64');

      var downloadUrl: string = await getDownloadURL(snap.ref);
      return downloadUrl;
    }
  }

  private convertToNumber(value: any): number {
    if (typeof (value) === "string") {
      return parseFloat(value);
    } else {
      return value;
    }
  }

  private async registerNotifications(): Promise<void> {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();

      if (permStatus.receive == 'granted') {
        await PushNotifications.register();
      } else {
        throw new Error('User denied permissions!');
      }
    } else if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    } else {
      await PushNotifications.register();
    }
  }

  private async getFcmToken(userId: string): Promise<void> {
    let token = await FCM.getToken();

    if (token && token.token) {
      await this.storeToken(token.token, userId);
    }
  }
}