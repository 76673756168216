import { Injectable } from '@angular/core';
import { Network, Connection } from '@awesome-cordova-plugins/network/ngx';
import { Platform } from '@ionic/angular';
import { fromEvent, merge, of, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    private online: boolean;
    private online$: Observable<boolean> = undefined;

    private validMinBandwidths: Array<string> = [
        // Connections on device
        Connection.CELL_3G,
        Connection.CELL_4G,
        Connection.CELL,
        Connection.ETHERNET,
        Connection.UNKNOWN,
        Connection.WIFI,
        // Connections in browser
        '3g',
        '4g'
    ];

    private invalidMinBandwidths: Array<string> = [
        Connection.CELL_2G
    ];

    constructor(
        public network: Network
        , public platform: Platform
    ) { }

    public listenToNetworkStatus(): void {
        this.online = true;

        if (this.platform.is('capacitor')) {
            this.network.onDisconnect().subscribe(() => {
                this.online = false;
            });
            this.network.onConnect().subscribe(() => {
                this.online = true;
            });
            this.online$ = merge(
                this.network.onConnect().pipe(mapTo(true)),
                this.network.onDisconnect().pipe(mapTo(false))
            );
        } else {
            this.online$ = merge(
                of(navigator.onLine),
                fromEvent(window, 'online').pipe(mapTo(true)),
                fromEvent(window, 'offline').pipe(mapTo(false))
            );
            this.online = navigator.onLine;
        }
    }

    public isMinBandwidth(): boolean {
        var type: string = this.platform.is('capacitor') ? this.network.type : navigator['connection'] ? navigator['connection']['effectiveType'] : null;
        if (
            type === null
            ||
            (
                this.validMinBandwidths.map(b => b.toLowerCase()).includes(type.toLowerCase())
                &&
                !this.invalidMinBandwidths.map(b => b.toLowerCase()).includes(type.toLowerCase())
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    public getNetworkType(): string {
        return this.platform.is('capacitor') ? this.network.type : navigator['connection']['effectiveType'];
    }

    public isOnlineObservable(): Observable<boolean> {
        return this.online$;
    }

    public isOnline(): boolean {
        if (this.platform.is('capacitor')) {
            return this.online;
        } else {
            return navigator.onLine;
        }
    }
}