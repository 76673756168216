import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import 'hammerjs';
import { Capacitor } from '@capacitor/core';
import { HammerModule, BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { ImageModalModule } from './pages/general/image-modal/image-modal.module';
import { ModalModule } from './pages/general/modal/modal.module';
import { MasterDataModalModule } from './pages/general/master-data-modal/master-data-modal.module';
import { FormModalModule } from './pages/general/form-modal/form-modal.module';
import { FilterPopoverPageModule } from './pages/general/filter-popover/filter-popover.module';
import { FilterDashboardPopoverPageModule } from './pages/general/filter-dashboard-popover/filter-dashboard-popover.module';
import { FilterMasterDataPopoverPageModule } from './pages/general/filter-masterdata-popover/filter-masterdata-popover.module';
import { LocationIssuerComponentModule } from './pages/signature/location-issuer/location-issuer.module';
import { LocationRecipientComponentModule } from './pages/signature/location-recipient/location-recipient.module';
import { NetworkService } from './services/network.service';
import { EventService } from './services/event.service';
import { ConfigService } from './services/config.service';
import { StorageService } from './services/storage.service';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { Market } from '@ionic-native/market/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxImageCompressService } from "ngx-image-compress";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeyValuesPipe } from './pipes/key-values.pipe';

import { environment } from '../environments/environment';
import { CustomLoader } from './utils/translate-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataCyDirective } from './directives/data-cy.directive';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import {
    provideFirestore, getFirestore, connectFirestoreEmulator, enableIndexedDbPersistence, persistentLocalCache, CACHE_SIZE_UNLIMITED
    , initializeFirestore, persistentMultipleTabManager, memoryLocalCache
} from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';

@NgModule({
    declarations: [AppComponent, KeyValuesPipe, DataCyDirective],
    imports: [
        BrowserModule,
        HammerModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        provideFirebaseApp(() => {
            const app = initializeApp(environment.firebase);

            initializeFirestore(app
                , {
                    localCache:
                        persistentLocalCache({ cacheSizeBytes: CACHE_SIZE_UNLIMITED, tabManager: persistentMultipleTabManager() })
                });

            if (Capacitor.isNativePlatform) {
                initializeAuth(app, {
                    persistence: indexedDBLocalPersistence
                });

            }
            return app;
        }),
        provideDatabase(() => getDatabase()),
        provideFirestore(() => {
            const firestore = getFirestore();
            //connectFirestoreEmulator(firestore, 'localhost', 8080);
            return firestore;
        }),
        provideStorage(() => getStorage()),
        provideAuth(() => getAuth()),
        provideMessaging(() => getMessaging()),
        provideRemoteConfig(() => getRemoteConfig()),
        providePerformance(() => getPerformance()),
        provideAnalytics(() => getAnalytics()),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader,
                deps: [ConfigService]
            }
        }),
        ImageModalModule,
        ModalModule,
        MasterDataModalModule,
        FormModalModule,
        FilterPopoverPageModule,
        FilterDashboardPopoverPageModule,
        FilterMasterDataPopoverPageModule,
        LocationIssuerComponentModule,
        LocationRecipientComponentModule,
        BrowserAnimationsModule,
        ImageCropperModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        FileOpener,
        Contacts,
        Market,
        AppVersion,
        AppRate,
        Network,
        NetworkService,
        EventService,
        StorageService,
        Diagnostic,
        NgxImageCompressService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
