export enum InputType {
    Amount = 'amount',
    Number = 'number',
    Text = 'text',
    Date = 'date',
    Datetime = 'datetime-local',
    Month = 'month',
    Year = 'year',
    Time = 'time'
}
