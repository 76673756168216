// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select {
  max-width: 100%;
  width: 100%;
}

.item-select ion-label {
  max-width: 75px;
  min-width: 75px;
}

#select-option {
  margin-right: auto;
  width: 100%;
}

ion-item {
  --border-width: 0px !important;
}

ion-item-divider {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent !important;
  height: 38px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/general/filter-dashboard-popover/filter-dashboard-popover.page.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;AACF;;AACA;EACE,eAAA;EACA,eAAA;AAEF;;AAAA;EACE,kBAAA;EACA,WAAA;AAGF;;AADA;EACE,8BAAA;AAIF;;AAFA;EACE,eAAA;EACA,mBAAA;EACA,yBAAA;EACA,wCAAA;EACA,YAAA;AAKF","sourcesContent":["ion-select {\n  max-width: 100%;\n  width: 100%;\n}\n.item-select ion-label {\n  max-width: 75px;\n  min-width: 75px;\n}\n#select-option {\n  margin-right: auto;\n  width: 100%;\n}\nion-item {\n  --border-width: 0px !important;\n}\nion-item-divider {\n  font-size: 12px;\n  letter-spacing: 1px;\n  text-transform: uppercase;\n  background-color: transparent !important;\n  height: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
