// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-searchbar {
  text-align: start;
  vertical-align: middle;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

ion-badge {
  --background: transparent !important;
  font-weight: bold;
  font-size: 14px;
  color: var(--ion-color-dark) !important;
  padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/general/form-modal/form-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AACA;EACI,oCAAA;EACA,iBAAA;EACA,eAAA;EACA,uCAAA;EACA,YAAA;AAEJ","sourcesContent":["ion-searchbar {\n    text-align: start;\n    vertical-align: middle;\n    align-items: center;\n    padding-top: 6px;\n    padding-bottom: 6px;\n}\nion-badge {\n    --background: transparent !important;\n    font-weight: bold;\n    font-size: 14px;\n    color: var(--ion-color-dark) !important;\n    padding: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
