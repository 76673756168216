export class AmountHelpers {
    static getFormattedAmountByLanguageByCurrency(amount: any, currency: string, language: string): string {
        if (amount === undefined) {
            return '';
        } else {
            if (typeof amount === 'string') {
                amount = parseFloat(amount);
            }
            if (Intl) {
                if (language === undefined || language === null) {
                    language = 'de-DE';
                }
                if (currency === undefined || currency === null || currency === '-') {
                    currency = 'EUR';
                }
                return new Intl.NumberFormat(language.replace('_', '-'), { style: 'currency', currency }).format(amount);
            } else {
                return amount;
            }
        }
    }

    static getFormattedAmountByLanguage(amount: any, language: string, minimumFractionDigits?: number, maximumFractionDigits?: number): string {
        if (amount === undefined) {
            return '';
        } else {
            if (typeof amount === 'string') {
                amount = parseFloat(amount.replace(',', '.'));
            }
            if (Intl) {
                if (language === undefined || language === null) {
                    language = 'de-DE';
                }
                return new Intl.NumberFormat(language.replace('_', '-'), { minimumFractionDigits, maximumFractionDigits }).format(amount);
            } else {
                return amount;
            }
        }
    }

    static calculateNetAmount(amountsize: string, currency: string, amount: any, vatrate: any, language: string, withFormatting: boolean): any {
        let newAmount = 0;
        let newVatrate = 0;
        let result = 0;

        if (amountsize === '') {
            amountsize = 'gross';
        }
        if (currency === undefined || currency === null || currency === '-') {
            currency = 'EUR';
        }
        if (language === undefined || language === null) {
            language = 'de-DE';
        }

        newAmount = amount ? parseFloat(amount) : 0;
        newVatrate = vatrate ? (parseFloat(vatrate) / 100) : 0;

        if (amountsize === 'gross') {
            result = newAmount / (1 + newVatrate);
        } else {
            result = newAmount;
        }

        if (withFormatting) {
            return new Intl.NumberFormat(language.replace('_', '-'), { style: 'currency', currency }).format(result);
        } else {
            return result;
        }
    }

    static calculateVat(amountsize: string, currency: string, amount: any, vatrate: any, language: string, withFormatting: boolean): any {
        let newAmount = 0;
        let newVatrate = 0;
        let result = 0;

        if (amountsize === '') {
            amountsize = 'gross';
        }
        if (currency === undefined || language === null || currency === '-') {
            currency = 'EUR';
        }
        if (language === undefined || language === null) {
            language = 'de-DE';
        }

        newAmount = amount ? parseFloat(amount) : 0;
        newVatrate = vatrate ? (parseFloat(vatrate) / 100) : 0;

        if (amountsize === 'gross') {
            result = newAmount * newVatrate / (1 + newVatrate);
        } else {
            result = newAmount * newVatrate;
        }

        if (withFormatting) {
            return new Intl.NumberFormat(language.replace('_', '-'), { style: 'currency', currency }).format(result);
        } else {
            return result;
        }
    }

    static calculateTotal(amountsize: string, currency: string, amount: any, vatrate: any, language: string, withFormatting: boolean): any {
        let newAmount = 0;
        let newVatrate = 0;
        let result = 0;

        if (amountsize === '') {
            amountsize = 'gross';
        }
        if (currency === undefined || currency === null || currency === '-') {
            currency = 'EUR';
        }
        if (language === undefined || language === null) {
            language = 'de-DE';
        }

        newAmount = amount ? parseFloat(amount) : 0;
        newVatrate = vatrate ? (parseFloat(vatrate) / 100) : 0;

        if (amountsize === 'gross') {
            result = newAmount;
        } else {
            result = newAmount + (newAmount * newVatrate);
        }

        if (withFormatting) {
            return new Intl.NumberFormat(language.replace('_', '-'), { style: 'currency', currency }).format(result);
        } else {
            return result;
        }
    }
}
