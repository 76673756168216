import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  Platform, AlertController, ModalController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../../services/storage.service';
import { AuthService } from '../../auth/auth.service';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { DateHelpers } from '../../../utils/date-helpers';
import { AlertId } from '../../../../app/enums/alert-id.enum';
import { Utils } from '../../../../app/utils/utils';
import { format } from 'date-fns';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app-location-recipient',
  templateUrl: './location-recipient.component.html',
  styleUrls: ['./location-recipient.component.scss'],
})
export class LocationRecipientComponent {
  private pageTrace: PerformanceTrace;

  public maxDate: any;
  public locationForm: UntypedFormGroup;
  public submitAttempt = false;
  public documentType: any;
  public isOnline: boolean = true;
  @Input() date: any;
  @Input() location: string;
  public language: any;

  constructor(
    public translate: TranslateService,
    private platform: Platform,
    public authService: AuthService,
    private alertCtrl: AlertController,
    public formBuilder: UntypedFormBuilder,
    private modalCtrl: ModalController,
    private storageService: StorageService,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.pageTrace = trace(this.performance, LocationRecipientComponent.name);

    this.locationForm = formBuilder.group({
      location: ['', Validators.required],
      date: ['', Validators.required]
    });
  }

  public async ionViewWillEnter(): Promise<void> {
    this.maxDate = DateHelpers.getCalenderPickerDate(new Date());

    var language = await this.storageService.get('language');
    this.language = language?.replace('_', '-');

    if (!this.date) {
      this.date = DateHelpers.getCalenderPickerDate(new Date());
      this.locationForm.controls['date'].setValue(DateHelpers.getCalenderPickerDate(new Date()));
    } else {
      this.locationForm.controls['date'].setValue(this.date);
    }

    if (!this.location) {
      this.location = this.authService.currentUser.city === undefined ? '' : this.authService.currentUser.city;
    }

    await this.storageService.set('component', LocationRecipientComponent.name);
    logEvent(this.analytics, 'screen_view');
    try { this.pageTrace.start(); } catch (error) { }

    if (this.platform.is('capacitor') && this.platform.isPortrait()) {
      ScreenOrientation.lock({ orientation: 'landscape-primary' });
    }
  }

  public async ionViewWillLeave(): Promise<void> {
    try { this.pageTrace.stop(); } catch (error) { }
  }

  public cancel(): void {
    this.modalCtrl.dismiss();
  }

  public async continue(): Promise<void> {
    this.submitAttempt = true;
    if (this.locationForm.valid) {
      this.modalCtrl.dismiss({
        location: this.location,
        date: this.date
      });
    } else {
      let header: string, message: string, ok: string;
      this.translate.get('TITLE_INCOMPLETE').subscribe((result: string) => header = result);
      this.translate.get('MSG_INCOMPLETE').subscribe((result: string) => message = result);
      this.translate.get('OK').subscribe((result: string) => ok = result);
      var alert = await this.alertCtrl.create({
        id: AlertId.Incomplete,
        header,
        message,
        buttons: [
          {
            text: ok
          }
        ]
      });
      await alert.present();
    }
  }

  public dateChanged(event: CustomEvent, id: string): void {
    if (event.detail.value === undefined) {
      var element = document.getElementById('dateModal_' + id);
      if (element) {
        (element as HTMLIonModalElement).dismiss();
      }
    }
  }

  public getFormattedString(value: string): string {
    if (!value) {
      return undefined;
    } else {
      let date = new Date(value);
      return format(date, 'dd.MM.yyyy', { locale: DateHelpers.getLocale(this.language) });
    }
  }

  public getDynamicIdentifier(prefix: string, key: string): string {
    return Utils.getDynamicIdentifier(prefix, key);
  }
}
