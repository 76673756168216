export enum PdfType {
    Header = 'header',
    Footer = 'footer',
    Content = 'content',
    Participants = 'participants',
    Empty = 'empty',
    Modal = 'modal',
    ModalTable = 'modalTable',
    PageBreak = 'pageBreak',
    PageMargins = 'pageMargins',
    SeparateHeader = 'separateHeader'
}
