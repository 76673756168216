import { Component } from '@angular/core';
import { Platform, ModalController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../../services/storage.service';
import { AuthService } from '../../auth/auth.service';
import { AccountService } from '../../account/account.service';
import { ContactService } from '../../master-data/contact/contact.service';
import { ProductService } from '../../master-data/product/product.service';
import { Contact, Product } from '../../../interfaces/database/masterdata';
import { Utils } from '../../../utils/utils';
import { MasterDataType } from '../../../enums/masterdata-type.enum';
import { ContactCategory } from '../../../enums/contact-category.enum';
import { ConfigService } from '../../../services/config.service';
import { DateHelpers } from '../../../../app/utils/date-helpers';
import { AmountHelpers } from '../../../../app/utils/amount-helpers';
import { MasterDataOverviewLabel } from '../../../../app/interfaces/config/masterdata';
import { ProductCategory } from '../../../enums/product-category.enum';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app-master-data-modal',
  templateUrl: './master-data-modal.component.html',
  styleUrls: ['./master-data-modal.component.scss'],
})
export class MasterDataModalComponent {
  private pageTrace: PerformanceTrace;

  public darkMode: boolean;
  public searchInput = '';
  public items: Array<Contact | Product> = new Array<Contact | Product>();
  public filteredItems: Array<Contact | Product> = new Array<Contact | Product>();
  public sortCriteria: Array<string>;
  public orderDesc: boolean;
  public masterDataType: MasterDataType;
  public masterDataTypeEnum: typeof MasterDataType = MasterDataType;
  public contactCategory: typeof ContactCategory = ContactCategory;
  public language: any;

  constructor(
    public translate: TranslateService,
    private platform: Platform,
    public authService: AuthService,
    public accountService: AccountService,
    private modalCtrl: ModalController,
    public formBuilder: UntypedFormBuilder,
    private contactService: ContactService,
    private navParams: NavParams,
    private productService: ProductService,
    private configService: ConfigService,
    private storageService: StorageService,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.pageTrace = trace(this.performance, MasterDataModalComponent.name);
  }

  public async ionViewWillEnter(): Promise<void> {
    await this.storageService.set('component', MasterDataModalComponent.name);
    logEvent(this.analytics, 'screen_view');
    try { this.pageTrace.start(); } catch (error) { }

    var darkMode = await this.storageService.get('darkMode');
    this.darkMode = darkMode;

    var language = await this.storageService.get('language');
    this.language = language.replace('_', '-');

    this.masterDataType = this.navParams.data.masterDataType as MasterDataType;
    var category = this.navParams.data.category;

    if (this.masterDataType === MasterDataType.Contact) {
      this.items = await this.contactService.getCurrentMasterDataObjs() as Array<Contact>;
    } else {
      this.items = await this.productService.getCurrentMasterDataObjs() as Array<Product>;
      if (category && category !== ProductCategory.General) {
        this.items = this.items
          .filter((i: Product) => i.category === category)
          .filter(i => this.configService.getMasterDataProductMappingByCategory(i.category));
      } else {
        this.items = this.items.filter(i => this.configService.getMasterDataProductMappingByCategory(i.category));
      }
    }

    this.sortCriteria = this.masterDataType === MasterDataType.Contact ? new Array<string>('lastname') : new Array<string>('title');
    this.orderDesc = false;

    this.filter();
  }

  public async ionViewWillLeave(): Promise<void> {
    try { this.pageTrace.stop(); } catch (error) { }
  }

  public async cancel(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  public toUpperCase(text: string): string {
    return text.toUpperCase();
  }

  public async filter(): Promise<void> {
    if (this.searchInput !== undefined && this.searchInput !== '') {
      this.filteredItems = await Utils.orderBy(this.items.filter((data: Contact) => {
        return this.checkSearchInput(data);
      }), this.sortCriteria, this.orderDesc);
    } else {
      this.filteredItems = await Utils.orderBy(this.items, this.sortCriteria, this.orderDesc);
    }
  }

  public async use(item: Contact | Product): Promise<void> {
    await this.modalCtrl.dismiss({
      key: item.$key
    });
  }

  public getBoldLabels(masterDataType: string, category: string): Array<MasterDataOverviewLabel> {
    if (masterDataType === MasterDataType.Product) {
      return this.configService.getMasterDataProductMappingByCategory(category)?.overview.boldLabels;
    } else {
      return this.configService.getMasterDataContactMappingByCategory(category)?.overview.boldLabels;
    }
  }

  public getLabels(masterDataType: string, category: string): Array<MasterDataOverviewLabel> {
    if (masterDataType === MasterDataType.Product) {
      return this.configService.getMasterDataProductMappingByCategory(category)?.overview.labels;
    } else {
      return this.configService.getMasterDataContactMappingByCategory(category)?.overview.labels;
    }
  }

  public getReplacedLabel(obj: any, label: MasterDataOverviewLabel, type: string): string {
    if (!label) { return; }

    if (label.property === 'category') {
      var text = type === MasterDataType.Product
        ? this.configService.getMasterDataProductMappingByCategory(obj.category).text
        : this.configService.getMasterDataContactMappingByCategory(obj.category).text;
      var translation: string;
      this.translate.get(text).subscribe((result: string) => translation = result);
      return translation;
    } else if (obj[label.property]) {
      if (label.property.toLowerCase().indexOf('date') > -1) {
        return DateHelpers.getFormattedDateFromTimestamp(obj[label.property]);
      } else if (typeof obj[label.property] === 'number') {
        return AmountHelpers.getFormattedAmountByLanguageByCurrency(obj[label.property], !obj['currency'] ? 'EUR' : obj['currency'], this.language);
      } else {
        var masterDataMapping = type === MasterDataType.Product
          ? this.configService.getMasterDataProductMappingByCategory(obj.category)
          : this.configService.getMasterDataContactMappingByCategory(obj.category);
        var masterDataControl = masterDataMapping?.attributes.filter(a => a.controls.filter(c => c.key === label.property).pop())
          .pop()
          ?.controls
          .filter(c => c.key === label.property)
          .pop();
        if (masterDataControl?.selectOptions) {
          var translation: string;
          var item = masterDataControl.selectOptions.items.filter(i => i.value === obj[label.property]).pop();
          this.translate.get(item ? item.text : obj[label.property]).subscribe((result: string) => translation = result);
          return translation;
        } else {
          return obj[label.property];
        }
      }
    } else {
      return;
    }
  }

  public getDynamicIdentifier(prefix: string, key: string): string {
    return Utils.getDynamicIdentifier(prefix, key);
  }

  private checkSearchInput(masterData: any): boolean {
    return Object.keys(masterData).some(m => {
      if (masterData[m] !== undefined) {
        if (typeof (masterData[m]) === 'number') {
          return masterData[m].toString().toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1;
        } else if (typeof (masterData[m]) === 'string') {
          return masterData[m].toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }
}
