export enum AlertId {
    PopupBlocked = 'alert_popupBlocked',
    UnknownError = 'alert_unknownError',
    DevMode = 'alert_devMode',
    WrongPassword = 'alert_wrongPassword',
    UserNotFound = 'alert_userNotFound',
    InvalidEmail = 'alert_invalidEmail',
    NoTeamLead = 'alert_noTeamLead',
    SetCostCenter = 'alert_setCostCenter',
    MaxCostCenters = 'alert_maxCostCenters',
    DeleteCostCenter = 'alert_deleteCostCenter',
    CostCenterNotSet = 'alert_costCenterNotSet',
    MasterDataNotSet = 'alert_masterDataNotSet',
    EmailChanged = 'alert_emailChanged',
    DataSaved = 'alert_dataSaved',
    NoImage = 'alert_noImage',
    DeleteAccount = 'alert_deleteAccount',
    ExportData = 'alert_exportData',
    ExportNoData = 'alert_exportNoData',
    Logout = 'alert_logout',
    MaxExecutions = 'alert_maxExecutions',
    CheckMaintenance = 'alert_checkMaintencance',
    InvalidVersion = 'alert_invalidVersion',
    NotVerified = 'alert_notVerified',
    Incomplete = 'alert_incomplete',
    TooManyRequests = 'alert_tooManyRequests',
    UserDisabled = 'alert_userDisabled',
    ResetPassword = 'alert_resetPassword',
    SignedUp = 'alert_signedUp',
    WeakPassword = 'alert_weakPassword',
    EmailInUse = 'alert_emailInUse',
    Delete = 'alert_delete',
    DeletePermanent = 'alert_deletePermanent',
    Restore = 'alert_restore',
    SigningNotPossible = 'alert_signingNotPossible',
    SuccessfulTransfer = 'alert_successfulTransfer',
    MaxMasterData = 'alert_maxMasterData',
    SharingNotPossible = 'alert_sharingNotPossible',
    DeleteImage = 'alert_deleteImage',
    DeletePdf = 'alert_deletePdf',
    DeleteModal = 'alert_deleteModal',
    DeleteContact = 'alert_deleteContact',
    DeleteProduct = 'alert_deleteProduct',
    MaxImages = 'alert_maxImages',
    MaxElements = 'alert_maxElements',
    MaxFileSize = 'alert_maxFileSize',
    CheckExistingContact = 'alert_checkExistingContact',
    CreateContact = 'alert_createContact',
    UpdateContact = 'alert_updateContact',
    MessageReceivedDevice = 'alert_messageReceivedDevice',
    MessageReceivedWeb = 'alert_messageReceivedWeb',
    SignatureExists = 'alert_signatureExists'
}