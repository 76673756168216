import { Injectable } from '@angular/core';
import { Firestore, doc, updateDoc, Timestamp, arrayUnion } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  constructor(
    private firestore: Firestore
  ) { }

  public addElementToMasterDataHistory(masterDataNode: string, masterDataId: string
    , newHistoryObj: {
      date: Timestamp, description: string, descriptionTitle: string, formId: string, documentNumber: string, formType: string, formSubType: string
    }): void {

    var masterDataRef = doc(this.firestore, masterDataNode, masterDataId);
    updateDoc(masterDataRef, {
      history: arrayUnion(newHistoryObj)
    });
  }
}