import { StringHelpers } from './string-helpers';
import { Browser } from '../enums/browser.enum';
import { deleteField, Timestamp } from '@angular/fire/firestore';
import { AbstractControl, UntypedFormControl, FormControlName } from '@angular/forms';
import { FormTransferCondition, FormTransferConditionObj, FormTransferTarget, FormTransferTargetAttribute } from '../interfaces/config/form_transfer';
import { User } from '../interfaces/database/user';
import { TranslateService } from '@ngx-translate/core';
import { FormCondition, FormConditionObj, FormDashboardAmount, FormOverviewAmount, FormPermissions } from '../interfaces/config/form';
import { AmountHelpers } from './amount-helpers';
import { FormAssignCondition, FormAssignConditionObj, FormAssignSource, FormAssignSourceAttribute } from '../interfaces/config/form_assign';
import { BodyType } from '../enums/mappings/pdf-body.enum';
import { Client } from '../enums/client.enum';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { MasterDataPermissions } from '../interfaces/config/masterdata';
import { ImageService } from '../services/image.service';

export class Utils {
    public static enableDarkTheme(shouldEnable: boolean) {
        document.body.classList.toggle('dark', shouldEnable);
    }

    public static base64ToArrayBuffer(base64Data: string): Promise<ArrayBuffer> {
        return new Promise(promise => {
            base64Data = base64Data.replace('data:image/jpeg;base64,', '')
                .replace('data:image/png;base64,', '')
                .replace('data:application/pdf;base64,', '');
            const binaryString = window.atob(base64Data);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            promise(bytes.buffer);
        });
    }

    public static async saveBase64AsImage(data: string, directory: Directory, path: string): Promise<string> {
        var file = await Filesystem.writeFile({
            path,
            data,
            directory
        });
        return file.uri;
    }

    public static async removeFileOnStorage(directory: Directory, path: string) {
        if (directory !== undefined && path !== undefined) {
            var fileStat = await Filesystem.stat({
                path,
                directory
            });
            if (fileStat) {
                await Filesystem.deleteFile({
                    path,
                    directory
                });
            }
        }
    }

    public static async asyncForEach(array: Array<any>, callback: any) {
        if (array === undefined) {
            return;
        }

        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    public static async sliceArray(array: Array<any>, chunkSize: number): Promise<Array<any>> {
        var tempArray = new Array<any>();
        for (var i = 0, len = array.length; i < len; i += chunkSize)
            tempArray.push(array.slice(i, i + chunkSize));
        return tempArray;
    }

    public static async deleteUndefinedProperties(obj: any): Promise<void> {
        Object.keys(obj).forEach(k => {
            if (k === 'holder') {
                return;
            } else if ((obj[k] === undefined || obj[k] === null) && !k.includes('seconds')) {
                delete obj[k];
            } else if (obj[k]['seconds'] !== undefined && isNaN(obj[k]['seconds'])) {
                obj[k] = null;
            } else if (typeof obj[k] === 'object') {
                this.deleteUndefinedProperties(obj[k]);
            }
        });
    }

    public static async deleteUpdatedUndefinedProperties(obj: any): Promise<void> {
        Object.keys(obj).forEach(k => {
            if (k === 'holder') {
                return;
            } else if ((obj[k] === undefined || obj[k] === null) && !k.includes('seconds')) {
                delete obj[k];
            } else if (obj[k]['seconds'] !== undefined && isNaN(obj[k]['seconds'])) {
                obj[k] = deleteField();
            } else if (typeof obj[k] === 'object') {
                this.deleteUndefinedProperties(obj[k]);
            }
        });
    }

    public static async replaceUndefinedPropertiesWithDelete(obj: any): Promise<void> {
        Object.keys(obj).forEach(k => {
            if (!obj[k]) {
                obj[k] = deleteField();
            }
        });
    }

    public static async getNewObjectNumber(objectNumber: number | undefined): Promise<number> {
        if (objectNumber === undefined) {
            return 1;
        } else {
            if (typeof (objectNumber) === 'string') {
                objectNumber = parseFloat(objectNumber);
            }
            return objectNumber + 1;
        }
    }

    public static async getNewObjectNumberString(objectNumberPrefix: string, objectNumber: number): Promise<string> {
        if (objectNumberPrefix === undefined || objectNumberPrefix === '') {
            return new Date().getFullYear() + '-' + StringHelpers.padString(objectNumber, 6);
        } else {
            return objectNumberPrefix + '-' + StringHelpers.padString(objectNumber, 6);
        }
    }

    public static getFullname(salutation: string, title: string, name: string): string {
        if (name !== undefined && name !== null && name !== '') {
            if (salutation !== undefined && salutation !== null && salutation !== '') {
                if (title !== undefined && title !== null && title !== '') {
                    return salutation + ' ' + title + ' ' + name;
                } else {
                    return salutation + ' ' + name;
                }
            } else if (title !== undefined && title !== null && title !== '') {
                return title + ' ' + name;
            } else {
                return name;
            }
        } else {
            return '';
        }
    }

    public static getHorsepower(power: number): number {
        if (power === undefined || power === null) {
            return 0;
        } else {
            return Math.round(power * 1.35962);
        }
    }

    public static async orderBy(value: Array<any>, criteria: Array<string>, orderDesc: boolean, translate?: TranslateService): Promise<any> {
        if (value === undefined) { return null; }
        return value.sort((a: any, b: any) => {
            var orderCriteriaIndexA: number;
            var orderCriteriaIndexB: number;

            criteria.forEach((criterion: string) => {
                if (a[criterion]) {
                    orderCriteriaIndexA = criteria.indexOf(criterion);
                }
                if (b[criterion]) {
                    orderCriteriaIndexB = criteria.indexOf(criterion);
                }
            });

            var valueA = this.getValue(criteria[orderCriteriaIndexA === undefined ? 0 : orderCriteriaIndexA], a[criteria[orderCriteriaIndexA]], orderDesc, translate);
            var valueB = this.getValue(criteria[orderCriteriaIndexB === undefined ? 0 : orderCriteriaIndexB], b[criteria[orderCriteriaIndexB]], orderDesc, translate);

            valueA = valueA === undefined ? 0 : valueA;
            valueB = valueB === undefined ? 0 : valueB;

            if (valueA < valueB) {
                return orderDesc ? 1 : -1;
            }
            if (valueA > valueB) {
                return orderDesc ? -1 : 1;
            }
            return 0;
        });
    }

    public static getBrowser(): Browser {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            return Browser.Opera;
        }
        else if (navigator.userAgent.indexOf("Chrome") != -1) {
            return Browser.Chrome;
        }
        else if (navigator.userAgent.indexOf("Safari") != -1) {
            return Browser.Safari;
        }
        else if (navigator.userAgent.indexOf("Firefox") != -1) {
            return Browser.Firefox;
        }
        else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document['documentNode'] == true)) {
            return Browser.InternetExplorer;
        }
        else {
            return Browser.Unknown;
        }
    }

    public static getReplacedValue(item: any, value: string): string {
        if (value === undefined) {
            return;
        }
        var variables = this.getSpecificVariables(value);
        if (variables.length > 0) {
            variables.forEach((variable: string) => {
                value = value.replace('[item.' + variable + ']', item[variable]);
            });
        }
        return value;
    }

    public static getReplacedTransferRule(item: any, value: string): string {
        if (value === undefined) {
            return;
        }
        var variables = this.getGeneralVariables(value);
        if (variables.length > 0) {
            variables.forEach((variable: string) => {
                let replacedValue: any;

                if (item[variable] == null) {
                    return;
                }

                if (typeof item[variable] === 'string' && item[variable].includes(':')) {
                    let today = new Date();
                    today.setHours(Number.parseInt(item[variable].substring(0, item[variable].indexOf(':'))));
                    today.setMinutes(Number.parseInt(item[variable].substring(item[variable].indexOf(':') + 1)));
                    let date = new Date(today);
                    replacedValue = Math.floor(date.getTime());
                } else if (typeof item[variable] === 'string' && item[variable].split('-').length - 1 > 1) {
                    let date = new Date(item[variable]);
                    replacedValue = Math.floor(date.getTime());
                } else if (typeof item[variable] === 'object') {
                    let date = (<Timestamp>item[variable]).toDate();
                    replacedValue = Math.floor(date.getTime());
                } else {
                    replacedValue = item[variable] !== undefined && item[variable] !== null ? item[variable] : 0;
                }
                value = value.replace('[' + variable + ']', typeof replacedValue === 'string' ? replacedValue.replace(',', '.') : replacedValue);
            });
        }
        return value;
    }

    public static getReplacedCalcRule(controls: any, value: string): string {
        if (value === undefined) {
            return;
        }

        var variables = this.getGeneralVariables(value);
        if (variables.length > 0) {
            variables.forEach((variable: string) => {
                let replacedValue: any;
                if (typeof controls[variable]?.value === 'string' && controls[variable]?.value.split('-').length - 1 > 1) {
                    let date: Date;
                    if (controls[variable].value.indexOf('T') > -1) {
                        date = new Date(controls[variable].value.substring(0, controls[variable].value.indexOf('T')));
                    } else {
                        date = new Date(controls[variable].value);
                    }
                    replacedValue = Math.floor(date.getTime());
                } else if (typeof controls[variable]?.value === 'string' && controls[variable]?.value.includes(':')) {
                    let today = new Date();
                    today.setHours(Number.parseInt(controls[variable].value.substring(0, controls[variable].value.indexOf(':'))));
                    today.setMinutes(Number.parseInt(controls[variable].value.substring(controls[variable].value.indexOf(':') + 1)));
                    let date = new Date(today);
                    replacedValue = Math.floor(date.getTime());
                } else if (typeof controls[variable]?.value === 'object' && controls[variable]?.value) {
                    let date = (<Timestamp>controls[variable]?.value).toDate();
                    replacedValue = Math.floor(date.getTime());
                } else {
                    replacedValue = controls[variable] && controls[variable].value !== undefined && controls[variable].value !== null ? controls[variable].value : 0;
                }
                value = value.replace('[' + variable + ']', typeof replacedValue === 'string' ? replacedValue.replace(',', '.') : replacedValue);
            });
        }
        return value;
    }

    public static checkDynamicCondition(propertyValue: string | boolean | number, comparison: string, value: string | boolean | number, currentUserKey: string): boolean {
        var result = false;
        var replacedValue = value === '<undefined>' ? undefined : value === '<currentUser>' ? currentUserKey : value;

        switch (comparison) {
            case '==':
                result = propertyValue === replacedValue;
                break;
            case '!=':
                result = propertyValue !== replacedValue;
                break;
            case '<':
                result = propertyValue < replacedValue;
                break;
            case '<=':
                result = propertyValue <= replacedValue;
                break;
            case '>':
                result = propertyValue > replacedValue;
                break;
            case '>=':
                result = propertyValue >= replacedValue;
                break;
        }

        return result;
    }

    public static convertMapToObject(map: Map<string, AbstractControl>): any {
        var obj = {};

        map.forEach((control: AbstractControl, key: string) => {
            obj[key] = control.value;
        });

        return obj;
    }

    public static async addGeneralControls(transferAssignObj: FormTransferTarget | FormAssignSource, document: any, controlsMap: Map<string, AbstractControl>, currentUser: User, parser: any
        , language: string): Promise<void> {
        await Utils.asyncForEach(transferAssignObj.attributes, async (attribute: FormTransferTargetAttribute | FormAssignSourceAttribute) => {
            if (attribute.skipConditions) {
                let skip = await this.checkConditions(document, attribute.skipConditions, currentUser.$key);
                if (skip) { return; }
            }
            if (attribute.showConditions) {
                let show = await this.checkConditions(document, attribute.showConditions, currentUser.$key);
                if (!show) { return; }
            }
            var abstractControl: AbstractControl = new UntypedFormControl();
            if (attribute.from !== undefined && attribute.from === 'vatrate' && document['valueAddedTaxIncluded'] !== undefined) {
                var valueAddedTaxIncluded: AbstractControl = new UntypedFormControl();
                var vatrate: number;
                if (document['valueAddedTaxIncluded']) {
                    valueAddedTaxIncluded.setValue('gross');
                    vatrate = currentUser.vatRate ? currentUser.vatRate : 0;
                } else {
                    valueAddedTaxIncluded.setValue('net');
                    vatrate = document[attribute.from] !== undefined ? document[attribute.from] : attribute.default;
                }
                abstractControl.setValue(vatrate);
                controlsMap.set(attribute.to, abstractControl);
                controlsMap.set('amountsize', valueAddedTaxIncluded);
            } else {
                if (attribute.from !== undefined) {
                    let fromValue = this.getTransferFromValue(document, attribute, undefined, currentUser);
                    if (fromValue !== undefined) {
                        abstractControl.setValue(fromValue);
                        controlsMap.set(attribute.to, abstractControl);
                    }
                } else if (attribute.fromRule !== undefined) {
                    let replacedRule = Utils.getReplacedTransferRule(document, attribute.fromRule);
                    let resultObj = parser.parse(replacedRule);
                    let result = resultObj.result;
                    abstractControl.setValue(result);
                    controlsMap.set(attribute.to, abstractControl);
                } else if (attribute.fromArray !== undefined && attribute.toArray === undefined) {
                    var arrayContent = document[attribute.fromArray];
                    if (arrayContent?.length > 0) {
                        if (attribute.filterConditions) {
                            arrayContent = await this.handleFilterConditions(arrayContent, attribute.filterConditions, currentUser.$key);
                        }
                        let sum = 0;
                        var currency: string;
                        await Utils.asyncForEach(arrayContent, async (element: any) => {
                            if (attribute.property.includes('<')) {
                                currency = element['currency'] ? element['currency'] : document['currency'];
                                sum += this.calculateSpecificAmount(element['amountsize'], currency, element['amount'], element['vatrate'], language, attribute.property.replace('<', '').replace('<', ''));
                            } else {
                                sum += element[attribute.property];
                            }
                        });
                        abstractControl.setValue(sum);
                        controlsMap.set(attribute.to, abstractControl);
                    } else {
                        return;
                    }
                } else if (attribute.toArray !== undefined && attribute.attributes?.length > 0) {
                    let newValue: any;
                    if (attribute.fromArray !== undefined && document[attribute.fromArray]) {
                        newValue = new Array<{}>();
                        await Utils.asyncForEach(document[attribute.fromArray], async (element: any) => {
                            var elementValue = {};
                            await Utils.asyncForEach(attribute.attributes, (subAttribute: FormTransferTargetAttribute | FormAssignSourceAttribute) => {
                                let fromValue = this.getTransferFromValue(element, subAttribute, parser, currentUser);
                                if (fromValue !== undefined) {
                                    elementValue[subAttribute.to] = fromValue;
                                }
                            });
                            newValue.push(elementValue);
                        });
                    } else {
                        newValue = {};
                        await Utils.asyncForEach(attribute.attributes, (subAttribute: FormTransferTargetAttribute | FormAssignSourceAttribute) => {
                            let fromValue = this.getTransferFromValue(document, subAttribute, undefined, currentUser);
                            if (fromValue !== undefined) {
                                newValue[subAttribute.to] = fromValue;
                            }
                        });
                    }
                    if (controlsMap.has(attribute.toArray)) {
                        var existingControl = controlsMap.get(attribute.toArray);
                        if (Array.isArray(newValue)) {
                            existingControl.value.push(...newValue);
                        } else {
                            existingControl.value.push(newValue);
                        }
                    } else {
                        var newArray = new Array<any>();
                        if (Array.isArray(newValue)) {
                            newArray.push(...newValue);
                        } else {
                            newArray.push(newValue);
                        }
                        abstractControl.setValue(newArray);
                        controlsMap.set(attribute.toArray, abstractControl);
                    }
                }
            }
        });
    }

    public static async checkConditions(obj: any, conditions: Array<FormTransferCondition | FormAssignCondition | FormCondition | FormTransferConditionObj | FormConditionObj>, currentUserKey: string): Promise<boolean> {
        var conditionsArray = new Array<boolean>();

        await Utils.asyncForEach(conditions as Array<FormTransferCondition | FormAssignCondition | FormCondition | FormTransferConditionObj | FormAssignConditionObj | FormConditionObj>
            , async (condition: FormTransferCondition | FormAssignCondition | FormCondition | FormTransferConditionObj | FormAssignCondition | FormConditionObj) => {
                if ('operator' in condition) {
                    let subConditionsArray = new Array<boolean>();
                    await Utils.asyncForEach(condition.conditions as Array<FormTransferCondition | FormAssignCondition | FormCondition | FormTransferConditionObj | FormAssignConditionObj | FormConditionObj>
                        , async (subCondition: FormTransferCondition | FormAssignCondition | FormCondition | FormTransferConditionObj | FormAssignConditionObj | FormConditionObj) => {
                            if ('operator' in subCondition) {
                                let subSubConditionsArray = new Array<boolean>();
                                await Utils.asyncForEach(subCondition.conditions as Array<FormTransferCondition | FormAssignCondition | FormCondition>
                                    , (subSubCondition: FormTransferCondition | FormAssignCondition | FormCondition) => {
                                        subSubConditionsArray.push(Utils.checkDynamicCondition(obj[subSubCondition.property], subSubCondition.comparison, subSubCondition.value, currentUserKey));
                                    });
                                if (subCondition['operator'] === 'OR') {
                                    subConditionsArray.push(subSubConditionsArray.includes(true));
                                } else {
                                    subConditionsArray.push(!subSubConditionsArray.includes(false));
                                }
                            } else {
                                subConditionsArray.push(Utils.checkDynamicCondition(obj[subCondition.property], subCondition.comparison, subCondition.value, currentUserKey));
                            }
                        });
                    if (condition['operator'] === 'OR') {
                        conditionsArray.push(subConditionsArray.includes(true));
                    } else {
                        conditionsArray.push(!subConditionsArray.includes(false));
                    }
                } else {
                    conditionsArray.push(Utils.checkDynamicCondition(obj[condition.property], condition.comparison, condition.value, currentUserKey));
                }
            });

        return !conditionsArray.includes(false);
    }

    public static checkReadPermissions(client: string, currentUserPermissions: Array<string>, objPermissions: FormPermissions): boolean {
        if (client === undefined
            || client === Client.Basic
            || client === Client.Free
            || client === Client.Advance) {
            return true;
        } else {
            if (currentUserPermissions?.length >= 0) {
                if (objPermissions?.read?.length >= 0) {
                    return objPermissions.read.some(r => currentUserPermissions.includes(r));
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    public static checkWritePermissions(client: string, currentUserPermissions: Array<string>, objPermissions: FormPermissions | MasterDataPermissions): boolean {
        if (client === undefined
            || client === Client.Basic
            || client === Client.Free
            || client === Client.Advance) {
            return true;
        } else {
            if (currentUserPermissions?.length >= 0) {
                if (objPermissions?.write?.length >= 0) {
                    return objPermissions.write.some(w => currentUserPermissions.includes(w));
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    public static getDynamicIdentifier(prefix: string, key: string): string {
        return prefix + '_' + key;
    }

    private static calculateSpecificAmount(amountsize: string, currency: string, amount: any, vatrate: any, language: string, amountType: string): any {
        var result: string | number;
        if (amountType === BodyType.NetAmount) {
            result = AmountHelpers.calculateNetAmount(amountsize, currency, amount, vatrate, language, false);
        } else if (amountType === BodyType.VatAmount) {
            result = AmountHelpers.calculateVat(amountsize, currency, amount, vatrate, language, false);
        } else {
            result = AmountHelpers.calculateTotal(amountsize, currency, amount, vatrate, language, false);
        }
        return result;
    }

    private static async handleFilterConditions(arrayContent: Array<any>, filterConditions: Array<FormTransferCondition | FormAssignCondition | FormTransferConditionObj | FormAssignConditionObj>, currentUserKey: string): Promise<Array<any>> {
        var newArrayContent = new Array<any>();
        await this.asyncForEach(arrayContent, async (element: any) => {
            var result = await this.checkConditions(element, filterConditions, currentUserKey);
            if (result) {
                newArrayContent.push(element);
            }
        });
        return newArrayContent;
    }

    private static getTransferFromValue(document: any, attribute: FormTransferTargetAttribute, parser?: any, currentUser?: User): any {
        if (document[attribute.from] !== undefined || attribute.fromRule !== undefined || attribute.default !== undefined) {
            if (document[attribute.from] !== undefined) {
                return document[attribute.from];
            } else if (attribute.fromRule !== undefined) {
                let replacedRule = this.getReplacedTransferRule(document, attribute.fromRule);
                let resultObj = parser.parse(replacedRule);
                return resultObj.result;
            } else {
                if (attribute.default === '<vatrate>') {
                    return currentUser.vatRate;
                } else {
                    return attribute.default;
                }
            }
        } else {
            return undefined;
        }
    }

    private static getGeneralVariables(text: string): Array<string> {
        if (text.includes('[')) {
            var variables = text.match(/\[.*?\]/g).map(x => x.replace('[', '').replace(']', ''));
            return variables;
        } else {
            return new Array<string>(text);
        }
    }

    private static getSpecificVariables(text: string): Array<string> {
        if (text.includes('[')) {
            var variables = text.match(/\[.*?\]/g).map(x => x.replace('[item.', '').replace(']', ''));
            return variables;
        } else {
            return new Array<string>(text);
        }
    }

    private static getValue(criteria: string, value: any, orderDesc: boolean, translate?: TranslateService): any {
        if (criteria === undefined) {
            return 0;
        } else {
            if (criteria === 'amount' || criteria === 'price' || criteria.toLowerCase().includes('amount')) {
                if (value === undefined || value === null) {
                    return 0;
                } else if (typeof value === 'string') {
                    return parseFloat(value.replace(',', '.'));
                } else {
                    return value;
                }
            } else if (criteria === 'creationDate' || criteria === 'dueDate') {
                if ((value === undefined || value === null) && criteria.startsWith('due')) {
                    if (orderDesc) {
                        return 0;
                    } else {
                        return 9999999999999;
                    }
                } else if (value === undefined || value === null) {
                    return value;
                } else {
                    return (<Timestamp>value).toMillis();
                }
            } else if (criteria === 'number') {
                if (value === undefined || value === null) {
                    return 0;
                } else {
                    var prefix = value.substring(0, value.indexOf('-'));
                    var preparedPrefix: number;
                    if (isNaN(parseFloat(prefix))) {
                        preparedPrefix = this.getAsciiSum(prefix);
                    } else {
                        preparedPrefix = parseFloat(prefix);
                    }
                    var number = value.substring(value.indexOf('-') + 1);
                    var parsedValue = preparedPrefix.toString() + number;
                    return parseFloat(parsedValue);
                }
            } else if (criteria === 'category' || criteria === 'result') {
                if (value === undefined || value === null) {
                    return '';
                } else {
                    let translation: string;
                    translate.get(value.toUpperCase()).subscribe((result: string) => translation = result);
                    if (translation) {
                        return translation.toLowerCase();
                    } else {
                        return value.toLowerCase();
                    }
                }
            } else if (typeof value === 'string') {
                if (value === undefined || value === null) {
                    return '';
                } else {
                    return value.toLowerCase();
                }
            } else if (value === undefined) {
                return '';
            } else {
                return value;
            }
        }
    }

    public static getArrayAmount(array: Array<any>, amount: FormOverviewAmount | FormDashboardAmount, language: any): number {
        let sum = 0;
        array.forEach((element: any) => {
            if (amount.property.includes('<')) {
                var calculatedAmount = 0;
                var amountsize = element['amountsize'] ? element['amountsize'] : '';
                var currency = element['currency'] ? element['currency'] : document['currency'];
                if (amount.property.toLowerCase().includes('netamount')) {
                    calculatedAmount = AmountHelpers.calculateNetAmount(amountsize, currency, element['amount'], element['vatrate'], language, false);
                } else if (amount.property.toLowerCase().includes('vatamount')) {
                    calculatedAmount = AmountHelpers.calculateVat(amountsize, currency, element['amount'], element['vatrate'], language, false);
                } else if (amount.property.toLowerCase().includes('totalamount')) {
                    calculatedAmount = AmountHelpers.calculateTotal(amountsize, currency, element['amount'], element['vatrate'], language, false);
                }
                sum += calculatedAmount;
            } else {
                if (element[amount.property]) {
                    sum += parseFloat(element[amount.property].replace(',', '.'));
                }
            }
        });
        return sum;
    }

    public static blobToBase64(blob: Blob): Promise<any> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    public static getBase64ByteLength(base64: string): number {
        const buffer = Buffer.from(base64.substring(base64.indexOf(',') + 1));
        return buffer.length / 1e+6;
    }

    public static async getChildElement(nodes: NodeListOf<ChildNode>, elementNumber: number): Promise<ChildNode> {
        return new Promise(async promise => {
            if (nodes.item(elementNumber)) {
                promise(nodes.item(elementNumber));
            } else {
                // Wait until child node is loaded
                setTimeout(async () => {
                    promise(this.getChildElement(nodes, elementNumber));
                }, 10);
            }
        });
    }

    public static isArrayControlWithNewImage(control: AbstractControl, imageService: ImageService): boolean {
        return typeof control.value === 'object'
            && control.value !== null
            && control.value !== undefined
            && Array.isArray(control.value)
            && (<Array<any>>control.value).some(v => v.image && !imageService.isFirebaseStorageUrl(v.image));
    }

    public static isArrayPropertyWithNewImage(property: any, imageService: ImageService): boolean {
        return typeof property === 'object'
            && property !== null
            && property !== undefined
            && Array.isArray(property)
            && (<Array<any>>property).some(v => v.image && !imageService.isFirebaseStorageUrl(v.image));
    }

    public static getArrayControlWithoutNewImage(control: AbstractControl, imageService: ImageService): any {
        var newArray = new Array<any>();
        control.value.forEach((v: any) => {
            if (!v.image || imageService.isFirebaseStorageUrl(v.image)) {
                let v2 = structuredClone(v);
                newArray.push(v2);
            }
        });
        return newArray;
    }

    public static getArrayPropertyWithoutNewImage(property: any, imageService: ImageService): any {
        var newArray = new Array<any>();
        property.forEach((v: any) => {
            if (!v.image || imageService.isFirebaseStorageUrl(v.image)) {
                let v2 = structuredClone(v);
                newArray.push(v2);
            }
        });
        return newArray;
    }

    private static getAsciiSum(value: string): number {
        var sum = 0;
        for (var i = 0; i < value.length; i++) {
            var asciiValue = value.charCodeAt(i);
            sum += asciiValue;
        }
        return sum;
    }
}
