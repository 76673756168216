import { NgModule } from '@angular/core';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: ''
    , loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  { path: 'login', loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule) },
  { path: 'login-email', loadChildren: () => import('./pages/auth/login-email/login-email.module').then(m => m.LoginEmailPageModule) },
  { path: 'reset-password', loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) },
  { path: 'signup', loadChildren: () => import('./pages/auth/signup/signup.module').then(m => m.SignupPageModule) },
  {
    path: 'profile'
    , loadChildren: () => import('./pages/account/profile/profile.module').then(m => m.ProfilePageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'settings'
    , loadChildren: () => import('./pages/account/settings/settings.module').then(m => m.SettingsPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'uploads'
    , loadChildren: () => import('./pages/account/uploads/uploads.module').then(m => m.UploadsPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/account/messages/messages.module').then(m => m.MessagesPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'sign-issuer'
    , loadChildren: () => import('./pages/signature/sign-issuer/sign-issuer.module').then(m => m.SignIssuerPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'sign-recipient'
    , loadChildren: () => import('./pages/signature/sign-recipient/sign-recipient.module').then(m => m.SignRecipientPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'edit-issuer'
    , loadChildren: () => import('./pages/documents/general/edit-issuer/edit-issuer.module').then(m => m.EditIssuerPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'edit-recipient'
    , loadChildren: () => import('./pages/documents/general/edit-recipient/edit-recipient.module').then(m => m.EditRecipientPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'contact-edit-object'
    , loadChildren: () => import('./pages/master-data/contact/contact-edit-object/contact-edit-object.module').then(m => m.ContactEditObjectPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'contact-details'
    , loadChildren: () => import('./pages/master-data/contact/contact-details/contact-details.module').then(m => m.ContactDetailsPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'product-edit-object'
    , loadChildren: () => import('./pages/master-data/product/product-edit-object/product-edit-object.module').then(m => m.ProductEditObjectPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'product-details'
    , loadChildren: () => import('./pages/master-data/product/product-details/product-details.module').then(m => m.ProductDetailsPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'form-edit'
    , loadChildren: () => import('./pages/documents/form/form-edit/form-edit.module').then(m => m.FormEditPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'form-details'
    , loadChildren: () => import('./pages/documents/form/form-details/form-details.module').then(m => m.FormDetailsPageModule)
    , canActivate: [AuthGuard]
    , data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'messages-popover',
    loadChildren: () => import('./pages/general/messages-popover/messages-popover.module').then(m => m.MessagesPopoverPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
