import { Injectable } from '@angular/core';
import { createCipheriv, createDecipheriv, createHash } from 'crypto';
import { Utils } from '../utils/utils';
import { Timestamp } from '@angular/fire/firestore';
import { Form } from '../interfaces/config/form';
import { MasterDataObj } from '../interfaces/config/masterdata';
import { ConfigService } from './config.service';

const algorithm: string = 'aes-256-ctr';

const blacklist: Array<string> = ['$key', 'type', 'subType', 'status', 'client', 'number', 'creator', 'holder', 'partner', 'userId', 'currency', 'email'];
const imageBlacklist: Array<string> = ['data:image/jpeg;base64,', 'data:image/png;base64,', 'data:application/pdf;base64,', 'https://firebasestorage.googleapis.com/'];

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private password: string;
  private key: string;
  private encryptionIV: string;

  constructor(
    private configService: ConfigService
  ) { }

  public async encryptObj(obj: object, mapping?: Form | MasterDataObj): Promise<object> {
    let encryptedObj: object = {};

    await Utils.asyncForEach(Object.keys(obj), async (key: string) => {
      if (typeof obj[key] === 'string' && obj[key] !== ''
        && !blacklist.includes(key) && !imageBlacklist.some((blacklistItem) => obj[key].includes(blacklistItem))) {
        var mappingAttribute = mapping?.attributes.find(a => a.controls.some(c => c.key === key));
        var attributeControl = mappingAttribute?.controls.find(c => c.key === key);
        if (
          !key.startsWith('issuer_')
          && !key.startsWith('recipient_')
          && ((mapping && attributeControl?.encryption === true) || !mapping)) {
          encryptedObj[key] = this.encryptValue(obj[key]);
        } else if (key.startsWith('issuer_') && mapping && mapping['issuer'] !== undefined) {
          var encryption = (mapping as Form).issuer.encryption;
          var dynamicControl = (mapping as Form).issuer.controls?.find(c => c.key === key);
          if (encryption && (!dynamicControl || dynamicControl && dynamicControl.encryption)) {
            encryptedObj[key] = this.encryptValue(obj[key]);
          } else {
            encryptedObj[key] = obj[key];
          }
        } else if (key.startsWith('recipient_') && mapping && mapping['recipient'] !== undefined) {
          var encryption = (mapping as Form).recipient.encryption;
          var dynamicControl = (mapping as Form).recipient.controls?.find(c => c.key === key);
          if (encryption && (!dynamicControl || dynamicControl && dynamicControl.encryption)) {
            encryptedObj[key] = this.encryptValue(obj[key]);
          } else {
            encryptedObj[key] = obj[key];
          }
        } else {
          encryptedObj[key] = obj[key];
        }
      } else {
        encryptedObj[key] = obj[key];
      }
    });

    return encryptedObj;
  }

  public async decryptObj(obj: object): Promise<void> {
    if (!this.configService.getClientMapping()) {
      return;
    }

    await Utils.asyncForEach(Object.keys(obj), async (key: string) => {
      if (typeof obj[key] === 'string' && obj[key] !== '' && !blacklist.includes(key) && !blacklist.includes(key)
        && !imageBlacklist.some((blacklistItem) => obj[key].includes(blacklistItem))) {
        obj[key] = this.decryptValue(obj[key]);
      }
    });
  }

  public encryptValue(value: string): string {
    this.setSecrets();

    if (!this.password) {
      return value;
    } else {
      if (this.alreadyEncrypted(value)) {
        return value;
      } else {
        const cipher = createCipheriv(algorithm, this.key, this.encryptionIV);
        return Buffer.from(
          cipher.update(value, 'utf8', 'hex') + cipher.final('hex')
        ).toString('base64');
      }
    }
  }

  public decryptValue(value: string): string {
    this.setSecrets();

    if (!this.password) {
      return value;
    } else {
      const buff = Buffer.from(value, 'base64');
      const decipher = createDecipheriv(algorithm, this.key, this.encryptionIV);
      var decrypted = decipher.update(buff.toString('utf8'), 'hex', 'utf8') + decipher.final('utf8');

      if (decrypted === '' || decrypted === '�') {
        return value;
      } else {
        return decrypted;
      }
    }
  }

  private setSecrets(): void {
    if (this.password && this.key && this.encryptionIV) {
      return;
    } else {
      this.password = this.getPassword();
      if (this.password !== null) {
        this.key = this.getKey(this.password);
        this.encryptionIV = this.getIv(this.password);
      }
    }
  }

  private getPassword(): string {
    var clientMapping = this.configService.getClientMapping();

    if (!clientMapping?.hashKey || !clientMapping?.name) {
      return null;
    }

    return clientMapping.hashKey + clientMapping.name;
  }

  private getKey(password: string): string {
    return createHash('sha512')
      .update(password)
      .digest('hex')
      .substring(0, 32);
  }

  private getIv(password: string): string {
    return createHash('sha512')
      .update(password)
      .digest('hex')
      .substring(0, 16);
  }

  private alreadyEncrypted(value: string): boolean {
    var decrypted = this.decryptValue(value);
    return decrypted !== value;
  }
}
