// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-searchbar {
  text-align: start;
  vertical-align: middle;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/general/master-data-modal/master-data-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACJ","sourcesContent":["ion-searchbar {\n    text-align: start;\n    vertical-align: middle;\n    align-items: center;\n    padding-top: 6px;\n    padding-bottom: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
